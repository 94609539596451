<!--DESKTOP-->
<div *ngIf="!isMobilePlatform"  #title class="title-container" [ngClass]="{'hide-empty-dashboard-line': genres?.length === 0}">
    <div class="title"><span>{{ 'genres-table.header.genres' | translate }}</span></div>
</div>
<div *ngIf="genres && genres.length > 0 && !isMobilePlatform; else emptyDesktopContainer" class="table-container">
    <table matSort (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource">
        <!-- Name Column -->
        <ng-container matColumnDef="name" sticky>
            <th mat-sort-header="{{currentLanguage === 'eng' ? 'nameEn' : 'nameDe'}}" mat-header-cell *matHeaderCellDef>{{ 'genres-table.name' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{currentLanguage === 'eng' ? element.nameEn : element.nameDe}} </td>
        </ng-container>

        <!-- Name Variant Column -->
        <ng-container matColumnDef="nameVariant">
            <th mat-header-cell *matHeaderCellDef>{{ 'genres-table.name-variant' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.nameVariant ? element.nameVariant : '—'}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<!--MOBILE-->
<div class="mobile-table" *ngIf="genres && genres.length > 0 && isMobilePlatform; else emptyMobileContainer">
    <div class="toolbar-wrapper">
        <mat-toolbar class="toolbar-form">
            <div class="left-side">
                <button mat-icon-button class="example-icon" (click)="closeDialog()">
                    <mat-icon class="close-icon">chevron_left</mat-icon>
                </button>
                <span>{{ 'genres-table.header.genres' | translate }}</span>
            </div>
        </mat-toolbar>
        <mat-divider></mat-divider>
    </div>
    <mat-list role="list">
        <ng-container *ngFor="let item of paginatedData; let i = index">
            <a mat-list-item matRipple>
                <div class="list-wrapper">
                    <div class="text-container">
                        <span class="name">{{currentLanguage === 'eng' ? item.nameEn : item.nameDe}}</span>
                        <span class="instrument">{{item.nameVariant ? item.nameVariant : '—'}}</span>
                    </div>
                </div>
            </a>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>

<ng-template #emptyMobileContainer>
    <ng-container *ngIf="isMobilePlatform">
        <div class="toolbar-wrapper">
            <mat-toolbar class="toolbar-form">
                <div class="left-side">
                    <button mat-icon-button class="example-icon" (click)="closeDialog()">
                        <mat-icon class="close-icon">chevron_left</mat-icon>
                    </button>
                    <span>{{ 'genres-table.header.genres' | translate }}</span>
                </div>
                <div class="more-container">
                    <button [disabled]="true" mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                    </mat-menu>
                </div>
            </mat-toolbar>
            <mat-divider></mat-divider>
        </div>
        <div class="empty-container">
            {{ 'student-dashboard-table.message.noDataAvailable' | translate }}
        </div>
    </ng-container>
</ng-template>
<ng-template #emptyDesktopContainer>
    <div *ngIf="!isMobilePlatform" class="empty-container">
        {{ 'student-dashboard-table.message.noDataAvailable' | translate }}
    </div>
</ng-template>

<!--DESKTOP-->
<mat-paginator *ngIf="!isMobilePlatform"  [ngClass]="{'empty-paginator': dataSource && dataSource.data && dataSource.data.length < 10, 'hide-empty-dashboard-line': genres?.length === 0 }" [pageSizeOptions]="[10, 20, 30]"
               showFirstLastButtons
               (page)="onPaginatorPageChange($event)">
</mat-paginator>

<!--MOBILE-->
<div *ngIf="isMobilePlatform && genres && genres.length > 0" class="paginator-wrapper">
    <mat-paginator
        [pageSize]="10"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>



