import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
    Genre,
    Instrument,
    School,
    SchoolItem,
    Student,
    Teacher,
    UserResponse
} from '../../../../services/api/api.type';
import { TEACHING_LOCATION } from '../../../../../shared/constants/form-option';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SelectImageComponent, UploadImageData } from '../../../select-image/select-image.component';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { TeacherFormComponent } from '../../../profile/teacher-form/teacher-form.component';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { TeacherProfileService } from '../../../../../shared/services/teacher-profile.service';
import { PlatformService } from '../../../../services/platform.service';
import { DeleteConfirmComponent } from '../../delete-confirm/delete-confirm.component';
import { ApiService } from '../../../../services/api/api.service';
import { UserService } from '../../../../services/user/user.service';
import { TeacherApproveEvent } from '../dashboard-teacher-table/dashboard-teacher-table.component';
import { ComponentType } from '@angular/cdk/overlay';
import {
    OrganisationProfileInfoComponent
} from '../../organisation-dashboard/organisation-profile-info/organisation-profile-info.component';
import { MusicEntity } from '../../../../../shared/services/music.service';
import { schoolId } from '../../../../../shared/constants/table-data';

export interface ApproveTeacher<T> {
    teacher: Teacher;
    id: string;
    modal?: T;
    teacherId?: string;
    validated?: boolean;
}

@Component({
  selector: 'app-teacher-profile-info',
  templateUrl: './teacher-profile-info.component.html',
  styleUrls: ['./teacher-profile-info.component.scss']
})
export class TeacherProfileInfoComponent implements OnInit {
    protected readonly UserRoles = UserRoles;
    teacher: Teacher;
    fromMatch = false;
    image: string | null;
    schoolLogo: string | null;
    schoolName: string | null;
    currentLanguage: string = this.translateService.currentLang;
    @Output() teacherUpdated: EventEmitter<string> = new EventEmitter<string>();
    @Output() onTeacherApprove: EventEmitter<TeacherApproveEvent<TeacherProfileInfoComponent>> = new EventEmitter<TeacherApproveEvent<TeacherProfileInfoComponent>>();

    @Output() approve: EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>> = new EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>>();
    @Output() disapprove: EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>> = new EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>>();

    hasEditProfile: boolean;

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get role(): UserRoles {
        return this.userService?.userInfo?.role;
    }

    get user() {
        return this.userService?.userInfo;
    }
    constructor(
        public dialogRef: MatDialogRef<TeacherProfileInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Teacher & { hasEditProfile: boolean },
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private dashboardTableService: DashboardTableService,
        private teacherProfileService: TeacherProfileService,
        private apiService: ApiService,
        private userService: UserService,
        private platformService: PlatformService) {
    }

    ngOnInit() {
        this.teacher = this.data;
        this.image = this.teacher.files.length > 0 ? this.teacher.files[0].url: null;
        this.schoolLogo = this.teacher?.teacher?.school?.files && this.teacher?.teacher?.school?.files.length > 0 ? this.teacher?.teacher?.school?.files[0].url: null;
        this.schoolName = this.teacher?.teacher?.school?.name ? this.teacher?.teacher?.school?.name : null;
        this.hasEditProfile = this.data.hasEditProfile;
        this.teacherProfileService.setTeacherProfileComponent(this);
        this.fromMatch = !!this.data?.fromMatch;
    }

    setTeacher(teacher: Teacher) {
        this.teacher = teacher;
    }

    setImage() {
        this.image = this.teacher.files.length > 0 ? this.teacher.files[0].url: null;
    }

    getImageStyle(image: string | null): SafeStyle {
        const backgroundImage = image ? `url(${image})` : 'url(\'/assets/images/profile/unknown.jpg\')';
        const backgroundColor = image ? '' : 'lightgray';

        return this.sanitizer.bypassSecurityTrustStyle(`
          background-image: ${backgroundImage};
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
          background-color: ${backgroundColor};
    `);
    }

    upload() {
        this.openDialog(SelectImageComponent, 'select-image', { firstName: this.teacher.firstName, files: this.teacher?.files, userId: this.teacher.id, role: UserRoles.Teacher });
    }

    editProfile() {
        this.openDialog(TeacherFormComponent, 'teacher-form', this.teacher, '864px');
    }

    deleteTeacher(key: string) {
        const title = this.translateService.instant(key);
        const type = this.translateService.instant('confirm_delete.teacher_type');
        this.openDeleteConfirmDialog(DeleteConfirmComponent, 'delete-confirm', { id: this.data.teacher.id, title, name: this.data.firstName, type });
    }

    openDeleteConfirmDialog(component: ComponentType<DeleteConfirmComponent>, panelClass: string, dialogData?: { id: string, title: string, name: string, type: string }): void {
        document.body.classList.add('modal-open');
        const dialogRef = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '480px',
            autoFocus: false,
            disableClose: true,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(teacherId => {
            document.body.classList.remove('modal-open');
            if (teacherId) {
                this.apiService.deleteTeacher(teacherId).subscribe(() => {
                    this.dialogRef.close({delete: true});
                });
            }
            console.log('Dialog closed result', teacherId);
        });
    }

    openDialog(component: ComponentType<SelectImageComponent | TeacherFormComponent>, panelClass: string, dialogData?: Teacher | UploadImageData, width = '800px'): void {
        document.body.classList.add('modal-open');
        let dialogRef: MatDialogRef<SelectImageComponent | TeacherFormComponent>;
        if (this.platformService.isMobile) {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        } else {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                autoFocus: false,
                width,
                disableClose: false,
                position: { top: '32px' },
                data: dialogData
            });
        }

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.dashboardTableService.needUpdateTeacherTable.next(true);
                this.teacherUpdated.emit(this.teacher.id);
                if (result?.delete) {
                    this.closeDialog();
                }
            }
            console.log('Dialog closed result', result);
        });
    }


    getTeachingLocation(locations?: string[]): string {
        const availableLocations = TEACHING_LOCATION;
        const result = availableLocations.filter((availableLocation) => {
            return locations?.includes(availableLocation.value);
        });
        const translatedLocations = result.map(location => this.translateService.instant('teaching_locations.' + location.value));
        return translatedLocations.join(', ');
    }

    translateGenres(genres: (string | MusicEntity | null)[] | undefined): string {
        if (!genres || genres.length === 0) return '—';

        return genres
            .map(genres => {
                if (typeof genres === 'string') {
                    return genres;
                }
                return this.currentLanguage === 'eng' ? genres?.nameEn : genres?.nameDe;
            })
            .join(' | ');
    }

    translateInstruments(instruments: (string | MusicEntity | null)[] | undefined): string {
        if (!instruments || instruments.length === 0) return '—';

        return instruments
            .map(instrument => {
                if (typeof instrument === 'string') {
                    return instrument;
                }
                return this.currentLanguage === 'eng' ? instrument?.nameEn : instrument?.nameDe;
            })
            .join(' | ');
    }

    translateGender(gender: string): string {
        if (!gender) return '—';
        return this.translateService.instant('genders.' + gender);
    }

    closeDialog(update = false) {
        this.dialogRef.close(update);
    }

    handleApprove(teacherId: string, validated: boolean) {
        validated = !validated;
        this.onTeacherApprove.emit({teacherId, validated});
    }

    handleDisapproveTeacher(teacher: Teacher) {
        this.disapprove.emit({teacher, id: teacher.teacher.id});
    }

    handleApproveTeacher(teacher: Teacher) {
        this.approve.emit({teacher, id: teacher.teacher.id});
    }

    openOrganisationProfile(school?: School | null, schoolId?: string) {
        if (school && schoolId) {
            // @ts-ignore
            school['id'] = schoolId;
            this.openOrgProfileDialog(OrganisationProfileInfoComponent, 'school-profile-info', school, '640px');
        }

    }

    getPriceRangeDisplay(): string | string[] {
        const priceRange = this.teacher.teacher?.school?.priceRange;

        if (Array.isArray(priceRange) && priceRange.length > 0) {
            return priceRange.map(range =>
                this.translateService.instant('organization_profile_info.perLesson', {
                    price: range.price,
                    duration: range.length
                })
            );
        } else if (priceRange && typeof priceRange === 'object') {
            return this.translateService.instant('organization_profile_info.perLesson', {
                price: priceRange.price,
                duration: priceRange.length
            });
        } else {
            return '—';
        }
    }

    openOrgProfileDialog<T>(component: ComponentType<T>, panelClass: string, dialogData?: School, width = '864px'): void {
        width = this.platformService.isMobile ? '100vw' : width;
        document.body.classList.add('modal-open');
        let dialogRef;
        if (this.platformService.isMobile) {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        } else {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                autoFocus: false,
                disableClose: false,
                position: { top: '32px' },
                data: dialogData
            });
        }

        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog closed result', result);
            document.body.classList.remove('modal-open');
        });
    }

    protected readonly schoolId = schoolId;
}
