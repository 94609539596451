<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>

    <mat-chip-listbox formControlName="genres" #chipList [multiple]="true" class="mat-chip-list-stacked"
                      [selectable]="true" (change)="handleSelectedGenresChange()">
        <mat-chip-option *ngFor="let chip of displayedChips" [value]="chip.chip.id" [selected]="chip.selected"
                         (click)="chip.selected=!chip.selected"
                         [selectable]="true">
            {{ currentLanguage === 'eng' ? chip.chip.nameEn : chip.chip.nameDe }}
        </mat-chip-option>
    </mat-chip-listbox>
    <div class="custom-theme storybook-field">
        <button mat-stroked-button  [color]="step.btn2Color" (click)="showMoreChips()">{{ 'signup-form.' + signUpRole + '.step-instrument.showMoreButton' | translate:{ count: moreChipCount } }}</button>
    </div>

    <div align="end" class="custom-theme btn-container sign-up-form-field">
        <button *ngIf="signUpRole === 'student'" mat-stroked-button [color]="step.btn2Color" (click)="resetGenres()">{{step.btnText1 | translate}}</button>
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid || !formGroup.get('genres')?.value || formGroup.get('genres')?.value?.length === 0" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>
