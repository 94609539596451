<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{ step.title1 | translate }} {{ step.title2 | translate }}</div>
    </div>
    <mat-chip-listbox formControlName="selectedInstruments" #chipList [multiple]="true" class="mat-chip-list-stacked"
                      [selectable]="true" (change)="handleSelectedInstrumentsChange()">
        <mat-chip-option *ngFor="let chip of displayedChips" [value]="chip.chip.id" [selected]="chip.selected"
                         (click)="chip.selected=!chip.selected"
                         [selectable]="true">
            <mat-icon *ngIf="chip.chip?.gnd || chip.chip?.wikidata || chip.chip?.mimo" matChipTrailingIcon [tooltip]="[{ name: 'GND', url:  chip.chip?.gnd}, { name: 'Wikidata', url:  chip.chip?.wikidata}, {name: 'MIMO',url:  chip.chip?.mimo}]">open_in_new</mat-icon>

            {{ currentLanguage === 'eng' ? chip.chip.nameEn : chip.chip.nameDe }}
        </mat-chip-option>
    </mat-chip-listbox>
    <div class="custom-theme storybook-field">
        <button mat-stroked-button  [color]="step.btn2Color" (click)="showMoreChips()">{{ 'signup-form.' + signUpRole + '.step-instrument.showMoreButton' | translate:{ count: moreChipCount } }}</button>
    </div>
    <div align="end" class="custom-theme btn-container sign-up-form-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ step.btnText2 | translate }}</button>
    </div>
</form>
