import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserIntroInfo, UserRoles } from '../../../../../core/models/user-info.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganisationDashboardTableComponent } from '../organisation-dashboard-table/organisation-dashboard-table.component';
import { DashboardTeacherTableComponent } from '../../teacher-dashboard/dashboard-teacher-table/dashboard-teacher-table.component';
import {
    MatchesListResponse,
    SchoolItem,
    SchoolListResponse,
    StudentLesson, StudentsResponse,
    Teacher, TeachersResponse
} from '../../../../services/api/api.type';
import { DashboardStudentTableComponent } from '../../student-dashboard/dashboard-student-table/dashboard-student-table.component';
import { OrganizationFormComponent } from '../../../profile/organization-form/organization-form.component';
import { TeacherFormComponent } from '../../../profile/teacher-form/teacher-form.component';
import { schoolId } from '../../../../../shared/constants/table-data';
import { MatchesListComponent } from '../matches-list/matches-list.component';
import { Subject, takeUntil } from 'rxjs';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentType } from '@angular/cdk/overlay';
import { InstrumentsTableComponent } from '../../instruments-table/instruments-table.component';
import { GenresTableComponent } from '../../genres-table/genres-table.component';
import { MusicEntity } from '../../../../../shared/services/music.service';

@Component({
  selector: 'app-organisation-list-mobile',
  templateUrl: './organisation-list-mobile.component.html',
  styleUrls: ['./organisation-list-mobile.component.scss']
})
export class OrganisationListMobileComponent implements OnInit, OnDestroy {

    protected readonly schoolId = schoolId;
    protected readonly UserRoles = UserRoles;
    destroy$ = new Subject();

    get hasConfirmedAndValidated(): boolean {
        return (!!this.organisation?.confirmed && !!this.organisation?.validated);
    }

    @Input() role: UserRoles;
    @Input() userIntroInfo: UserIntroInfo;
    @Input() organisations: SchoolListResponse;
    @Input() newOrganisations: SchoolListResponse;
    @Input() teachers: TeachersResponse;
    @Input() organisation?: SchoolItem;
    @Input() studentLessons: StudentLesson[];
    @Input() teacher?: Teacher;
    @Input() students: StudentsResponse;
    @Input() pendingMatches: MatchesListResponse;
    @Input() activeMatches: MatchesListResponse;
    @Input() pastMatches: MatchesListResponse;
    @Input() genres: MusicEntity[];
    @Input() instruments: MusicEntity[];

    @Output() onApproveOrg = new EventEmitter();
    @Output() onApproveTeacher = new EventEmitter();
    @Output() onApproveStudent = new EventEmitter();
    @Output() onLessonTimeSelect = new EventEmitter();
    @Output() updateOrganisationList = new EventEmitter();
    @Output() updateTeacherList = new EventEmitter();
    @Output() onApprove: EventEmitter<{organisationId: string, validated: boolean}> = new EventEmitter<{organisationId: string, validated: boolean}>();
    @Output() updateMatches: EventEmitter<void> = new EventEmitter<void>();
    @Output() updatePendingMatches: EventEmitter<void> = new EventEmitter<void>();


    constructor(
        private dialog: MatDialog,
        private dashboardTableService: DashboardTableService,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.dashboardTableService.needUpdateTeacherTable.pipe(takeUntil(this.destroy$)).subscribe((update) => {
            if (update) {
                 // TODO
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    openInstrumentsList() {
        this.openInstrumentsDialog(InstrumentsTableComponent, 'instrument-dashboard');
    }

    openGenresList() {
        this.openGenresDialog(GenresTableComponent, 'genre-dashboard');
    }

    openOrgList() {
        this.openOrganisationDialog(OrganisationDashboardTableComponent, 'org-dashboard');
    }

    openTeacherList() {
        this.openTeacherDialog(DashboardTeacherTableComponent, 'teacher-dashboard');
    }

    openStudentList() {
        this.openStudentDialog(DashboardStudentTableComponent, 'student-dashboard');
    }

    openActiveMatchesList() {
        const matchType = 'active';
        const title = this.translateService.instant('statistics.accepted-matches');
        this.openMatchesDialog(MatchesListComponent, 'matches-list', { ...this.activeMatches, title, matchType });
    }

    openPendingMatchesList() {
        const matchType = 'pending';
        const title = this.translateService.instant('statistics.pending-matches');
        this.openMatchesDialog(MatchesListComponent, 'matches-list', { ...this.pendingMatches, title, matchType });
    }

    openPastMatchesList() {
        const matchType = 'past';
        const title = this.translateService.instant('statistics.past-matches');
        this.openMatchesDialog(MatchesListComponent, 'matches-list', { ...this.pastMatches, title, matchType });
    }

    openOrganisationDialog(component: ComponentType<OrganisationDashboardTableComponent>, panelClass: string, dialogData = null): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<OrganisationDashboardTableComponent> = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width: '800px',
                height: '95vh',
                maxWidth: '100vw',
                autoFocus: false,
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        dialogRef.componentInstance.role = this.role;
        dialogRef.componentInstance.organisations = this.organisations;
        dialogRef.componentInstance.onApprove.subscribe((approve) => {
            this.onApproveOrg.emit(approve);
        });
        dialogRef.componentInstance.closeModal.subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            console.log('Dialog closed result', result);
        });
    }

    openInstrumentsDialog(component: ComponentType<InstrumentsTableComponent>, panelClass: string, dialogData = null): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<InstrumentsTableComponent> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '800px',
            height: '95vh',
            maxWidth: '100vw',
            autoFocus: false,
            disableClose: true,
            position: { top: '48px' },
            data: dialogData
        });
        dialogRef.componentInstance.instruments = this.instruments;
        dialogRef.componentInstance.closeModal.subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            console.log('Dialog closed result', result);
        });
    }

    openGenresDialog(component: ComponentType<GenresTableComponent>, panelClass: string, dialogData = null): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<GenresTableComponent> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '800px',
            height: '95vh',
            maxWidth: '100vw',
            autoFocus: false,
            disableClose: true,
            position: { top: '48px' },
            data: dialogData
        });
        dialogRef.componentInstance.genres = this.genres;
        dialogRef.componentInstance.closeModal.subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            console.log('Dialog closed result', result);
        });
    }


    openTeacherDialog(component: ComponentType<DashboardTeacherTableComponent>, panelClass: string, dialogData = null): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<DashboardTeacherTableComponent> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '800px',
            height: '95vh',
            maxWidth: '100vw',
            autoFocus: false,
            disableClose: true,
            position: { top: '48px' },
            data: dialogData
        });
        dialogRef.componentInstance.role = this.role;
        dialogRef.componentInstance.organisation = this.organisation;
        dialogRef.componentInstance.teachers = this.teachers;
        dialogRef.componentInstance.onApprove.subscribe((approve) => {
            this.onApproveTeacher.emit(approve);
        });
        dialogRef.componentInstance.closeModal.subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            console.log('Dialog closed result', result);
        });
    }

    openStudentDialog(component: ComponentType<DashboardStudentTableComponent>, panelClass: string, dialogData = null): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<DashboardStudentTableComponent> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '800px',
            height: '95vh',
            maxWidth: '100vw',
            autoFocus: false,
            disableClose: true,
            position: { top: '48px' },
            data: dialogData
        });

        dialogRef.componentInstance.students = this.students;
        dialogRef.componentInstance.teacher = this.teacher;
        dialogRef.componentInstance.studentLessons = this.studentLessons;
        dialogRef.componentInstance.onLessonTimeSelect.subscribe(() => {
            this.onLessonTimeSelect.emit();
        });
        dialogRef.componentInstance.onApprove.subscribe(() => {
            this.onApproveStudent.emit();
        });
        dialogRef.componentInstance.closeModal.subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            console.log('Dialog closed result', result);
        });
    }

    addOrganisation() {
        this.openDialog(OrganizationFormComponent, 'organisation-form');
    }

    addTeacher() {
        this.openDialog(TeacherFormComponent, 'teacher-form');
    }


    openDialog(component: ComponentType<OrganizationFormComponent | TeacherFormComponent>, panelClass: string, dialogData = null): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<OrganizationFormComponent | TeacherFormComponent> = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width: '800px',
                height: '95vh',
                maxWidth: '100vw',
                autoFocus: false,
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });

           dialogRef.afterClosed().subscribe((result) => {
               document.body.classList.remove('modal-open');
               if (result) {
                if (panelClass === 'organisation-form') {
                    this.updateOrganisationList.emit();
                }
                if (panelClass === 'teacher-form') {
                    this.updateTeacherList.emit();
                }
            }
            console.log('Dialog closed result', result);
        });
    }

    handleApprove(organisationId: string) {
        this.onApprove.emit({organisationId, validated: true});
    }

    openMatchesDialog(component: ComponentType<MatchesListComponent>, panelClass: string, dialogData: MatchesListResponse): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<MatchesListComponent> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '800px',
            height: '95vh',
            maxWidth: '100vw',
            autoFocus: false,
            disableClose: true,
            position: { top: '48px' },
            data: dialogData
        });

        dialogRef.componentInstance.matches = dialogData;
        dialogRef.componentInstance.closeModal.subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            console.log('Dialog closed result', result);
        });
        dialogRef.componentInstance.updateMatches.subscribe(() => {
            this.updateMatches.emit();
        });
        dialogRef.componentInstance.updatePendingMatches.subscribe(() => {
            this.updatePendingMatches.emit();
        })
    }

}
