import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTableDataSource } from '@angular/material/table';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { ApiService } from '../../../../services/api/api.service';
import { SchoolItem, SchoolListResponse, Teacher, TeachersResponse } from '../../../../services/api/api.type';
import { TeacherFormComponent } from '../../../profile/teacher-form/teacher-form.component';
import { ApproveTeacher, TeacherProfileInfoComponent } from '../teacher-profile-info/teacher-profile-info.component';
import { Subject, takeUntil } from 'rxjs';
import { PlatformService } from '../../../../services/platform.service';
import { ComponentType } from '@angular/cdk/overlay';
import { Sort } from '@angular/material/sort';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MusicEntity } from '../../../../../shared/services/music.service';

export interface TeacherApproveEvent<T> {
    teacherId: string;
    validated: boolean;
    modal?: T;
}

@Component({
  selector: 'app-dashboard-teacher-table',
  templateUrl: './dashboard-teacher-table.component.html',
  styleUrls: ['./dashboard-teacher-table.component.scss']
})
export class DashboardTeacherTableComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [ 'firstName','lastName', 'email', 'signUpDate', 'instruments', 'genres', 'students', 'actions', 'profile'];
    dataSource: MatTableDataSource<Teacher>;
    currentIndex: number;
    copied = false;
    protected readonly UserRoles = UserRoles;
    dialogRef: MatDialogRef<TeacherFormComponent | TeacherProfileInfoComponent>;
    selectedTeacherId: string;
    destroy$ = new Subject();

    pageSize = 10;
    currentPage = 0;
    sort: Sort;
    currentLanguage: string = this.translateService.currentLang;


    @Input() role: UserRoles;
    @Input() teachers: TeachersResponse;
    @Output() onApprove: EventEmitter<TeacherApproveEvent<TeacherProfileInfoComponent>> = new EventEmitter<TeacherApproveEvent<TeacherProfileInfoComponent>>();
    @Input() organisation?: SchoolItem;
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

    @Output() approve: EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>> = new EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>>();
    @Output() disapprove: EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>> = new EventEmitter<ApproveTeacher<TeacherProfileInfoComponent>>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    get hasConfirmedAndValidated(): boolean {
        return (!!this.organisation?.confirmed && !!this.organisation?.validated);
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get paginatedData() {
        const startIndex = this.currentPage * this.pageSize;
        const endIndex = startIndex + 10;
        return this.dataSource.data.slice(startIndex, endIndex);
    }

    constructor(private dialog: MatDialog,
                private dashboardTableService: DashboardTableService,
                private platformService: PlatformService,
                private clipboard: Clipboard,
                private cd: ChangeDetectorRef,
                private translateService: TranslateService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.handleLanguageChange();
        this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
        this.dashboardTableService.needUpdateTable.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;
        });
        this.dashboardTableService.needUpdateTeacherTable.pipe(takeUntil(this.destroy$)).subscribe((update) => {
            this.getTeachers(!!update);
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    isMusicEntity(instrument: any): instrument is MusicEntity {
        return instrument && typeof instrument === 'object' && 'nameEn' in instrument;
    }

    handleLanguageChange() {
        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: LangChangeEvent) => {
                this.currentLanguage = event.lang;
                this.cd.detectChanges();
            });
    }


    addOrEditTeacher(isAdd: boolean, teacherData?: Teacher) {
        if (isAdd) {
            this.openDialog(TeacherFormComponent, 'teacher-form');
        } else {
            this.openDialog(TeacherFormComponent, 'teacher-form', teacherData);
        }
    }

    openDialog(component: ComponentType<TeacherFormComponent | TeacherProfileInfoComponent>, panelClass: string, dialogData?: Teacher, width = '864px'): void {
        document.body.classList.add('modal-open');
        let dialogRef: MatDialogRef<TeacherFormComponent | TeacherProfileInfoComponent>;
        if (this.platformService.isMobile) {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        } else {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                autoFocus: false,
                disableClose: false,
                position: { top: '32px' },
                data: dialogData
            });
        }

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.getTeachers();
            }
            console.log('Dialog closed result', result);
        });
        if (panelClass === 'teacher-profile-info') {
            this.dialogRef = dialogRef;
            const modalComponentInstance = this.dialogRef.componentInstance as TeacherProfileInfoComponent;
            modalComponentInstance.teacherUpdated.subscribe((id) => this.selectedTeacherId = id);
            modalComponentInstance.onTeacherApprove.subscribe((data) => {
                this.onApprove.emit({...data, modal : modalComponentInstance});
            });
            modalComponentInstance.approve.subscribe((data) => {
                this.approve.emit({...data, modal : modalComponentInstance});
            });
            modalComponentInstance.disapprove.subscribe((data) => {
                this.disapprove.emit({...data, modal : modalComponentInstance});
            });
        }
    }

    handleApprove(teacherId: string, validated: boolean) {
        validated = !validated;
        this.onApprove.emit({teacherId, validated})
    }


    getTeachers(updateProfile = false) {
        this.apiService.getTeachers( '1', '1000').subscribe(
            (teachers) => {
                this.teachers.list.length = 0;
                teachers.list.forEach((item) => {
                    this.teachers?.list.push(item);
                })
                this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
                if (updateProfile && this.selectedTeacherId) {
                    const selectedTeacher = this.teachers.list.find((teacher: Teacher) => teacher.id === this.selectedTeacherId);
                    if (selectedTeacher) {
                        const modalComponentInstance = this.dialogRef.componentInstance as TeacherProfileInfoComponent;
                        modalComponentInstance.setTeacher(selectedTeacher);
                        modalComponentInstance.setImage();
                    }
                }
            });
    }

    getSortedTeachers(sortBy: string, direction: string) {
        this.apiService.getSortedTeachers( '1', '1000', sortBy, direction.toUpperCase()).subscribe(
            (teachers) => {
                this.teachers.list.length = 0;
                teachers.list.forEach((item) => this.teachers?.list.push(item));
                this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
            });
    }

    onPageChange(event: PageEvent) {
        this.currentIndex = event.pageIndex * event.pageSize;
        this.currentPage = event.pageIndex;
    }

    showProfile(teacher: Teacher) {
        this.openDialog(TeacherProfileInfoComponent, 'teacher-profile-info', teacher, '640px');
    }

    copyInviteUrl() {
        const url = this.organisation?.inviteUrl || '';
        const success = this.clipboard.copy(url);

        if (success) {
            this.copied = true;
            setTimeout(() => { this.copied = false; }, 2000);
        } else {
            console.error('Could not copy the invite link.');
        }
    }

    closeDialog() {
        this.closeModal.emit();
    }

    sortData(sort: Sort) {
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            this.getTeachers();
            return;
        }
        this.getSortedTeachers(sort.active, sort.direction);
    }

}
