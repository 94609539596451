import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { StudentProfileInfoComponent } from '../../student-dashboard/student-profile-info/student-profile-info.component';
import { TeacherProfileInfoComponent } from '../../teacher-dashboard/teacher-profile-info/teacher-profile-info.component';
import { PlatformService } from '../../../../services/platform.service';
import { UserInfo, UserRoles } from '../../../../../core/models/user-info.interface';
import { MatchDetailsComponent } from '../../match-details/match-details.component';
import { Subject, takeUntil } from 'rxjs';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { ApiService } from '../../../../services/api/api.service';
import { UserService } from '../../../../services/user/user.service';
import {
    Instrument,
    Lesson,
    Matches,
    MatchesListResponse,
    Student,
    Teacher
} from '../../../../services/api/api.type';
import { ComponentType } from '@angular/cdk/overlay';
import { Sort } from '@angular/material/sort';
import { MusicEntity } from '../../../../../shared/services/music.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

export interface MatchData {
    matchType: string;
    instruments: (string | MusicEntity | null)[];
    teacher: Teacher;
    student: Student;
    lesson: Lesson;
}

@Component({
  selector: 'app-matches-list',
  templateUrl: './matches-list.component.html',
  styleUrls: ['./matches-list.component.scss']
})
export class MatchesListComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [ 'trialLesson', 'instrument', 'teacherFirstName', 'teacherLastName', 'studentFirstName', 'studentLastName', 'teacherProfile', 'studentProfile'];
    dataSource: MatTableDataSource<Matches>;
    currentIndex: number;
    pageSize = 10;
    currentPage = 0;
    dialogRef: MatDialogRef<TeacherProfileInfoComponent>;
    detailsDialogRef: MatDialogRef<MatchDetailsComponent>;
    profileDialogRef: MatDialogRef<TeacherProfileInfoComponent>;
    selectedTeacherId: string;
    protected readonly UserRoles = UserRoles;
    destroy$ = new Subject();
    sort: Sort;
    currentLanguage: string = this.translateService.currentLang;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('title') title: ElementRef;

    @Input() matches: MatchesListResponse;
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
    @Output() updateMatches: EventEmitter<void> = new EventEmitter<void>();
    @Output() updatePendingMatches: EventEmitter<void> = new EventEmitter<void>();


    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get user(): UserInfo {
        return this.userService.userInfo;
    }

    get paginatedData(): Matches[] {
        const startIndex = this.currentPage * this.pageSize;
        const endIndex = startIndex + 10;
        return this.dataSource.data.slice(startIndex, endIndex);
    }

    constructor(private dialog: MatDialog,
                private platformService: PlatformService,
                private dashboardTableService: DashboardTableService,
                private userService: UserService,
                private cd: ChangeDetectorRef,
                private translateService: TranslateService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.handleLanguageChange();
        this.dataSource = new MatTableDataSource<Matches>(this.matches.list);
        this.dashboardTableService.needUpdateTeacherTable.pipe(takeUntil(this.destroy$)).subscribe((update) => {
            if (this.isMobilePlatform) {
                this.getMatchesForMobile(!!update);
            } else {
                this.getMatches(!!update);
            }
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    handleLanguageChange() {
        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: LangChangeEvent) => {
                this.currentLanguage = event.lang;
                this.cd.detectChanges();
            });
    }

    isMusicEntity(instrument: any): instrument is MusicEntity {
        return instrument && typeof instrument === 'object' && 'nameEn' in instrument;
    }

    setMatches(matches: MatchesListResponse) {
        this.matches = matches;
    }

    openDialog(component: ComponentType<(StudentProfileInfoComponent | TeacherProfileInfoComponent)>, panelClass: string, dialogData?: (Teacher | Student), width = '864px', position = '32px'): void {
        document.body.classList.add('modal-open');
        const config = {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: true,
            position: { top: position },
            data: dialogData
        }
        if (position) {
            config.position = { top: position }
        }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                if (!this.isMobilePlatform) {
                    this.getMatches();
                } else {
                    this.getMatchesForMobile();
                }
            }
            console.log('Dialog closed result', result);
        });
        if (panelClass === 'teacher-profile-info') {
            this.dialogRef = dialogRef as MatDialogRef<TeacherProfileInfoComponent>;
            const modalComponentInstance = this.dialogRef.componentInstance as TeacherProfileInfoComponent;
            modalComponentInstance.teacherUpdated.subscribe((id) => this.selectedTeacherId = id);
        }
    }

    sortData(sort: Sort) {
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            this.getMatches();
            return;
        }
        this.getSortedMatches(sort.active, sort.direction);
    }

    onPaginatorPageChange(event: PageEvent) {
        if (!this.isMobilePlatform) {
            const titleElement = this.title.nativeElement;
            if (titleElement) {
                setTimeout(() => titleElement.focus());
            }
        }
        this.currentIndex = event.pageIndex * event.pageSize;
        this.currentPage = event.pageIndex;
    }

    showStudentProfile(student?: Matches) {
        this.openDialog(StudentProfileInfoComponent, 'student-profile-info', student?.studentUser, '640px');
    }

    showTeacherProfile(teacher?: Matches) {
        this.openDialog(TeacherProfileInfoComponent, 'teacher-profile-info', teacher?.teacherUser, '640px');
    }

    closeDialog() {
        this.closeModal.emit();
    }

    openMatchDetails(item: Matches) {
        const data: MatchData = {
            matchType: this.matches.matchType,
            instruments: item.instruments,
            teacher: item.teacherUser,
            student: item.studentUser,
            lesson: item.lesson,
        }
        this.openMobileDialog(MatchDetailsComponent, 'match-details', data);
    }

    openMobileDialog(component: ComponentType<MatchDetailsComponent>, panelClass: string, dialogData?: MatchData, width = '864px'): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<MatchDetailsComponent> = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                autoFocus: false,
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.getMatchesForMobile();
            }
            if (result && result?.delete) {
                this.updateMatches.emit();
            }
            console.log('Dialog closed result', result);
        });
        if (panelClass === 'match-details') {
            this.detailsDialogRef = dialogRef;
            const modalComponentInstance = this.detailsDialogRef.componentInstance as MatchDetailsComponent;
            modalComponentInstance.teacherUpdated.subscribe((id) => this.selectedTeacherId = id);
            modalComponentInstance.approveMatch.subscribe(() => this.updatePendingMatches.emit());
            modalComponentInstance.profileComponentInstance.subscribe(instance => this.profileDialogRef = instance);
        }
        dialogRef.componentInstance.closeModal.subscribe((res: { delete?: boolean }) => {
            dialogRef.close(res);
        });
    }

    getMatches(updateProfile = false) {
        const manageSchoolId = this.user.manageSchoolId;
        if (manageSchoolId) {
            this.apiService.getMatchesList(manageSchoolId, '1', '100', 'active').subscribe(
                (matches: MatchesListResponse) => {
                    this.matches.list.length = 0;
                    matches.list.forEach((item) => {
                        this.matches?.list.push(item);
                    })
                    this.dataSource = new MatTableDataSource<Matches>(this.matches?.list);
                    this.dataSource._updateChangeSubscription();
                    this.dataSource.paginator = this.paginator;
                    if (updateProfile && this.selectedTeacherId) {
                        const selectedTeacher = this.matches.list.find((matches) => matches.teacherUser.id === this.selectedTeacherId);
                        if (selectedTeacher) {
                            const modalComponentInstance = this.dialogRef.componentInstance as TeacherProfileInfoComponent;
                            modalComponentInstance.setTeacher(selectedTeacher.teacherUser);
                            modalComponentInstance.setImage();
                        }
                    }
                });
        }
    }

    getSortedMatches(sortBy: string, direction: string) {
        const manageSchoolId = this.user.manageSchoolId;
        if (manageSchoolId) {
            this.apiService.getSortedMatches(manageSchoolId, '1', '100', 'active', sortBy, direction.toUpperCase()).subscribe(
                (matches: MatchesListResponse) => {
                    this.matches.list.length = 0;
                    matches.list.forEach((item) => {
                        this.matches?.list.push(item);
                    });
                    this.dataSource = new MatTableDataSource<Matches>(this.matches?.list);
                    this.dataSource._updateChangeSubscription();
                    this.dataSource.paginator = this.paginator;
                });
        }
    }

    getMatchesForMobile(updateProfile = false) {
        const manageSchoolId = this.user.manageSchoolId;
        if (manageSchoolId) {
            this.apiService.getMatchesList(manageSchoolId, '1', '100', this.matches.matchType).subscribe(
                (matches: MatchesListResponse) => {
                    this.matches.list.length = 0;
                    matches.list.forEach((item) => {
                        this.matches?.list.push(item);
                    })
                    this.dataSource = new MatTableDataSource<Matches>(this.matches?.list);
                    this.dataSource._updateChangeSubscription();
                    this.dataSource.paginator = this.paginator;
                    if (updateProfile && this.selectedTeacherId) {
                        const selectedTeacher = this.matches.list.find((matches) => matches.teacherUser.id === this.selectedTeacherId);
                        if (selectedTeacher) {
                            const modalComponentInstance = this.profileDialogRef.componentInstance as TeacherProfileInfoComponent;
                            modalComponentInstance.setTeacher(selectedTeacher.teacherUser);
                            modalComponentInstance.setImage();
                            const detailsComponentInstance = this.detailsDialogRef.componentInstance as MatchDetailsComponent;
                            detailsComponentInstance.setTeacher(selectedTeacher.teacherUser);
                        }
                    }
                });
        }
    }

}
