<div class="genre-form-container">
    <mat-toolbar class="toolbar-form">
        <span>{{'genres_modal.title' | translate}}</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon" (click)="closeDialog()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div class="title-container">
        <div class="title">{{signUpRole === 'teacher' ? ('genres_modal.select_genres' | translate) : ('genres_modal.select_genres_student' | translate) }}</div>
    </div>
    <form [formGroup]="formGroup">
        <div mat-dialog-content class="form-content">
            <mat-chip-listbox formControlName="selectedGenres" #chipList [multiple]="true" class="mat-chip-list-stacked"
                              [selectable]="true" (change)="handleSelectedGenresChange()">
                <mat-chip-option *ngFor="let chip of displayedChips" [value]="chip.chip.id" [selected]="chip.selected"
                                 (click)="chip.selected=!chip.selected"
                                 [selectable]="true">
                    {{ data.lang === 'eng' ? chip.chip.nameEn : chip.chip.nameDe }}
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
    </form>

    <div class="footer">
        <div class="btn-container custom-theme">
            <div class="right-side">
                <button mat-stroked-button color="primary" (click)="closeDialog()">
                    <span>{{'genres_modal.cancel' | translate}}</span>
                </button>
                <button mat-raised-button color="primary" type="submit"  (click)="onSubmit()">
                    <span>{{'genres_modal.confirm_selection' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
