<ng-container *ngIf="loadDashboard && LoadInstrumentAndGenres" [ngSwitch]="user.role">
    <ng-container *ngSwitchCase="'student'">
        <app-teacher-dashboard></app-teacher-dashboard>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-root-dashboard
            [genresData]="genresData"
            [instrumentsData]="instrumentsData"
        >
        </app-root-dashboard>
    </ng-container>
</ng-container>
