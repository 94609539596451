import {
    Directive,
    ElementRef,
    Input,
    HostListener,
    ApplicationRef,
    ComponentFactoryResolver,
    Injector,
    ComponentRef,
    EmbeddedViewRef,
    Renderer2
} from '@angular/core';
import { CustomTooltipComponent } from '../../music/components/custom-tooltip/custom-tooltip.component';

@Directive({
    selector: '[tooltip]'
})
export class TooltipDirective {
    @Input() tooltip: { name: string, url: string | null | undefined }[] = [];
    private componentRef: ComponentRef<any> | null = null;
    private tooltipElement: HTMLElement | null = null;

    constructor(
        private elementRef: ElementRef,
        private appRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private renderer: Renderer2
    ) {}

    @HostListener('mouseenter') onMouseEnter(): void {
        if (this.componentRef === null) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CustomTooltipComponent);
            this.componentRef = componentFactory.create(this.injector);
            this.appRef.attachView(this.componentRef.hostView);
            const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            document.body.appendChild(domElem);

            this.tooltipElement = domElem;

            this.setTooltipComponentProperties();

            this.setTooltipComponentProperties();

            this.renderer.listen(this.tooltipElement, 'mouseenter', () => {
            });

            this.renderer.listen(this.tooltipElement, 'mouseleave', () => {
                this.destroy();
            });
        }
    }

    @HostListener('mouseleave') onMouseLeave(): void {
        // @ts-ignore
        if (!this.tooltipElement?.contains(event.relatedTarget)) {
            this.destroy();
        }
    }

    private setTooltipComponentProperties() {
        if (this.componentRef !== null) {
            this.componentRef.instance.tooltip = this.tooltip;
            const { left, right, bottom } = this.elementRef.nativeElement.getBoundingClientRect();
            this.componentRef.instance.left = (right - left) / 2 + left;
            this.componentRef.instance.top = bottom;
        }
    }

    private destroy(): void {
        if (this.componentRef !== null) {
            this.appRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
            this.componentRef = null;
            this.tooltipElement = null;
        }
    }
}
