import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {StepperOrientation} from '@angular/cdk/stepper';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user/user.service';
import {BehaviorSubject, mergeMap, Observable, of, Subject, switchMap} from 'rxjs';
import {SCHOOL_STEPS, STUDENT_STEPS, TEACHER_STEPS} from '../../../../shared/constants/form-option';
import {UserRoles} from '../../../../core/models/user-info.interface';
import {ExperienceLevel, SocialsItem, UsersFormBody} from '../../../services/api/api.type';
import {ApiService} from '../../../services/api/api.service';
import {schoolId} from '../../../../shared/constants/table-data';
import {getSchedulesBody} from '../../../../shared/constants/schedules';
import {
    SchoolRegisterData,
    SharedDataService,
    StudentRegisterData,
    TeacherRegisterData,
    UserConfirmationData
} from './shared/shared-data.service';
import { ISO_CONVERTER } from '../../../../shared/constants/localstorage-constants';
import { LanguageService } from '../../../../shared/services/language.service';
import { MusicService } from '../../../../shared/services/music.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
    studentStepData = STUDENT_STEPS;
    teacherStepData = TEACHER_STEPS;
    schoolStepData = SCHOOL_STEPS;
    isLinear = true;
    orientation = 'vertical' as StepperOrientation;
    private unsubscriber : Subject<void> = new Subject<void>();
    private _showSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showSuccess$: Observable<boolean> = this._showSuccess$.asObservable();
    private _showUserPasswordConfirmation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showUserPasswordConfirmation$: Observable<boolean> = this._showUserPasswordConfirmation$.asObservable();
    public userConfirmationData: UserConfirmationData;

    get signUpRole() {
        return this.userService.signUpRole;
    }


    backgroundImages: Record<string, string>  = {
        school_admin: '/assets/images/sign-up/signup-org.jpg',
        student: '/assets/images/sign-up/signup-student.jpg',
        teacher: '/assets/images/sign-up/signup-teacher.jpg',
    };

    mobileBackgroundImages: Record<string, string>  = {
        school_admin: '/assets/images/sign-up/signup-org-mobile.jpg',
        student: '/assets/images/sign-up/signup-student.jpg',
        teacher: '/assets/images/sign-up/signup-teacher-mobile.png',
    };

    getBackgroundImage(role: UserRoles) {
        return this.backgroundImages[role];
    }

    getMobileBackgroundImage(role: UserRoles) {
        return this.mobileBackgroundImages[role];
    }
    getMobileBackgroundShapeClass(role: string): string {
        switch (role) {
            case UserRoles.Student:
                return 'student';
            case UserRoles.Teacher:
                return 'teacher';
            case UserRoles.Organisation:
                return 'school_admin';
            default:
                return 'default';
        }
    }

    getBackgroundShapeClass(role: string): string {
        switch (role) {
            case UserRoles.Student:
                return 'student';
            case UserRoles.Teacher:
                return 'teacher';
            case UserRoles.Organisation:
                return 'school_admin';
            default:
                return 'default';
        }
    }

    get selectedLanguage() {
        return this.languageService.language as keyof typeof ISO_CONVERTER;
    }

    get chosenLanguage() {
        return this.languageService.language === 'EN' ? 'DE' : 'EN';
    }

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private userService: UserService,
        private zone: NgZone,
        private languageService: LanguageService,
        private sharedDataService: SharedDataService,
        private musicService: MusicService) {
    }


    ngOnInit() {
        this.userConfirmationData = this.sharedDataService.setUserConfirmationData;
        this._showUserPasswordConfirmation$.next(!!this.sharedDataService.setUserConfirmationData);
        const schoolId = this.route.snapshot.queryParamMap.get('schoolId');
        if (schoolId) {
            this.sharedDataService.setSchoolId = schoolId;
            this.userService.signUpRole = UserRoles.Teacher;
        }
        this.musicService.fetchInstruments('1', '1000').subscribe();
        this.musicService.fetchGenres('1', '1000').subscribe();
    }

    onResult(data:{result: SchoolRegisterData | StudentRegisterData | TeacherRegisterData; role: UserRoles | null}) {
        switch (data.role) {
            case  UserRoles.Organisation:
                this.createOrganisation(data.result as SchoolRegisterData);
                break;
            case  UserRoles.Teacher:
                this.createTeacher(data.result as TeacherRegisterData);
                break;
            case  UserRoles.Student:
                this.createStudent(data.result as StudentRegisterData);
                break;
            default:
                break;
        }
    }

    createStudent(data: StudentRegisterData) {
        const getSchedulesBodyFun = getSchedulesBody;
        const schedulesBody = getSchedulesBodyFun(data.availabilityTimeStamp);
        // const genderPreference = data.genderPreference.filter((gender: string) => gender !== 'no_preference');

        const birthday = data.birthday;
        const userBody: UsersFormBody = {
            language: 'english',
            role: 'student',
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender ? data.gender : null,
            postalCode: data.zipCode ? data.zipCode: '',
            phoneNumber: data.phone ? data.phone: null,
            allowPhoneContact: !!data.allowContactViaPhone,
            allowEmailContact: !!data.allowContactViaEmail,
            spokenLanguages: data.language,
            birthday: birthday.toISOString(),
        }


        this.apiService.createUser(userBody).pipe(
            mergeMap((userData) => {
                const studentFormData = {
                    userId: userData.id,
                    instruments: data.selectedInstruments,
                    genres: data.genres,
                    experienceLevel: data.priorKnowledge,
                    teachingLocation: data.teachingLocation,
                    // genderPreference: genderPreference,
                    description: data.goals
                }
                return this.apiService.createStudent(studentFormData)
            }),
            mergeMap((student) => {
                return this.apiService.persistSchedule(schedulesBody, student.id)
            })
        ).subscribe(() => {
            this._showSuccess$.next(true);
        }, () => {
        });
    }

    createTeacher(data: TeacherRegisterData) {
        const getSchedulesBodyFun = getSchedulesBody;
        const schedulesBody = getSchedulesBodyFun(data.availabilityTimeStamp);
        const birthday = data.birthday;

        const userBody: UsersFormBody = {
            language: 'english',
            role: 'teacher',
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender ? data.gender : null,
            postalCode: data.zipCode ? data.zipCode: '',
            phoneNumber: data.phone ? data.phone: null,
            allowPhoneContact: !!data.allowContactViaPhone,
            allowEmailContact: !!data.allowContactViaEmail,
            spokenLanguages: data.language,
            birthday: birthday.toISOString(),
        }
        const selectedAgePreferences = data.studentPreferenceAgeGroup.filter((pr: string) => pr !== 'no_preference');
       // const studentGenderPreference = data.studentGenderPreference.filter((gender: string) => gender !== 'no_preference');
        this.apiService.createUser(userBody).pipe(
            mergeMap((userData) => {
                const socials: SocialsItem[] = data.socialMediaGroup.map((social: {
                    socialMedia: string;
                    nickName: string;
                }) => ({type: social?.socialMedia, link: social?.nickName }));

                const filteredSocials = socials.filter((item : {
                    type: string;
                    link: string;
                }) => {
                    return Object.values(item).every(value => value !== '');
                });

                const filteredEducationGroup = data.educationGroup.filter((item: { institution: string; degree: string; }) => {
                    return item.institution.trim() !== '';
                });

                const organisationId = this.sharedDataService.invitedSchoolId ? this.sharedDataService.invitedSchoolId : schoolId;
                const experienceLevel: ExperienceLevel[] = data.studentExperienceLevel.filter((ex: string) => ex !== 'no_preference');
                const teacherFormData = {
                    userId: userData.id,
                    schoolId: organisationId,
                    instruments: data.selectedInstruments,
                    genres: data.genres,
                    studentsExperienceLevel: experienceLevel,
                    teachingLocation: data.teachingLocation,
                    education: filteredEducationGroup.length > 0 ? filteredEducationGroup : null,
                    socials: filteredSocials.length > 0 ? filteredSocials : null,
                    agePreference: selectedAgePreferences,
                    // genderPreference: studentGenderPreference,
                    description: data.goals
                }
                return this.apiService.createTeacher(teacherFormData);
            }),
            mergeMap((teacher) => {
                return this.apiService.persistSchedule(schedulesBody, teacher.id);
            })).subscribe(() => {
                this._showSuccess$.next(true);
        }, () => {
        });
    }

    createOrganisation(data: SchoolRegisterData) {
       const socials: SocialsItem[] = data.socialMediaGroup.map((social: {
            socialMedia: string;
            link: string;
        }) => ({type: social?.socialMedia, link: social?.link }));

        const filteredSocials = socials.filter((item : {
            type: string;
            link: string;
        }) => {
            return Object.values(item).every(value => value !== '');
        });

        const filteredAssociationsGroup = data.associationsGroup.filter((item: { name: string; membership: string; }) => {
            return item.name.trim() !== '';
        });

        const userBody: UsersFormBody = {
            language: 'english',
            role: 'school_admin',
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            postalCode: data.postalCode,
            phoneNumber: data.phone ? data.phone  : null,
            allowPhoneContact: !!data.allowContactViaPhone,
            allowEmailContact: !!data.allowContactViaEmail,
        }

        console.log(data, 'data')

        const priceRange = data.priceRangeGroup.map((item: {
            price: string;
            length: string;
        }) => {
            const numericPriceString = item.price.replace(/[^\d.,€]/g, '');
            const numericPrice = parseFloat(numericPriceString.replace(',', '.'));
            return {price: numericPrice, length: item.length }
        });

        const organisationFormData = {
            name: data.name,
            language: 'english',
            socials: filteredSocials,
            unions: filteredAssociationsGroup,
            description: data.description,
            sponsor: data.sponsoringCompany ? data.sponsoringCompany : null,
            postalCode: data.postalCode ? data.postalCode : null,
            phoneNumber: data.phone ? data.phone : null,
            website: data.website ? data.website : null,
            matchNotification: data.matchNotification,
            matchNotificationEmails: data.matchEmail ? [data.matchEmail] : null,
            priceRange,
        }


        this.apiService.createOrganisation(organisationFormData).pipe(
            switchMap((org) => {
                userBody.manageSchoolId = org.id;
                return this.apiService.createUser(userBody);
            })
        ).subscribe(() => {
            this._showSuccess$.next(true);
        },err => {
        });
    }

    ngOnDestroy(): void {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }


    redirectToLogin() {
        this.router.navigate(['/app']).then(() => {
            this.router.navigate(['/login']);
            // @ts-ignore
            this.userService.signUpRole = null;
        });
    }

    changeLanguage() {
        let selectedLanguage: keyof typeof ISO_CONVERTER;

        if (this.selectedLanguage.toLocaleLowerCase() === 'en') {
            selectedLanguage = 'de';
        } else {
            selectedLanguage = 'en';
        }

        this.languageService.change(selectedLanguage).then();
    }

}
