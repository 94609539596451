import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { forkJoin, switchMap } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { UserInfo } from '../../../core/models/user-info.interface';
import { ApiService } from '../../services/api/api.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { MusicEntity, MusicService } from '../../../shared/services/music.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    get user(): UserInfo {
        return this.userService.userInfo;
    }

    loadDashboard = false;
    LoadInstrumentAndGenres = false;
    instrumentsData: { top10: MusicEntity[]; notTop10: MusicEntity[]; allItems: MusicEntity[] };
    genresData: { top10: MusicEntity[]; notTop10: MusicEntity[]; allItems: MusicEntity[] };
    constructor(
        private userService: UserService,
        private apiService: ApiService,
        private auth: AuthenticationService,
        private musicService: MusicService,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        const isAuthorized = this.auth.isAuthorized();
        if (!isAuthorized) {
            this.auth.refreshAccessToken().pipe(switchMap(() => {
                return this.apiService.getUser(this.userService.userInfo.userId)
            })).subscribe((user) => {
                this.userService.userIntroInfo['userFirstName'] = user.firstName;
                this.loadDashboard = true;
            });
            return;
        }

        this.apiService.getUser(this.userService.userInfo.userId).subscribe((user) => {
            this.userService.userIntroInfo['userFirstName'] = user.firstName;
            this.loadDashboard = true;
        });
        this.getInstrumentsAndGenres();
        this.renderer.setStyle(document.body, 'background-color', 'var(--primary-background-color)');
    }

    ngOnDestroy() {
        this.renderer.setStyle(document.body, 'background-color', 'inherit');
    }

    getInstrumentsAndGenres() {
        forkJoin([
            this.musicService.fetchGenres('1', '1000'),
            this.musicService.fetchInstruments('1', '1000')
        ]).subscribe(([genresResponse, instrumentsResponse]) => {
            this.instrumentsData = instrumentsResponse;
            this.genresData = genresResponse;
            this.LoadInstrumentAndGenres = true;
        });
    }

}
