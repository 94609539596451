import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Avatar, Student, StudentLesson, StudentsResponse, Teacher } from '../../../../services/api/api.type';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { StudentProfileInfoComponent } from '../student-profile-info/student-profile-info.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../../services/api/api.service';
import {
    MatchingTimeSelectComponent,
    TimeSelect
} from '../../../match-teachers/matching-time-select/matching-time-select.component';
import { ComponentType } from '@angular/cdk/overlay';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MusicEntity } from '../../../../../shared/services/music.service';

type StudentWithTeacher = Student & { teacher?: Teacher };

@Component({
  selector: 'app-student-list-mobile',
  templateUrl: './student-list-mobile.component.html',
  styleUrls: ['./student-list-mobile.component.scss']
})
export class StudentListMobileComponent implements OnInit, OnDestroy {
    protected readonly UserRoles = UserRoles;
    dialogRef: MatDialogRef<StudentProfileInfoComponent | MatchingTimeSelectComponent>;
    selectedStudentId: string;
    currentLanguage: string = this.translateService.currentLang;
    destroy$ = new Subject();

    @Input() students: StudentsResponse;
    @Input() teacher?: Teacher;
    @Input() studentLessons: StudentLesson[];
    @Input() schoolId: string;
    @Input() role: string;
    @Input() newStudents?: StudentsResponse;
    @Input() teacherNotifyData?: { earliestLesson?: string | undefined | Date; student: Student | undefined };

    @Output() onApprove = new EventEmitter();
    @Output() onLessonTimeSelect = new EventEmitter();
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

    constructor(
                private dialog: MatDialog,
                private apiService: ApiService,
                private cd: ChangeDetectorRef,
                private translateService: TranslateService,) {
    }
    ngOnInit() {
        this.handleLanguageChange();
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    isMusicEntity(instrument: any): instrument is MusicEntity {
        return instrument && typeof instrument === 'object' && 'nameEn' in instrument;
    }

    get studentFileUrl(): string {
        if (this.teacherNotifyData?.student?.files) {
            return this.getFileUrl(this.teacherNotifyData?.student?.files);
        }
        return '';
    }

    handleLanguageChange() {
        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: LangChangeEvent) => {
                this.currentLanguage = event.lang;
                this.cd.detectChanges();
            });
    }

    getFileUrl(files: Avatar[]): string {
        if (files && files.length > 0) {
            return files[0].url;
        }
        return '';
    }

    showProfile(student: Student) {
        this.openDialog(StudentProfileInfoComponent, 'student-profile-info', {...student, teacher: this.teacher});
    }

    openDialog(component: ComponentType<StudentProfileInfoComponent | MatchingTimeSelectComponent>, panelClass: string, dialogData?: StudentWithTeacher | TimeSelect): void {
        document.body.classList.add('modal-open');
        let config: MatDialogConfig = {
                panelClass: panelClass,
                hasBackdrop: true,
                width: '100vw',
                height: '95vh',
                maxWidth: '100vw',
                autoFocus: false,
                disableClose: true,
                position: {top: '48px'},
                data: dialogData
            }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result && panelClass === 'time-select') {
                const modalComponentInstance = this.dialogRef.componentInstance as StudentProfileInfoComponent;
                this.onLessonTimeSelect.emit({modal: modalComponentInstance, id: this.selectedStudentId});
            }
            console.log('Dialog closed result', result);
        });
        if (panelClass === 'student-profile-info') {
            this.dialogRef = dialogRef;
            const modalComponentInstance = this.dialogRef.componentInstance as StudentProfileInfoComponent;
            modalComponentInstance.disapprove.subscribe(({student, id}) => {
                this.selectedStudentId = id;
                this.disapprove(student, modalComponentInstance);
            });
            modalComponentInstance.approve.subscribe(({student, id}) => {
                this.selectedStudentId = id;
                this.approve(student);
            })
        }
    }

    disapprove(student: Student, modal: StudentProfileInfoComponent) {
        this.apiService.updateMatching({
            teacherId: this.teacher?.teacher.id || '',
            studentId: student.student.id,
            acceptedTeacher: false,
        }).subscribe(() => {
            this.onLessonTimeSelect.emit({modal, id: student.student.id});
            console.log('Successfully declined.')
        });
    }


    approve(student: Student) {
        const teacherId = this.teacher?.teacher.id || '';
        this.apiService.getMatching(teacherId, student.student.id).subscribe(
            (data) => {
                const modalDescription = {
                    title: 'We’ve found a new student for you!',
                    question: 'When do you have time for a trial lesson?'
                }
                this.openDialog(MatchingTimeSelectComponent, 'time-select', { slots: data.timeSlots, teacher: this.teacher, student, modalDescription, multiple: false, hasTeacher: true });
            }
        )
    }

}
