<div class="title-container" [ngClass]="{'hide-empty-dashboard-line': teachers?.list?.length === 0}">
    <div class="title">
        <span>{{ 'student-teachers-dashboard-table.title.teachers' | translate }}</span>
    </div>
</div>

<!-- Table Content -->
<div *ngIf="teachers?.list && teachers.list.length > 0; else emptyContainer" class="table-container">
    <table mat-table [dataSource]="dataSource">

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName" sticky>
            <th mat-header-cell *matHeaderCellDef>{{ 'student-teachers-dashboard-table.header.first-name' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName" sticky>
            <th mat-header-cell *matHeaderCellDef>{{ 'student-teachers-dashboard-table.header.last-name' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>

        <!-- Instruments Column -->
        <ng-container matColumnDef="instruments">
            <th mat-header-cell *matHeaderCellDef>{{ 'student-teachers-dashboard-table.header.instruments' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.teacher?.instruments">
                      <span *ngFor="let instrument of element.teacher?.instruments; let last = last">
                          {{ currentLanguage === 'eng' ? instrument.nameEn : instrument.nameDe }}
                          <span *ngIf="!last">, </span>
                     </span>
                </ng-container>
                <span *ngIf="!element.teacher?.instruments || element.teacher?.instruments?.length === 0">—</span>
            </td>
        </ng-container>


        <!-- Actions Column -->
        <ng-container matColumnDef="terminate" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> {{ 'student-teachers-dashboard-table.header.actions' | translate }} </th>
            <td mat-cell *matCellDef="let element; let i = index">
                <div class="actions-container">
                    <button *ngIf="teachers.list[!this.currentIndex ? i : this.currentIndex + i]?.acceptedStudent" mat-button (click)="disapprove(teachers.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'student-teachers-dashboard-table.button.disapprove' | translate }}</button>
                    <button *ngIf="!teachers.list[!this.currentIndex ? i : this.currentIndex + i]?.acceptedStudent" class="raised-button" color="primary" mat-raised-button mat-button (click)="approve(teachers.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'student-teachers-dashboard-table.button.approve' | translate }}</button>
                </div>
            </td>
        </ng-container>


        <ng-container matColumnDef="contact" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index">
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <a target="_blank" class="menu-link" href="mailto:{{ element.email }}">{{ element.email }}</a>
                    </button>
                    <button *ngIf="element.phoneNumber" mat-menu-item>
                        <a target="_blank" class="menu-link" href="tel:{{ element.phoneNumber }}">{{ element.phoneNumber }}</a>
                    </button>
                </mat-menu>
                <div class="actions-container">
                    <button [matMenuTriggerFor]="menu" mat-button>{{'student-teachers-dashboard-table.button.contact' | translate}}</button>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<!-- Empty Container Template -->
<ng-template #emptyContainer>
    <div class="empty-container">
        {{ 'student-teachers-dashboard-table.message.noDataAvailable' | translate }}
    </div>
</ng-template>

<!-- Paginator -->
<mat-paginator [ngClass]="{'empty-paginator': dataSource && dataSource.data && dataSource.data.length < 10, 'hide-empty-dashboard-line': teachers?.list?.length === 0}" [pageSizeOptions]="[10, 20, 30]"
               showFirstLastButtons
               (page)="onPageChange($event)">
</mat-paginator>

