import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { getFieldProperties } from '../../../../../../../shared/validators/has-error';
import { AGE_GROUP, EXPERIENCE_LEVEL, GENDER, Step } from '../../../../../../../shared/constants/form-option';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-prefer-student-preference',
  templateUrl: './step-prefer-student-preference.component.html',
  styleUrls: ['./step-prefer-student-preference.component.scss']
})
export class StepPreferStudentPreferenceComponent implements OnInit {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    protected readonly getFieldProperties = getFieldProperties;
    experienceLevel = EXPERIENCE_LEVEL;
    ageGroupOptions = AGE_GROUP;
    selectGender = GENDER;

    get getSelectedOptions() {
        const studentPreferenceAgeGroupControl = this.formGroup.get('studentPreferenceAgeGroup');
        if (studentPreferenceAgeGroupControl?.value.includes('no_preference')) {
            return this.translateService.instant('signup-form.teacher.step-preferStudentPreference.no_preference');
        } else {
            return studentPreferenceAgeGroupControl?.value;
        }
    }

    get getSelectedExperienceLevelOptions() {
        const studentExperienceLevelControl = this.formGroup.get('studentExperienceLevel');
        if (studentExperienceLevelControl?.value.includes('no_preference')) {
            return this.translateService.instant('signup-form.teacher.step-preferStudentPreference.no_preference');
        } else {
            if (studentExperienceLevelControl?.value) {
                const translatedOptions = studentExperienceLevelControl?.value?.map((value: string) =>
                    this.translateService.instant('experience_levels.' + value)
                );
                return translatedOptions.join(', ')
            } else {
                return [];
            }
        }
    }

    get getSelectedGenderOptions() {
        const genderControl = this.formGroup.get('studentGenderPreference');
        if (genderControl?.value.includes('no_preference')) {
            return this.translateService.instant('signup-form.teacher.step-preferStudentPreference.no_preference');
        } else {
            if (genderControl?.value) {
                const translatedOptions = genderControl?.value?.map((value: string) =>
                    this.translateService.instant('genders.' + value)
                );
                return translatedOptions.join(', ')
            } else {
                return [];
            }
        }
    }


    constructor(private fb: FormBuilder, private translateService: TranslateService) {
    }

    ngOnInit() {
        this.addControls();
    }

    onSubmit() {
        this.submitForm.emit();
        this.stepper.next();
    }

    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('studentExperienceLevel', this.fb.control('no_preference', Validators.required));
            this.formGroup.addControl('studentPreferenceAgeGroup', this.fb.control('no_preference', Validators.required));
            // this.formGroup.addControl('studentGenderPreference', this.fb.control('no_preference', Validators.required));
            // this.onNoPreferenceExperienceLevel();
            // this.onNoGenderPreference();
            // this.onNoPreference();
            this.updateFormControl(false, 'studentExperienceLevel');
            this.updateFormControl(false, 'studentPreferenceAgeGroup')
        }
    }

    onNoGenderPreference() {
        const genderControl = this.formGroup.get('studentGenderPreference');
        const currentOptions = genderControl?.value || [];
        if (currentOptions.includes('no_preference')) {
            const genderValues = this.selectGender.map((exp) => exp.value);
            genderControl?.setValue([...genderValues, 'no_preference']);
        } else {
            genderControl?.setValue([]);
        }
    }

    onNoPreferenceExperienceLevel() {
        const studentExperienceLevelControl = this.formGroup.get('studentExperienceLevel');
        const currentOptions = studentExperienceLevelControl?.value || [];
        if (currentOptions.includes('no_preference')) {
            const experienceLevelValues = this.experienceLevel.map((exp) => exp.value);
            studentExperienceLevelControl?.setValue([...experienceLevelValues, 'no_preference']);
        } else {
            studentExperienceLevelControl?.setValue([]);
        }
    }

    onNoPreference() {
        const studentPreferenceAgeGroupControl = this.formGroup.get('studentPreferenceAgeGroup');
        const currentOptions = studentPreferenceAgeGroupControl?.value || [];
        if (currentOptions.includes('no_preference')) {
            studentPreferenceAgeGroupControl?.setValue([...this.ageGroupOptions, 'no_preference']);
        } else {
            studentPreferenceAgeGroupControl?.setValue([]);
        }
    }

    updateFormControl(otherOptions: boolean = false, formControlName: string, noPreferenceValue = 'no_preference') {
        const experienceLevelControl = this.formGroup.get(formControlName);
        const currentOptions = experienceLevelControl?.value || [];
        if (currentOptions.includes(noPreferenceValue)) {
            experienceLevelControl?.setValue([noPreferenceValue]);
        }
        if (otherOptions) {
            const updatedOptions = currentOptions.filter((option: string) => option !== noPreferenceValue);
            experienceLevelControl?.setValue(updatedOptions);
        }
    }

}
