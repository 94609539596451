import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { UserRoles } from '../../../../../../../../core/models/user-info.interface';
import { Step } from '../../../../../../../../shared/constants/form-option';
import { MatChipListbox, MatChipListboxChange, MatChipOption } from '@angular/material/chips';
import { MusicEntity, MusicService } from '../../../../../../../../shared/services/music.service';
import { Subject, takeUntil } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { GenresComponent } from '../../../../../../../components/genres/genres.component';

@Component({
  selector: 'app-step-genres',
  templateUrl: './step-genres.component.html',
  styleUrls: ['./step-genres.component.scss']
})
export class StepGenresComponent implements OnInit, OnDestroy {
    @ViewChild('chipList') chipList: MatChipListbox;

    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    @Input() signUpRole: UserRoles;

    displayedChips: { chip: MusicEntity; selected: boolean }[] = [];
    destroy$ = new Subject();
    currentLanguage: string = this.translateService.currentLang;

    get moreChipCount(): number {
        return MusicService.genresData.notTop10.length;
    }


    constructor(
        private fb: FormBuilder,
        private musicService: MusicService,
        private translateService: TranslateService,
        private cd: ChangeDetectorRef,
        private dialog: MatDialog) {}
    ngOnInit() {
        this.handleLanguageChange()
        const stepChips = MusicService.genresData.top10;
        this.addControls();
        this.displayedChips = stepChips.map((chip) => ({ chip, selected: false }));
    }

    handleLanguageChange() {
        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: LangChangeEvent) => {
                this.currentLanguage = event.lang;
                this.musicService.setLanguage(this.currentLanguage);
                const selectedChipIds = this.displayedChips.filter(chipObj => chipObj.selected).map(chipObj => chipObj.chip.id);

                const selectedTop10Chips = MusicService.genresData.top10.map(chip => ({
                    chip,
                    selected: selectedChipIds.includes(chip.id) || this.isChipSelected(chip)
                }));

                const selectedNotTop10Chips = MusicService.genresData.notTop10.filter(chip => selectedChipIds.includes(chip.id))
                    .map(chip => ({
                        chip,
                        selected: true
                    }));

                this.displayedChips = [
                    ...selectedTop10Chips,
                    ...selectedNotTop10Chips
                ];
                this.sortDisplayChips();
                this.cd.detectChanges();
            });
    }

    isChipSelected(chip: MusicEntity): boolean {
        const existingChip = this.displayedChips.find((item) => item.chip.id === chip.id);
        return existingChip ? existingChip.selected : false;
    }

    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('genres', this.fb.control('', Validators.required));
        }
    }

    getSelectedChips() {
        if (this.chipList.selected) {
            const selectedValues: MatChipOption[] = Array.isArray(this.chipList.selected)
                ? this.chipList.selected
                : [this.chipList.selected];
            return selectedValues.map((chips: MatChipOption) => chips['_value']);
        }
        return [];
    }

    handleSelectedGenresChange() {
        const notTop10Ids = MusicService.genresData.notTop10.map(chip => chip.id);

        setTimeout(() => {
            this.displayedChips = this.displayedChips.filter(chipObj => {
                const chip = chipObj.chip;
                return !(notTop10Ids.includes(chip.id) && !chipObj.selected);
            });

            this.cd.markForCheck();
            this.formGroup.get('genres')?.setValue(this.getSelectedChips());

            this.submitForm.emit();
        });
    }

    showMoreChips() {
        const selectedNotTop10Chips = this.displayedChips
            .filter(chip => chip.selected && MusicService.genresData.notTop10.some(notTop10Chip => notTop10Chip.id === chip.chip.id))
            .map(chip => chip.chip);
        this.openDialog(GenresComponent, 'activate-form', { chipList: MusicService.genresData.notTop10, lang: this.currentLanguage, selectedChips: selectedNotTop10Chips })
    }

    onSubmit() {
        this.stepper.next();
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    openDialog<T>(
        component: ComponentType<T>,
        panelClass: string,
        dialogData: { chipList: MusicEntity[], lang: string, selectedChips: MusicEntity[] },
        width: string = '640px',
    ): void {
        document.body.classList.add('modal-open');

        const dialogRef: MatDialogRef<T> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: false,
            position: { top: '32px' },
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                const selectedIds = result as string[];

                const selectedChips = dialogData.chipList.filter(chip => selectedIds.includes(chip.id));
                this.displayedChips = this.displayedChips.filter(chipObj =>
                    !MusicService.genresData.notTop10.some(notTop10Chip => notTop10Chip.id === chipObj.chip.id)
                );

                selectedChips.forEach(selectedChip => {
                    const existingChip = this.displayedChips.find(chipObj => chipObj.chip.id === selectedChip.id);
                    if (!existingChip) {
                        this.displayedChips.push({ chip: selectedChip, selected: true });
                        this.sortDisplayChips();
                    }
                });

                this.handleSelectedGenres();
            }


            console.log('Dialog closed result', result);
        });

    }

    handleSelectedGenres() {
        const selectedGenresIds = this.displayedChips
            .filter(chip => chip.selected)
            .map(chip => chip.chip.id);


        this.cd.detectChanges();
        this.formGroup.get('genres')?.setValue(selectedGenresIds);
    }

    sortDisplayChips() {
        const sortBy = this.currentLanguage === 'eng' ? 'nameEn' : 'nameDe';

        this.displayedChips.sort((a, b) => {
            const nameA = a.chip[sortBy] || '';
            const nameB = b.chip[sortBy] || '';

            return nameA.localeCompare(nameB, this.currentLanguage === 'eng' ? 'en' : 'de', { sensitivity: 'base' });
        });
    }

}
