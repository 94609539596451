import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
    @Input() tooltip: ({ name: string, url: string } | null)[];
    @Input() left: number = 0;
    @Input() top: number = 0;

}
