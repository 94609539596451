import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

export interface MusicEntity {
    id: string;
    top10: string;
    availableInMusiq: string;
    nameDe: string;
    nameEn: string;
    nameVariant: string | null;
    gnd: string | null;
    wikidata: string | null;
    mimo: string | null;
}


@Injectable({
  providedIn: 'root'
})
export class MusicService {

    private apiUrl = environment.apiUrl;
    private httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    currentLanguage: string = this.translateService.currentLang;


    public static instrumentsData: { top10: MusicEntity[]; notTop10: MusicEntity[]; allItems: MusicEntity[] };
    public static genresData: { top10: MusicEntity[]; notTop10: MusicEntity[]; allItems: MusicEntity[] };

    constructor(private httpClient: HttpClient, private translateService: TranslateService) {
    }


    fetchInstruments(page: string, limit: string): Observable<any> {
        const sortBy = this.currentLanguage === 'eng' ? 'nameEn' : 'nameDe';
        return this.httpClient.get<any>(`${this.apiUrl}/instruments?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${'ASC'}`, this.httpOptions).pipe(
            map((instruments: { count: number, page: number, list: any[], pages: number }) => {
                const instrumentsList = instruments.list;

                if (!MusicService.instrumentsData) {
                    MusicService.instrumentsData = {
                        top10: instrumentsList.filter(
                            (instrument) =>
                                instrument['top10'] === 'TRUE' && instrument['availableInMusiq'] === 'TRUE'
                        ),
                        notTop10: instrumentsList.filter(
                            (instrument) =>
                                instrument['availableInMusiq'] === 'TRUE' && instrument['top10'] !== 'TRUE'
                        ),
                        allItems: instrumentsList.filter(
                            (instrument) => instrument['availableInMusiq'] === 'TRUE'
                        )
                    }
                }

                return MusicService.instrumentsData;
            })
        );
    }


    fetchGenres(page: string, limit: string): Observable<any> {
        const sortBy = this.currentLanguage === 'eng' ? 'nameEn' : 'nameDe';
        return this.httpClient.get<any>(`${this.apiUrl}/genres?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${'ASC'}`, this.httpOptions).pipe(
            map((genres: any) => {
                const genresList = genres.list;
                if (!MusicService.genresData) {
                    MusicService.genresData = {
                        top10: genresList.filter(
                            (genre: {
                                [x: string]: string;
                            }) => genre['top10'] === 'TRUE' && genre['availableInMusiq'] === 'TRUE'
                        ),
                        notTop10: genresList.filter(
                            (genre: { [x: string]: string; }) =>
                                genre['availableInMusiq'] === 'TRUE' && genre['top10'] !== 'TRUE'
                        ),
                        allItems: genresList.filter(
                            (genre: { [x: string]: string; }) => genre['availableInMusiq'] === 'TRUE'
                        )
                    };
                }
                return MusicService.genresData;
            })
        );
    }

    updateGenre(id: number, genreData: any): Observable<any> {
        const url = `${this.apiUrl}/genres/${id}`;
        return this.httpClient.put<any>(url, genreData, this.httpOptions);
    }

    getInstrumentsByID(ids: (string | MusicEntity | null)[]) {
        return ids.map(id => {
            const instrument = MusicService.instrumentsData?.allItems?.find((item) => item.id === id);
            return instrument ? instrument : id;
        });
    }

    getGenresByID(ids: (string | MusicEntity | null)[]) {
        return ids.map(id => {
            const genre = MusicService.genresData?.allItems?.find((item) => item.id === id);
            return genre ? genre : id;
        });
    }


    fetchSortedInstruments(page: string, limit: string, sortBy: string, direction: string): Observable<any> {
        return this.httpClient.get<any>(`${this.apiUrl}/instruments?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
            map((instruments: { count: number, page: number, list: any[], pages: number }) => {
                const instrumentsList = instruments.list;
                return ({
                    allItems: instrumentsList.filter(
                        (instrument) => instrument['availableInMusiq'] === 'TRUE'
                    )
                });
            })
        );
    }

    fetchSortedGenres(page: string, limit: string, sortBy: string, direction: string): Observable<any> {
        return this.httpClient.get<any>(`${this.apiUrl}/genres?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
            map((instruments: { count: number, page: number, list: any[], pages: number }) => {
                const instrumentsList = instruments.list;
                return ({
                    allItems: instrumentsList.filter(
                        (instrument) => instrument['availableInMusiq'] === 'TRUE'
                    )
                });
            })
        );
    }

    sortInstrumentsAndGenres() {
        MusicService.instrumentsData.allItems = this.sortItems(MusicService.instrumentsData.allItems);
        MusicService.instrumentsData.notTop10 = this.sortItems(MusicService.instrumentsData.notTop10);
        MusicService.instrumentsData.top10 = this.sortItems(MusicService.instrumentsData.top10);
        MusicService.genresData.allItems = this.sortItems(MusicService.genresData.allItems);
        MusicService.genresData.notTop10 = this.sortItems(MusicService.genresData.notTop10);
        MusicService.genresData.top10 = this.sortItems(MusicService.genresData.top10);
    }

    sortItems(items: any[]) {
        const sortBy = this.currentLanguage === 'eng' ? 'nameEn' : 'nameDe';
        return items.sort((a, b) => {
            const nameA = a[sortBy] || '';
            const nameB = b[sortBy] || '';

            return nameA.localeCompare(nameB, this.currentLanguage === 'eng' ? 'en' : 'de', { sensitivity: 'base' });
        });
    }



    setLanguage(language: string) {
        this.currentLanguage = language;
        this.sortInstrumentsAndGenres();
    }
}
