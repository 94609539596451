import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserIntroInfo, UserRoles } from '../../../core/models/user-info.interface';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
    @Input() role: UserRoles;
    @Input() rootIntroInfo?: UserIntroInfo;
    @Input() genresCount: number;
    @Input() instrumentsCount: number;
    @Output() openOrgList: EventEmitter<void> = new EventEmitter<void>();
    @Output() openTeachersList: EventEmitter<void> = new EventEmitter<void>();
    @Output() openStudentsList: EventEmitter<void> = new EventEmitter<void>();
    @Output() openActiveMatchesList: EventEmitter<void> = new EventEmitter<void>();
    @Output() openPendingMatchesList: EventEmitter<void> = new EventEmitter<void>();
    @Output() openPastMatchesList: EventEmitter<void> = new EventEmitter<void>();
    @Output() openInstrumentsList: EventEmitter<void> = new EventEmitter<void>();
    @Output() openGenresList: EventEmitter<void> = new EventEmitter<void>();


    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get teachersAmount(): number {
        return <number>this.rootIntroInfo?.teachersAmount;
    }
    constructor(private platformService: PlatformService) {
    }

    openOrganisationList() {
       this.openOrgList.emit();
    }

    openTeacherList() {
        this.openTeachersList.emit();
    }

    openStudentList() {
        this.openStudentsList.emit();
    }

    openInstrumentList() {
        this.openInstrumentsList.emit();
    }

    openGenreList() {
        this.openGenresList.emit();
    }

    openActiveMatchList() {
        this.openActiveMatchesList.emit();
    }

    openPendingMatchList() {
        this.openPendingMatchesList.emit();
    }

    openPastMatchList() {
        this.openPastMatchesList.emit();
    }

}
