import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    AGE_GROUP,
    CALENDAR_FORMATS,
    CHIPS, EMAIL_VALIDATION_REG,
    EXPERIENCE_LEVEL,
    GENDER,
    LANGUAGES,
    MATCH_NOTIFICATION,
    SINGLE_GENDER,
    TEACHING_LOCATION
} from '../../../../shared/constants/form-option';
import { ApiService } from '../../../services/api/api.service';
import { MatSelectChange } from '@angular/material/select';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    AgePreference,
    EducationItem, ExperienceLevel,
    ScheduleItem,
    SocialsItem,
    Teacher,
    TeacherFormBody, TeachingLocation,
    UsersFormBody
} from '../../../services/api/api.type';
import { debounceTime, mergeMap, Subject, takeUntil } from 'rxjs';
import { schoolId } from '../../../../shared/constants/table-data';
import { DeleteConfirmComponent } from '../../dashboard/delete-confirm/delete-confirm.component';
import { UserService } from '../../../services/user/user.service';
import { UserRoles } from '../../../../core/models/user-info.interface';
import { getSchedulesBody } from '../../../../shared/constants/schedules';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '../../../services/platform.service';
import { MatChipSelectionChange } from '@angular/material/chips';
import { ComponentType } from '@angular/cdk/overlay';
import { MusicEntity, MusicService } from '../../../../shared/services/music.service';

@Component({
    selector: 'app-teacher-form',
    templateUrl: './teacher-form.component.html',
    styleUrls: ['./teacher-form.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: CALENDAR_FORMATS},
    ],
})
export class TeacherFormComponent implements OnInit {
    teacherProfileForm: FormGroup;
    originalFormValues: typeof this.teacherProfileForm.value;
    loading = false;
    selectedSchedules: {list: ScheduleItem[] };
    currentDate: Date = new Date();
    destroy$: Subject<void> = new Subject<void>();


    @ViewChildren('customInput') inputs: QueryList<ElementRef>;

    get educationGroup(): any {
        return this.teacherProfileForm.get('educationGroup') as FormArray;
    }

    get socialMediaGroup(): any {
        return this.teacherProfileForm.get('socialMediaGroup') as FormArray;
    }

    get availabilityTimeStamp(): any {
        return this.teacherProfileForm.get('availabilityTimeStamp') as FormArray;
    }

    get isValidAvailabilityTimeStamp(): boolean {
        return (!!this.availabilityTimeStamp.value.find((timeStamp: {
            day: string,
            date: string,
            selectedDate: Date,
            selectedTime: string[],
        }) => timeStamp.selectedTime.length > 0));
    }

    get getSelectedOptions() {
        const studentPreferenceAgeGroupControl = this.teacherProfileForm.get('studentPreferenceAgeGroup');
        if (studentPreferenceAgeGroupControl?.value?.includes('no_preference')) {
            return 'No Preference';
        } else {
            return studentPreferenceAgeGroupControl?.value;
        }
    }

    get getSelectedGenderOptions() {
        const genderControl = this.teacherProfileForm.get('studentGenderPreference');
        if (genderControl?.value?.includes('no_preference')) {
            return this.translateService.instant('teacher-profile.sections.student_preference.labels.age_group.no_preference');
        } else {
            if (genderControl?.value) {
                const translatedOptions = genderControl?.value?.map((value: string) =>
                    this.translateService.instant('genders.' + value)
                );
                return translatedOptions.join(', ')
            } else {
                return [];
            }
        }
    }

    get getTeachingLocationOptions() {
        const teachingLocationControl = this.teacherProfileForm.get('studentPreferenceLocation');
        if (teachingLocationControl?.value?.includes('no-preference')) {
            return this.translateService.instant('teacher-profile.sections.student_preference.labels.age_group.no_preference');
        } else {
            if (teachingLocationControl?.value) {
                const translatedOptions = teachingLocationControl?.value.map((value: string) =>
                    this.translateService.instant('teaching_locations.' + value)
                );
                return translatedOptions.join(', ')
            } else {
                return [];
            }
        }
    }

    get getStudentExperienceLevelOptions() {
        const studentExperienceLevelControl = this.teacherProfileForm.get('studentExperienceLevel');
        if (studentExperienceLevelControl?.value?.includes('no_preference')) {
            return this.translateService.instant('teacher-profile.sections.student_preference.labels.age_group.no_preference');
        } else {
            if (studentExperienceLevelControl?.value) {
                const translatedOptions = studentExperienceLevelControl?.value.map((value: string) =>
                    this.translateService.instant('experience_levels.' + value)
                );
                return translatedOptions.join(', ')
            } else {
                return [];
            }
        }
    }

    get email(): AbstractControl<any> | null  {
        return this.teacherProfileForm.get('email');
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    selectOptions = [
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'X', value: 'X' },
        { label: 'TikTok', value: 'TikTok' },
        { label: 'YouTube', value: 'YouTube' },
        { label: 'LinkedIn', value: 'LinkedIn' },
        { label: 'Custom', value: 'custom' }
    ]

    selectGender = GENDER;
    singleGender = SINGLE_GENDER;
    languageOptions = LANGUAGES;
    instrument: MusicEntity[];
    genres: MusicEntity[];
    teachingLocation = TEACHING_LOCATION;
    ageGroupOptions = AGE_GROUP;
    chips: { day: string; date: string; time: string[]; selectedDate?: Date }[] = CHIPS;
    matchNotifications = MATCH_NOTIFICATION;
    experienceLevel = EXPERIENCE_LEVEL;
    currentLanguage: string = this.translateService.currentLang;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<TeacherFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Teacher,
        private dialog: MatDialog,
        private readonly apiService: ApiService,
        private userService: UserService,
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private platformService: PlatformService,
        private elementRef: ElementRef,
        private musicService: MusicService) {
    }

    ngOnInit(): void {
        this.musicService.setLanguage(this.currentLanguage);
        this.instrument = [MusicService.instrumentsData.top10, MusicService.instrumentsData.notTop10].flat();
        this.genres = [MusicService.genresData.top10, MusicService.genresData.notTop10].flat();


        const weeklySchedule = this.generateWeekSchedule();
        this.chips.forEach(chip => {
            const currentDate = weeklySchedule.find((d) => d.day === chip.day);
            if (currentDate) {
                chip.date = currentDate?.date;
                chip.selectedDate = currentDate.selectedDate;
            }
            chip.time.forEach((time: string | object) => time = ({name: time, selected: false}))
        });
        this.chips.forEach((chip) => {
            chip.time.forEach((time: string | object) => time = ({name: time, selected: false}))
        });
        let genderPreferenceArray: string[] = [];

       /*if (this.data?.teacher?.genderPreference?.length === this.selectGender.length) {
           this.data?.teacher?.genderPreference.push('no_preference');
       }*/

       if (this.data?.teacher?.agePreference?.length === this.ageGroupOptions.length) {
           if (this.data && this.data.teacher) {
               this.data.teacher.agePreference = ['no_preference' as AgePreference];
           }
       }

        if (this.data?.teacher?.studentsExperienceLevel?.length === this.experienceLevel.length) {
            if (this.data && this.data.teacher) {
                this.data.teacher.studentsExperienceLevel = ['no_preference' as ExperienceLevel];
            }

        }

        if (this.data?.teacher?.teachingLocation?.length === this.teachingLocation.length || this.data?.teacher?.teachingLocation?.length=== 3) {
            if (this.data && this.data.teacher) {
                this.data.teacher.teachingLocation = ['no-preference' as TeachingLocation];
            }

        }
        this.teacherProfileForm = this.fb.group({
            firstName: [this.data?.firstName, [Validators.required]],
            zipCode: [this.data?.postalCode?.toString(), Validators.pattern(/^\d+$/)],
            language: [this.data?.language, Validators.required],
            lastName: [this.data?.lastName, [Validators.required]],
            birthday: [this.data?.birthday, Validators.required],
            educationGroup: this.fb.array([]),
            socialMediaGroup: this.fb.array([]),
            instrument: [this.data?.teacher?.instruments, Validators.required],
            genre: [this.data?.teacher?.genres, Validators.required],
            goals: [this.data?.teacher?.description],
            studentPreferenceLocation: [this.data?.teacher?.teachingLocation, Validators.required],
            spokenLanguages: [this.data?.spokenLanguages, Validators.required],
            contactMatchNotification: [''], // TODO
            studentPreferenceAgeGroup: [this.data?.teacher?.agePreference, Validators.required],
            studentExperienceLevel: [this.data?.teacher?.studentsExperienceLevel, Validators.required],
            email: [this.data?.email, [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]],
            gender: [this.data?.gender],
            // studentGenderPreference: [this.data?.teacher?.genderPreference, Validators.required],
            phone: [this.data?.phoneNumber],
            allowContactViaEmail: [!!this.data?.allowEmailContact],
            allowContactViaPhone: [!!this.data?.allowPhoneContact],
            availabilityTimeStamp: this.fb.array([]),
        });
        this.addEducationControls(!!this.data, this.data?.teacher?.education);
        this.addSocialMediaControls(!!this.data, this.data?.teacher?.socials);
        this.chips.forEach((chip) => this.addAvailabilityTimeStamp(chip));
        this.setSelectedSchedules();
        this.originalFormValues = { ...this.teacherProfileForm.value };

        this.email?.valueChanges.pipe(
            debounceTime(300),
            takeUntil(this.destroy$)
        ).subscribe((value) => {
            this.checkIfUserExist(value);
        });
    }

    checkIfUserExist(email: string) {
        if (this.email?.valid) {
            if (this.data?.email && email === this.data?.email) {
                this.email?.setErrors(null);
                return;
            }
            this.apiService.checkIfUserExist(email).subscribe(
                () => {
                    this.email?.setErrors(null);
                },
                () => {
                    this.email?.setErrors({ alreadyExist: true });
                    this.cd.detectChanges();
                }
            );
        }
    }

    trackById(index: number, item: MusicEntity): string {
        return item.id;
    }

    setSelectedSlot(selectedTime: string[],timeSlot: string) {
        return !!selectedTime?.includes(timeSlot);
    }

    setSelectedAll(selectedTime: string[]) {
        const uniqueArray = selectedTime?.filter((value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
        });
        return (uniqueArray.length === 5);
    }

    scrollToErrorField(): void {
        const firstErrorField = this.elementRef.nativeElement.querySelector('mat-form-field.ng-invalid');
        if (firstErrorField) {
            const parentElement = firstErrorField.parentElement;
            parentElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    setSelectedSchedules() {
        if (this.data?.teacher) {
            this.apiService.getSchedules(this.data.id).subscribe(schedules => {
                this.selectedSchedules = schedules;
                this.selectedSchedules.list = this.selectedSchedules.list.filter((schedule: ScheduleItem) => {
                    const startHour = this.getHour(schedule.startTime);
                    const endHour = this.getHour(schedule.endTime);
                    return (startHour > 6) && (endHour < 22);
                });
                this.onSelectTime(null, NaN, this.selectedSchedules.list, true);
            });
        }
    }

    getHour(time: string): number {
        const [hours] = time.split(':').map(Number);
        return hours;
    }

    getFieldProperties<T>(fieldName: string, controlField?:AbstractControl<T> | null): {
        control: AbstractControl<any> | null;
        hasError: boolean;
        isRequired: boolean;
        hasAllErrors: boolean;
    } {
        let control: AbstractControl<any> | null;
        if (controlField) {
            control = controlField.get(fieldName);
        } else {
            control = this.teacherProfileForm.get(fieldName);
        }
        const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
        const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
        const hasAllErrors = isRequired || hasError;
        return { control, isRequired, hasError, hasAllErrors } ;
    }

    addEducationControls(defaultValue?: boolean, educations: EducationItem[] = []) {
        if (defaultValue && educations?.length > 0) {
            educations?.forEach(education => {
                const control = this.fb.group({
                    institution: [ education.institution, this.crossFieldValidator.bind(this) ],
                    degree: [ education.degree, this.crossFieldValidator.bind(this) ]
                });
                this.educationGroup.push(control);
            })
        } else {
            const control = this.fb.group({
                institution: [ '', this.crossFieldValidator.bind(this) ],
                degree: [ '', this.crossFieldValidator.bind(this) ]
            });
            this.educationGroup.push(control);
        }
    }

    addSocialMediaControls(defaultValue?: boolean, socials: SocialsItem[] = []) {
        if (defaultValue && socials?.length > 0) {
            const selectOptions = this.selectOptions.map((option) => option.value);
            socials?.forEach(social => {
                let socialType: string = '';
                let hasCustom = false;
                if (selectOptions.includes(social.type)) {
                    socialType = social.type;
                } else if (social.type) {
                    hasCustom = true;
                    socialType = social.type;
                }
                const control = this.fb.group({
                    socialMedia: [ socialType, this.crossFieldSocialMediaValidator.bind(this) ],
                    link: [ social.link, this.crossFieldSocialMediaValidator.bind(this) ],
                    custom: [ hasCustom,  Validators.required ]
                });
                this.socialMediaGroup.push(control);
            })
        } else {
            const control = this.fb.group({
                socialMedia: [ '', this.crossFieldSocialMediaValidator.bind(this) ],
                link: [ '', this.crossFieldSocialMediaValidator.bind(this) ],
                custom: [ false,  Validators.required ]
            });
            this.socialMediaGroup.push(control);
        }
    }

    addAvailabilityTimeStamp(chip: { day: string; date: string; time: string[]; selectedDate?: Date }) {
        const control = this.fb.group({
            day: [ chip.day ],
            date: [ chip.date ],
            selectedDate: [ chip.selectedDate ],
            selectedTime: [ []],
        });
        this.availabilityTimeStamp.push(control);
    }

    removeEducation(index: number) {
        this.educationGroup.removeAt(index);
    }

    removeSocial(index: number) {
        this.socialMediaGroup.removeAt(index);
    }

    onSubmit() {
        this.teacherProfileForm.markAllAsTouched();
        if (this.teacherProfileForm.invalid || !this.isValidAvailabilityTimeStamp) {
            this.scrollToErrorField();
            return;
        }
        this.loading = true;

        const birthday = this.teacherProfileForm.get('birthday')?.value;
        const userBody: UsersFormBody = {
            language: this.teacherProfileForm.get('language')?.value,
            role: 'teacher',
            email: this.teacherProfileForm.get('email')?.value,
            firstName: this.teacherProfileForm.get('firstName')?.value,
            lastName: this.teacherProfileForm.get('lastName')?.value,
            gender: this.teacherProfileForm.get('gender')?.value,
            postalCode: this.teacherProfileForm.get('zipCode')?.value ? this.teacherProfileForm.get('zipCode')?.value : '',
            phoneNumber: this.teacherProfileForm.get('phone')?.value ? this.teacherProfileForm.get('phone')?.value : null,
            allowPhoneContact: this.teacherProfileForm.get('allowContactViaPhone')?.value,
            allowEmailContact: this.teacherProfileForm.get('allowContactViaEmail')?.value,
            spokenLanguages: this.teacherProfileForm.get('spokenLanguages')?.value,
            birthday: this.data ? new Date(birthday).toISOString() : birthday.toISOString(),
        }

        this.data ? this.editTeacher(userBody) : this.addTeacher(userBody);
    }

    editTeacher(userBody: UsersFormBody) {
        const getSchedulesBodyFun = getSchedulesBody;
        const schedulesBody = getSchedulesBodyFun(this.teacherProfileForm.get('availabilityTimeStamp')?.value);
        let selectedStudentPreferenceLocation = this.teacherProfileForm.get('studentPreferenceLocation')?.value.filter((pr: string) => pr !== 'no-preference');
        if (selectedStudentPreferenceLocation.length === 0 || selectedStudentPreferenceLocation.length === 3) {
            selectedStudentPreferenceLocation = this.teachingLocation.map((exp) => exp.value).filter((pr: string) => pr !== 'no-preference');
        }

        let selectedAgePreferences = this.teacherProfileForm.get('studentPreferenceAgeGroup')?.value.filter((pr: string) => pr !== 'no_preference');
        if (selectedAgePreferences.length === 0) {
            selectedAgePreferences = this.ageGroupOptions;
        }

        let selectedStudentExperienceLevel = this.teacherProfileForm.get('studentExperienceLevel')?.value.filter((pr: string) => pr !== 'no_preference');
        if (selectedStudentExperienceLevel.length === 0) {
            selectedStudentExperienceLevel = this.experienceLevel.map((exp) => exp.value);
        }
        this.apiService.editUser(userBody, this.data.id, !!this.data.hasEditProfile).pipe(
            mergeMap((userData) => {
                const socials: SocialsItem[] = this.socialMediaGroup.value.map((social: {
                    socialMedia: string;
                    link: string;
                }) => ({type: social?.socialMedia, link: social?.link }));
                const filteredSocials = socials.filter((item : {
                    type: string;
                    link: string;
                }) => {
                    return Object.values(item).every(value => value !== '');
                });

                const filteredEducationGroup = this.educationGroup.value.filter((item: { institution: string; degree: string; }) => {
                    return item.institution.trim() !== '';
                });

                // const studentGenderPreference = this.teacherProfileForm.get('studentGenderPreference')?.value.filter((gender: string) => gender !== 'no_preference');

                const teacherFormData: TeacherFormBody = {
                    userId: userData.id,
                    schoolId: this.userService.userInfo.manageSchoolId && this.userService.userInfo.role === UserRoles.Organisation ? this.userService.userInfo.manageSchoolId : schoolId,
                    instruments: this.teacherProfileForm.get('instrument')?.value.map((inst: MusicEntity) => inst.id),
                    genres: this.teacherProfileForm.get('genre')?.value.map((inst: MusicEntity) => inst.id),
                    studentsExperienceLevel: selectedStudentExperienceLevel,
                    teachingLocation: selectedStudentPreferenceLocation,
                    education: filteredEducationGroup.length > 0 ? filteredEducationGroup : null,
                    socials: filteredSocials.length > 0 ? filteredSocials : null,
                    agePreference: selectedAgePreferences,
                    // genderPreference: studentGenderPreference,
                    description: this.teacherProfileForm.get('goals')?.value,
                }
                return this.apiService.editTeacher(teacherFormData, this.data.teacher.id);
            }),
            mergeMap((teacher) => {
                return this.apiService.persistSchedule(schedulesBody, teacher.id)
            })
        ).subscribe(() => {
            this.loading = false;
            this.closeDialog(true);
        }, err => {
                this.loading = false;
            }
        );
    }
    addTeacher(userBody: UsersFormBody) {
        const getSchedulesBodyFun = getSchedulesBody;
        const schedulesBody = getSchedulesBodyFun(this.teacherProfileForm.get('availabilityTimeStamp')?.value);
        let selectedStudentPreferenceLocation = this.teacherProfileForm.get('studentPreferenceLocation')?.value.filter((pr: string) => pr !== 'no-preference');
        if (selectedStudentPreferenceLocation.length === 0) {
            selectedStudentPreferenceLocation = this.teachingLocation.map((exp) => exp.value).filter((pr: string) => pr !== 'no-reference');
        }
        let selectedAgePreferences = this.teacherProfileForm.get('studentPreferenceAgeGroup')?.value.filter((pr: string) => pr !== 'no_preference');
        if (selectedAgePreferences.length === 0) {
            selectedAgePreferences = this.ageGroupOptions;
        }
        let selectedStudentExperienceLevel = this.teacherProfileForm.get('studentExperienceLevel')?.value.filter((pr: string) => pr !== 'no_preference');
        if (selectedStudentExperienceLevel.length === 0) {
            selectedStudentExperienceLevel = this.experienceLevel.map((exp) => exp.value);
        }
        this.apiService.createUser(userBody).pipe(
            mergeMap((userData) => {
                const socials: SocialsItem[] = this.socialMediaGroup.value.map((social: {
                    socialMedia: string;
                    link: string;
                }) => ({type: social?.socialMedia, link: social?.link }));

                const filteredSocials = socials.filter((item : {
                    type: string;
                    link: string;
                }) => {
                    return Object.values(item).every(value => value !== '');
                });

                const filteredEducationGroup = this.educationGroup.value.filter((item: { institution: string; degree: string; }) => {
                    return item.institution.trim() !== '';
                });
               // const studentGenderPreference =  this.teacherProfileForm.get('studentGenderPreference')?.value.filter((gender: string) => gender !== 'no_preference');
                const teacherFormData: TeacherFormBody = {
                    userId: userData.id,
                    schoolId: this.userService.userInfo.manageSchoolId && this.userService.userInfo.role === UserRoles.Organisation ? this.userService.userInfo.manageSchoolId : schoolId,
                    instruments: this.teacherProfileForm.get('instrument')?.value.map((inst: MusicEntity) => inst.id),
                    genres: this.teacherProfileForm.get('genre')?.value.map((inst: MusicEntity) => inst.id),
                    studentsExperienceLevel: selectedStudentExperienceLevel,
                    teachingLocation: selectedStudentPreferenceLocation,
                    education: filteredEducationGroup.length > 0 ? filteredEducationGroup : null,
                    socials: filteredSocials.length > 0 ? filteredSocials : null,
                    agePreference: selectedAgePreferences,
                    // genderPreference: studentGenderPreference,
                    description: this.teacherProfileForm.get('goals')?.value,
                }
                return this.apiService.createTeacher(teacherFormData);
            }),
            mergeMap((teacher) => {
                return this.apiService.persistSchedule(schedulesBody, teacher.id)
            })
        ).subscribe(() => {
            this.loading = false;
            this.closeDialog(true);
        }, err => {
            this.loading = false;
        });
    }
    discard() {
        this.teacherProfileForm.reset(this.originalFormValues);
        if (this.data) {
            this.availabilityTimeStamp.clear();
            this.chips.forEach((chip) => this.addAvailabilityTimeStamp(chip));
            this.onSelectTime(null, NaN, this.selectedSchedules.list, true);
        }
    }

    closeDialog(update = false) {
        this.destroy$.next();
        this.destroy$.complete();
        this.dialogRef.close(update);
    }

    onSelectTime(event: MatChipSelectionChange | null, i: number, timeSlot: ScheduleItem[] | string, setDefaultValue = false) {
        if (setDefaultValue) {
            if (typeof timeSlot == 'object') {
                timeSlot.forEach(slot => {
                    let startTime = '';
                    let endTime = '';

                    if (this.convertTimeWithoutSeconds(slot.startTime)) {
                        startTime = this.convertTimeWithoutSeconds(slot.startTime)
                    }

                    if (this.convertTimeWithoutSeconds(slot.endTime)) {
                        endTime = this.convertTimeWithoutSeconds(slot.endTime);
                    }

                    const selectedTime = `${startTime} – ${endTime}`;
                    const dayNum = slot.day === 0 ? 6 : slot.day - 1;
                    this.availabilityTimeStamp.at(dayNum).value.selectedTime.push(selectedTime);
                });
            }
            return;
        }

        if (event?.selected && this.availabilityTimeStamp.at(i)) {
            this.availabilityTimeStamp.at(i).value.selectedTime.push(timeSlot);
        } else if (this.availabilityTimeStamp.at(i)) {
            this.availabilityTimeStamp.at(i).value.selectedTime = this.availabilityTimeStamp.at(i).value.selectedTime.filter((e: string) => e !== timeSlot)
        }
    }

    selectAllTimeSlots(index: number) {
           const allDaySelected = this.setSelectedAll(this.availabilityTimeStamp.at(index).value.selectedTime);
        if (!allDaySelected) {
            this.availabilityTimeStamp.at(index).value.selectedTime.splice(0);
            this.availabilityTimeStamp.at(index).value.selectedTime.push(...this.chips[index].time);
        } else {
            this.availabilityTimeStamp.at(index).value.selectedTime = this.availabilityTimeStamp.at(index).value.selectedTime.filter((e: string) => !this.chips[index].time.includes(e));
        }
    }

    compareFn<T>(c1: T, c2: T): boolean {
        return c1 && c2 ? (c1 as any).id === (c2 as any).id : c1 === c2;
    }

    onSelectSocialMedia(event: MatSelectChange, control: FormControl, index: number) {
        if (event.value === 'custom') {
            control.get('socialMedia')?.setValue('');
            control.get('custom')?.setValue(true);
            this.cd.detectChanges();
            const input = this.inputs.toArray().find(input => input.nativeElement.id === `custom-${index}`);
            input?.nativeElement.focus();
        }
    }

    clearInput(control: FormControl) {
        control.get('socialMedia')?.setValue('');
        control.get('custom')?.setValue(false);
    }

    convertTimeWithoutSeconds(time: string): string {
        if (!time) {
            return '';
        }
        const parts = time.split(":");
        const hours = parts[0];
        const minutes = parts[1];
        return `${hours}:${minutes}`;
    }

    generateWeekSchedule(): {
        day: string;
        date: string;
        selectedDate: Date;
    }[] {
        const today = new Date();
        const daysInWeek = 7;
        const millisecondsInDay = 24 * 60 * 60 * 1000;
        const weekSchedule = [];

        for (let i = 0; i < daysInWeek; i++) {
            const currentDate = new Date(today.getTime() + i * millisecondsInDay);

            const dayOfWeek = currentDate.toLocaleDateString('en-US', {weekday: 'long'});
            const formattedDate = currentDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            }).replaceAll('/', '.');

            weekSchedule.push({day: dayOfWeek, date: formattedDate, selectedDate: currentDate});
        }
        return weekSchedule;
    }

    deleteTeacher(key: string) {
        const title = this.translateService.instant(key);
        const type = this.translateService.instant('confirm_delete.teacher_type');
        this.openDeleteConfirmDialog(DeleteConfirmComponent, 'delete-confirm', { id: this.data.teacher.id, title, name: this.data.firstName, type });
    }

    openDeleteConfirmDialog<T>(component: ComponentType<T>, panelClass: string, dialogData?: {
        id: string;
        title: string;
        name: string;
        type: string;
    }): void {
        document.body.classList.add('modal-open');
        const dialogRef = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '480px',
            autoFocus: false,
            disableClose: true,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(teacherId => {
            document.body.classList.remove('modal-open');
            if (teacherId) {
                this.apiService.deleteTeacher(teacherId).subscribe(() => {
                    this.dialogRef.close({delete: true});
                });
            }
            console.log('Dialog closed result', teacherId);
        });
    }

    crossFieldValidator(control: AbstractControl): ValidationErrors | null {
        const institution = control?.parent?.get('institution');
        const degree = control?.parent?.get('degree');

        if (degree?.value && !institution?.value) {
            institution?.setErrors({ requiredInstitution: true });
            institution?.markAsTouched();
            return { requiredInstitution: true };
        }

        institution?.setErrors(null);
        return null;
    }

    crossFieldSocialMediaValidator(control: AbstractControl): ValidationErrors | null {
        const socialMediaValue = control?.parent?.get('socialMedia');
        const linkValue = control?.parent?.get('link');

        if (socialMediaValue?.value && !linkValue?.value) {
            linkValue?.setErrors({ requiredLink: true });
            linkValue?.markAsTouched();
            return { requiredLink: true };
        }

        if (linkValue?.value && !socialMediaValue?.value) {
            socialMediaValue?.setErrors({ requiredSocialMedia: true });
            socialMediaValue?.markAsTouched();
            return { requiredSocialMedia: true };
        }

        socialMediaValue?.setErrors(null);
        linkValue?.setErrors(null);
        return null;
    }

    onNoGenderPreference() {
        const genderControl = this.teacherProfileForm.get('studentGenderPreference');
        const currentOptions = genderControl?.value || [];
        if (currentOptions.includes('no_preference')) {
            const genderValues = this.selectGender.map((exp) => exp.value);
            genderControl?.setValue([...genderValues, 'no_preference']);
        } else {
            genderControl?.setValue([]);
        }
    }

    onNoTeachingLocation() {
        const teachingLocationControl = this.teacherProfileForm.get('studentPreferenceLocation');
        const currentOptions = teachingLocationControl?.value || [];
        if (currentOptions.includes('no-preference')) {
            const teachingLocationValues = this.teachingLocation.map((exp) => exp.value);
            teachingLocationControl?.setValue([...teachingLocationValues]);
        } else {
            teachingLocationControl?.setValue([]);
        }
    }

    // onNoStudentExperienceLevel() {
    //     const experienceLevelControl = this.teacherProfileForm.get('studentExperienceLevel');
    //     const currentOptions = experienceLevelControl?.value || [];
    //     if (currentOptions.includes('no_preference')) {
    //         const experienceLevelValues = this.experienceLevel.map((exp) => exp.value);
    //         experienceLevelControl?.setValue([...experienceLevelValues, 'no_preference']);
    //     } else {
    //         experienceLevelControl?.setValue([]);
    //     }
    // }

    updateFormControl(otherOptions: boolean = false, formControlName: string, noPreferenceValue = 'no_preference') {
        const experienceLevelControl = this.teacherProfileForm.get(formControlName);
        const currentOptions = experienceLevelControl?.value || [];
        if (currentOptions.includes(noPreferenceValue)) {
            experienceLevelControl?.setValue([noPreferenceValue]);
        }
        if (otherOptions) {
            const updatedOptions = currentOptions.filter((option: string) => option !== noPreferenceValue);
            experienceLevelControl?.setValue(updatedOptions);
        }
    }


    onNoPreference() {
        const studentPreferenceAgeGroupControl = this.teacherProfileForm.get('studentPreferenceAgeGroup');
        const currentOptions = studentPreferenceAgeGroupControl?.value || [];
        if (currentOptions.includes('no_preference')) {
            studentPreferenceAgeGroupControl?.setValue([...this.ageGroupOptions, 'no_preference']);
        } else {
            studentPreferenceAgeGroupControl?.setValue([]);
        }
    }

}
