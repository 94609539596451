import { Component, EventEmitter, Inject, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MATCH_CHIPS } from '../../../../shared/constants/form-option';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getSchedulesBody } from '../../../../shared/constants/schedules';
import { ApiService } from '../../../services/api/api.service';
import { MatChipOption, MatChipSelectionChange } from '@angular/material/chips';
import {
    LessonDuration,
    LessonPlace,
    LessonStatus,
    LessonType,
    Student,
    Teacher, TimeSlot, TimeSlotData
} from '../../../services/api/api.type';
import { PlatformService } from '../../../services/platform.service';

export interface TimeSelect {
    slots: string[];
    teacher?: Teacher;
    student: Student;
    modalDescription: {
        title: string;
        question: string;
    };
    modal?: unknown;
    multiple: boolean;
    hasTeacher?: boolean;
}

interface AvailabilityTimeStampControl {
    day: FormControl<string>;
    date: FormControl<string>;
    selectedDate: FormControl<Date | undefined>;
    selectedTime: FormControl<(string | TimeSlot)[]>;
}

@Component({
  selector: 'app-matching-time-select',
  templateUrl: './matching-time-select.component.html',
  styleUrls: ['./matching-time-select.component.scss']
})
export class MatchingTimeSelectComponent implements OnInit {
    protected readonly chips: TimeSlotData[] = MATCH_CHIPS;
    matchingTimeSelectForm: FormGroup;
    @ViewChildren('options') options!: QueryList<MatChipOption>;
    @Output() updateMatches: EventEmitter<void> = new EventEmitter<void>();

    get availabilityTimeStamp(): FormArray<FormGroup<AvailabilityTimeStampControl>> {
        return this.matchingTimeSelectForm.get('availabilityTimeStamp') as FormArray<FormGroup<AvailabilityTimeStampControl>>;
    }

    get isValidAvailabilityTimeStamp(): boolean {
        return (!!this.availabilityTimeStamp.value.find((timeStamp) => timeStamp?.selectedTime && timeStamp.selectedTime?.length > 0));
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<MatchingTimeSelectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TimeSelect,
        private apiService: ApiService,
        private platformService: PlatformService
    ) {
    }

    ngOnInit() {
        const weeklySchedule = this.generateWeekSchedule();
        this.chips.forEach((chip) => {
            const currentDate = weeklySchedule.find((d) => d.day === chip.day);
            if (currentDate) {
                chip.date = currentDate?.date;
                chip.selectedDate = currentDate.selectedDate;
            }
            chip.time.forEach((time) => time = ({name: time as string, selected: false}));
        });
        this.chips.forEach((chip) => {
            chip.time.forEach((time) => (time = {name: time as string, selected: false}));
        });

        this.matchingTimeSelectForm = this.fb.group({
            availabilityTimeStamp: this.fb.array([]),
        });
        this.chips.forEach((chip) => this.addAvailabilityTimeStamp(chip));
    }
    generateWeekSchedule(): {
        day: string;
        date: string;
        selectedDate: Date;
    }[] {
        const today = new Date();
        const daysInWeek = 7;
        const millisecondsInDay = 24 * 60 * 60 * 1000;
        const weekSchedule = [];

        for (let i = 0; i < daysInWeek; i++) {
            const currentDate = new Date(today.getTime() + i * millisecondsInDay);

            const dayOfWeek = currentDate.toLocaleDateString('en-US', {weekday: 'long'});
            const formattedDate = currentDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            }).replaceAll('/', '.');

            weekSchedule.push({day: dayOfWeek, date: formattedDate, selectedDate: currentDate});
        }
        return weekSchedule;
    }

    setSelectedSlot(slots: string[],timeSlot: string | TimeSlot, day: number) {
        let formatTimeSlots = slots.filter((slot: string) => this.transformDateTime(slot).day === day);
        formatTimeSlots = formatTimeSlots.map((slot: string) => this.transformDateTime(slot).fullTime);
        return !!formatTimeSlots?.includes(timeSlot as string);
    }
    onSelectTime(event: MatChipSelectionChange, i: number, timeSlot: (string | TimeSlot)) {
        if (this.data.multiple) {
            if (event.selected && this.availabilityTimeStamp.at(i)) {
                this.availabilityTimeStamp.at(i).value.selectedTime?.push(timeSlot);
            } else if (this.availabilityTimeStamp.at(i)) {
                this.availabilityTimeStamp.at(i).value.selectedTime = this.availabilityTimeStamp.at(i).value.selectedTime?.filter((e) => e !== timeSlot);
            }
        } else {
            const chipControls = this.availabilityTimeStamp.controls;

            if (event.selected && this.availabilityTimeStamp.at(i)) {
                chipControls.forEach((control, index: number) => {
                    if (index === i && event.selected) {
                        this.availabilityTimeStamp.at(i).value.selectedTime?.push(timeSlot);
                        const selectedOption = event.source;
                        const otherOptions = this.options.toArray().filter(option => option !== selectedOption);
                        this.deselectOtherOptions(selectedOption, otherOptions);
                    } else {
                        this.availabilityTimeStamp.at(index).value.selectedTime = this.availabilityTimeStamp.at(index).value.selectedTime?.filter((e) => e !== timeSlot);
                    }
                });
            } else {
                this.availabilityTimeStamp.at(i).value.selectedTime = this.availabilityTimeStamp.at(i).value.selectedTime?.filter((e) => e !== timeSlot);
            }
        }
    }

    deselectOtherOptions(selectedOption: MatChipOption, options: MatChipOption[]) {
        options.forEach(option => {
            if (option !== selectedOption) {
                option.deselect();
            }
        });
    }

    addAvailabilityTimeStamp(chip: TimeSlotData) {
        const control = this.fb.group({
            day: [chip.day],
            date: [chip.date],
            selectedDate: [chip.selectedDate],
            selectedTime: [[]],
        }) as unknown as FormGroup<AvailabilityTimeStampControl>;
        this.availabilityTimeStamp.push(control);
    }

    cancel() {
        this.dialogRef.close();
    }

    onSubmit() {
        const getSchedulesBodyFun = getSchedulesBody;
        const schedules = getSchedulesBodyFun(this.matchingTimeSelectForm.get('availabilityTimeStamp')?.value);
        const timeSlots = schedules.map(schedule => schedule.startTime);
        if (this.data.hasTeacher) {
            const startTime = timeSlots[0];
            const endTime = new Date(new Date(startTime).setUTCHours(new Date(startTime).getUTCHours() + 1)).toISOString();
            this.apiService.updateMatching({
                teacherId: this.data.teacher?.teacher.id || '',
                studentId: this.data.student.student.id,
                acceptedTeacher: true,
                lesson: {
                place: LessonPlace.ONLINE,
                    teacherId: this.data.teacher?.teacher.id || '',
                    studentId: this.data.student.student.id,
                    title: 'Musiq lesson',
                    startTime: startTime,
                    endTime: endTime,
                    type: LessonType.TRIAL,
                    duration: LessonDuration['60_M'],
                    status: LessonStatus.ACCEPTED,
            }
            }).subscribe(() => {
                this.dialogRef.close(true);
                this.updateMatches.emit();
            });
        } else {
            const safeMap = (array: any[]) => array?.map(v => (typeof v === 'object' && v !== null) ? v.id : v) || [];
            const instruments = safeMap(this.data?.student?.student.instruments || []);
            const genres = safeMap(this.data?.student?.student.genres || []);
            this.apiService.updateMatching({
                teacherId: this.data.teacher?.teacher.id || '',
                studentId: this.data.student.student.id,
                acceptedStudent: true,
                timeSlots,
                genres,
                instruments
            }).subscribe(() => this.dialogRef.close(true));
        }
    }

    transformDateTime(dateTimeString: string): {
        day: number;
        dayName: string;
        startTime: string;
        endTime: string;
        fullTime: string;
    } {
        const date = new Date(dateTimeString);

        const day = date.getUTCDay();
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        const minutes = date.getMinutes();
        const roundedMinutes = Math.floor(minutes / 15) * 15;
        date.setMinutes(roundedMinutes);

        let startTime = date.toISOString().slice(11, 16) + ':00';
        let endTime = new Date(date.getTime() + 3600000).toISOString().slice(11, 16) + ':00';

        const fullTime = `${startTime.substring(0, 5)} – ${endTime.substring(0, 5)}`;
        const currentDay = day === 0 ? 6 : day - 1;
        return {
            day: currentDay,
            dayName: daysOfWeek[currentDay],
            startTime: startTime,
            endTime: endTime,
            fullTime
        };
    }

}
