import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { UserRoles } from '../../../../core/models/user-info.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StudentProfileInfoComponent } from '../student-dashboard/student-profile-info/student-profile-info.component';
import { TeacherProfileInfoComponent } from '../teacher-dashboard/teacher-profile-info/teacher-profile-info.component';
import { Instrument, Student, Teacher } from '../../../services/api/api.type';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MatchingTimeSelectComponent } from '../../match-teachers/matching-time-select/matching-time-select.component';
import { ApiService } from '../../../services/api/api.service';
import { ComponentType } from '@angular/cdk/overlay';
import { MatchData } from '../organisation-dashboard/matches-list/matches-list.component';
import { MusicEntity } from '../../../../shared/services/music.service';

interface MatchingTimeSelectData {
    slots: string[];
    teacher: Teacher;
    student: Student;
    modalDescription: { title: string; question: string };
    multiple: boolean;
    hasTeacher: boolean;
}

@Component({
  selector: 'app-match-details',
  templateUrl: './match-details.component.html',
  styleUrls: ['./match-details.component.scss']
})
export class MatchDetailsComponent implements OnDestroy {
    protected readonly UserRoles = UserRoles;
    @Output() closeModal: EventEmitter<{ delete?: boolean }> = new EventEmitter<{ delete?: boolean }>();
    @Output() teacherUpdated: EventEmitter<string> = new EventEmitter<string>();
    @Output() approveMatch: EventEmitter<void> = new EventEmitter<void>();
    selectedTeacherId: string;
    destroy$ = new Subject();
    dialogRef: MatDialogRef<TeacherProfileInfoComponent>;
    @Output() profileComponentInstance: EventEmitter<MatDialogRef<TeacherProfileInfoComponent>> = new EventEmitter();
    currentLanguage: string = this.translateService.currentLang;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MatchData,
        private dialog: MatDialog,
        private apiService: ApiService,
        private translateService: TranslateService) {
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    setTeacher(teacher: Teacher) {
        this.data.teacher = teacher;
        this.data.teacher.fromMatch = true;
    }

    closeDialog() {
        this.closeModal.emit();
    }

    showStudentProfile() {
        this.data.student.fromMatch = true;
        this.openDialog(StudentProfileInfoComponent, 'student-profile-info', this.data.student);
    }

    showTeacherProfile() {
        this.data.teacher.fromMatch = true;
        this.openDialog(TeacherProfileInfoComponent, 'teacher-profile-info', this.data.teacher);
    }


    openDialog(component: ComponentType<(StudentProfileInfoComponent | TeacherProfileInfoComponent)>, panelClass: string, dialogData?: (Student | Teacher), width = '800px'): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<(TeacherProfileInfoComponent | StudentProfileInfoComponent)> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            height: '95vh',
            maxWidth: '100vw',
            autoFocus: false,
            disableClose: true,
            position: { top: '48px' },
            data: dialogData
        });
        dialogRef.afterClosed().subscribe((result: {delete?: boolean }) => {
            document.body.classList.remove('modal-open');
            if (result && result?.delete) {
                this.closeModal.emit({ delete: true });
            }
            console.log('Dialog closed result', result);
        });
        const { componentInstance } = dialogRef as { componentInstance: TeacherProfileInfoComponent };
            componentInstance?.teacherUpdated?.subscribe((id: string) => {
                this.selectedTeacherId = id;
                this.teacherUpdated.emit(this.selectedTeacherId);
            });

        if (panelClass === 'teacher-profile-info') {
            this.dialogRef = dialogRef as MatDialogRef<TeacherProfileInfoComponent>;
            this.profileComponentInstance.emit(dialogRef as MatDialogRef<TeacherProfileInfoComponent>);
        }

    }

    translateInstruments(instruments: (string | MusicEntity | null)[] | undefined): string {
        if (!instruments || instruments.length === 0) return '—';

        return instruments
            .map(instrument => {
                if (typeof instrument === 'string') {
                    return instrument;
                }
                return this.currentLanguage === 'eng' ? instrument?.nameEn : instrument?.nameDe;
            })
            .join(' | ');
    }


    approve(student: Student, teacher: Teacher) {
            const teacherId = teacher?.teacher?.id || '';
            this.apiService.getMatching(teacherId, student.student.id).subscribe(
                (data) => {
                    const modalDescription: { title: string; question: string } = {
                        title: this.translateService.instant('matching.time_select.titleCard'),
                        question: this.translateService.instant('matching.time_select.description')
                    }
                    this.openTimeSelectDialog(MatchingTimeSelectComponent, 'time-select', { slots: data.timeSlots, teacher: teacher, student, modalDescription, multiple: false, hasTeacher: true }, '864px', '');
                }
            )
    }


    openTimeSelectDialog<T>(component: ComponentType<T>, panelClass: string, dialogData?: MatchingTimeSelectData, width = '864px', position = '32px'): void {
        document.body.classList.add('modal-open');
        const  config = {
                panelClass: panelClass,
                hasBackdrop: true,
                width: '100vw',
                height: '95vh',
                maxWidth: '100vw',
                autoFocus: false,
                disableClose: true,
                position: {top: '48px'},
                data: dialogData
            }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.approveMatch.emit();
            }
        });
    }

}
