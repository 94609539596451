import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MusicEntity } from '../../../shared/services/music.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-genres',
  templateUrl: './genres.component.html',
  styleUrls: ['./genres.component.scss']
})
export class GenresComponent implements OnInit {
    @ViewChild('chipList') chipList: MatChipListbox;

    displayedChips: { chip: MusicEntity; selected: boolean }[] = [];
    formGroup: FormGroup;

    get signUpRole() {
        return this.userService.signUpRole;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: { chipList: MusicEntity[], lang: string, selectedChips: MusicEntity[] },
                public dialogRef: MatDialogRef<GenresComponent>,
                private fb: FormBuilder,
                private userService: UserService) {
    }

    ngOnInit() {
        const stepChips = this.data.chipList;
        this.addControls();
        this.displayedChips = stepChips.map((chip) => ({ chip, selected: false }));

        this.displayedChips = stepChips.map((chip) => ({
            chip,
            selected: this.isChipSelected(chip)
        }));
        this.updateSelectedGenres();
    }

    updateSelectedGenres(): void {
        const selectedIds = this.displayedChips
            .filter(chip => chip.selected)
            .map(chip => chip.chip.id);

        this.formGroup.get('selectedGenres')?.setValue(selectedIds);
    }

    isChipSelected(chip: MusicEntity): boolean {
        return this.data.selectedChips.some(selectedChip => selectedChip.id === chip.id);
    }

    addControls(): void {
        this.formGroup = this.fb.group({
            selectedGenres: [[], Validators.required]
        });
    }
    closeDialog() {
        this.dialogRef.close(null);
    }

    onSubmit() {
        this.dialogRef.close(this.formGroup.get('selectedGenres')?.value);
    }

    handleSelectedGenresChange() {
        this.formGroup.get('selectedGenres')?.setValue(this.getSelectedChips());
    }

    getSelectedChips() {
        if (this.chipList.selected) {
            const selectedValues: MatChipOption[] = Array.isArray(this.chipList.selected)
                ? this.chipList.selected
                : [this.chipList.selected];
            return selectedValues.map((chips: MatChipOption) => chips['_value']);
        }
        return [];
    }
}
