<div class="tab-container student-form-wrapper" appTabNavigation [containerSelector]="'.tab-container'">
    <div class="header-wrapper-mobile">
        <mat-toolbar class="toolbar-form">
            <span>{{ 'student-profile.toolbar_title' | translate }}</span>
            <span class="example-spacer"></span>
            <button mat-icon-button tabindex="1" class="example-icon" (click)="closeDialog()">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </mat-toolbar>
        <mat-divider></mat-divider>
        <div class="footer mobile">
            <div class="btn-container custom-theme">
                <button mat-stroked-button color="primary"
                        (click)="discard()">{{ 'student-profile.buttons.discard' | translate }}
                </button>
                <button mat-raised-button color="primary" [disabled]="loading" type="submit"
                        (click)="onSubmit()">{{ 'student-profile.buttons.save_changes' | translate }}
                </button>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
    <div mat-dialog-content class="form-content">
        <form [formGroup]="studentProfileForm">
            <!-- DESKTOP-->
            <div *ngIf="!isMobilePlatform">
                <div
                    class="form-title personal-data">{{ 'student-profile.sections.personal_data.form_title' | translate }}
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.first_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="2" matInput formControlName="firstName"
                                       placeholder="{{ 'student-profile.sections.personal_data.labels.first_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('firstName').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.first_name.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('firstName').hasError">
                                {{ 'student-profile.sections.personal_data.labels.first_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('firstName').hasAllErrors && getFieldProperties('lastName').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.zip_code.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.personal_data.labels.zip_code.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="4" appNumericInput matInput formControlName="zipCode"
                                       placeholder="000000"
                                       maxlength="10">
                            </mat-form-field>
                            <mat-error
                                *ngIf="!getFieldProperties('zipCode').hasAllErrors && getFieldProperties('gender').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.ui_language.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="6"
                                            placeholder="{{ 'student-profile.sections.personal_data.labels.ui_language.placeholder' | translate }}"
                                            formControlName="language" [compareWith]="compareFn">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('language').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.ui_language.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>

                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.last_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="3" matInput formControlName="lastName"
                                       placeholder="{{ 'student-profile.sections.personal_data.labels.last_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('lastName').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.last_name.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('lastName').hasError">
                                {{ 'student-profile.sections.personal_data.labels.last_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('lastName').hasAllErrors && getFieldProperties('firstName').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.gender.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.personal_data.labels.zip_code.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="5"
                                            placeholder="{{ 'student-profile.sections.personal_data.labels.gender.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('gender').hasAllErrors}"
                                            formControlName="gender">
                                    <mat-option *ngFor="let option of singlegender" [value]="option.value">
                                        {{ 'genders.' + option.value | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <mat-error *ngIf="getFieldProperties('gender').isRequired">
                                 {{ 'student-profile.sections.personal_data.labels.gender.required_error' | translate }}
                             </mat-error> -->
                            <mat-error
                                *ngIf="!getFieldProperties('gender').hasAllErrors && getFieldProperties('zipCode').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.birthday.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="7" matInput readonly formControlName="birthday"
                                       placeholder="{{ 'student-profile.sections.personal_data.labels.birthday.placeholder' | translate }}"
                                       [matDatepicker]="picker" [max]="currentDate">
                                <mat-datepicker-toggle matIconSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('birthday').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.birthday.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-title">{{ 'student-profile.sections.what_im_looking_for.form_title' | translate }}
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.instrument.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.instrument.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="8" formControlName="instrument" [multiple]="true"
                                            placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.instrument.placeholder' | translate }}">
                                    <mat-option *ngFor="let option of instrument; trackBy: trackById"
                                                [value]="option">
                                    <mat-icon *ngIf="option?.gnd || option?.wikidata || option?.mimo"  class="link-url-icon" [tooltip]="[{ name: 'GND', url: option?.gnd}, { name: 'Wikidata', url: option?.wikidata}, {name: 'MIMO',url: option?.mimo}]">open_in_new</mat-icon>
                                    {{ this.currentLanguage === 'eng' ? option.nameEn : option.nameDe }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('instrument').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.instrument.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('genre').isRequired && !getFieldProperties('instrument').isRequired">
                            </mat-error>
                        </div>
                        <div class="form-field">
                            <!--   <p>{{ 'student-profile.sections.what_im_looking_for.labels.gender_preference.label' | translate }}</p>
                                    <mat-form-field subscriptSizing="dynamic">
                                        <mat-select placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.gender_preference.placeholder' | translate }}" formControlName="genderPreference" multiple>
                                                <mat-option [value]="'no_preference'" (click)="onNoGenderPreference()">{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}</mat-option>
                                                <mat-option *ngFor="let option of selectGender"
                                                            [disabled]="option.value !== 'no_preference' && getFieldProperties('genderPreference', studentProfileForm).control?.value?.includes('no_preference')"
                                                            [value]="option.value">
                                                    {{ 'genders.' + option.value | translate }}
                                                </mat-option>
                                            <mat-select-trigger>
                                    <span class="example-additional-selection">
                                     {{getSelectedGenderOptions}}
                                   </span>
                                            </mat-select-trigger>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="getFieldProperties('genderPreference').isRequired">
                                        {{ 'student-profile.sections.what_im_looking_for.labels.gender_preference.required_error' | translate }}
                                    </mat-error> -->
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="10"
                                            placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.placeholder' | translate }}"
                                            formControlName="spokenLanguages" [multiple]="true"
                                            [compareWith]="compareFn">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('spokenLanguages').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.genre.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.genre.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="9" formControlName="genre" [multiple]="true"
                                            placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.genre.placeholder' | translate }}">
                                    <mat-option *ngFor="let option of genres; trackBy: trackById" [value]="option">
                                        {{ this.currentLanguage === 'eng' ? option.nameEn : option.nameDe }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('genre').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.genre.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('instrument').isRequired && !getFieldProperties('genre').isRequired">
                            </mat-error>
                        </div>
                        <!--  <div class="form-field">
                              <p>{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.label' | translate }}</p>
                              <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.multiple_possible' | translate }}</p>
                              <mat-form-field subscriptSizing="dynamic">
                                  <mat-select placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.placeholder' | translate }}" formControlName="spokenLanguages" [multiple]="true" [compareWith]="compareFn">
                                      <mat-option *ngFor="let option of languageOptions" [value]="option.value">{{ option.label }}</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-error *ngIf="getFieldProperties('spokenLanguages').isRequired">
                                  {{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.required_error' | translate }}
                              </mat-error>
                          </div> -->
                    </div>
                </div>
                <div class="form-field">
                    <p>{{ 'student-profile.sections.what_im_looking_for.labels.goals.label' | translate }}</p>
                    <p class="small-label"></p>
                    <mat-form-field>
                    <textarea tabindex="11"
                              placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.goals.placeholder' | translate }}"
                              maxlength="300" matInput formControlName="goals"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.prior_knowledge.label' | translate }}</p>
                            <mat-radio-group class="mat-radio-container" formControlName="knowledge">
                                <mat-radio-button tabIndex="{{12 + i}}"
                                                  *ngFor="let experience of experienceLevel; let i = index"
                                                  [value]="experience.value"><span>
                            {{ 'experience_levels.' + experience.value | translate }}
                        </span></mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="getFieldProperties('knowledge').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.prior_knowledge.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.teaching_location.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.teaching_location.multiple_possible' | translate }}</p>
                            <mat-selection-list formControlName="location">
                                <ng-container *ngFor="let location of teacherLocations; let i = index">
                                    <mat-list-option *ngIf="location.value === 'no-preference'; else others"
                                                     (click)="onNoTeachingLocation()"
                                                     [value]="location.value">{{ 'teaching_locations.' + location.value | translate }}
                                    </mat-list-option>
                                    <ng-template #others>
                                        <mat-list-option
                                            [selected]="location.value !== 'no-preference' && getFieldProperties('location', studentProfileForm).control?.value?.includes('no-preference')"
                                            [class.disable-focus]="getFieldProperties('location', studentProfileForm).control?.value?.includes('no-preference')"
                                            [disabled]="location.value !== 'no-preference' && getFieldProperties('location', studentProfileForm).control?.value?.includes('no-preference')"
                                            [value]="location.value">{{ 'teaching_locations.' + location.value | translate }}
                                        </mat-list-option>
                                    </ng-template>
                                </ng-container>
                            </mat-selection-list>
                            <mat-error *ngIf="getFieldProperties('location').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.teaching_location.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="form-title">{{ 'student-profile.sections.contact.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.contact.labels.email.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="20" matInput formControlName="email" required
                                       placeholder="{{ 'student-profile.sections.contact.labels.email.placeholder' | translate }}">
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('email').isRequired">
                                {{ 'student-profile.sections.contact.labels.email.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                                {{ 'student-profile.sections.contact.labels.email.invalid_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="email?.hasError('alreadyExist')">
                                <!-- Replace key after json update   -->
                                {{ 'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.contact.labels.phone.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.contact.labels.phone.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="22" matInput
                                       placeholder="{{ 'student-profile.sections.contact.labels.phone.placeholder' | translate }}"
                                       formControlName="phone" maxlength="15" minlength="5" appNumericInput>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('phone').hasError">
                                {{ 'student-profile.sections.contact.labels.phone.minlength_error' | translate }}
                            </mat-error>
                        </div>
                    </div>

                    <div class="form-column">
                        <div class="form-field checkbox">
                            <mat-checkbox tabindex="21"
                                          formControlName="allowContactViaEmail">{{ 'student-profile.sections.contact.labels.allow_contact_via_email.label' | translate }}
                            </mat-checkbox>
                        </div>

                        <div class="form-field checkbox"
                             [ngStyle]="{'padding-top': getFieldProperties('email').hasAllErrors ? '24px': '0'}">
                            <mat-checkbox tabindex="23"
                                          formControlName="allowContactViaPhone">{{ 'student-profile.sections.contact.labels.allow_contact_via_phone.label' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Mobile-->
            <div *ngIf="isMobilePlatform">
                <div
                    class="form-title personal-data">{{ 'student-profile.sections.personal_data.form_title' | translate }}
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.first_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="2" matInput formControlName="firstName"
                                       placeholder="{{ 'student-profile.sections.personal_data.labels.first_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('firstName').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.first_name.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('firstName').hasError">
                                {{ 'student-profile.sections.personal_data.labels.first_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('firstName').hasAllErrors && getFieldProperties('lastName').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.last_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="3" matInput formControlName="lastName"
                                       placeholder="{{ 'student-profile.sections.personal_data.labels.last_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('lastName').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.last_name.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('lastName').hasError">
                                {{ 'student-profile.sections.personal_data.labels.last_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('lastName').hasAllErrors && getFieldProperties('firstName').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.zip_code.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.personal_data.labels.zip_code.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="4" appNumericInput matInput formControlName="zipCode"
                                       placeholder="000000"
                                       maxlength="10">
                            </mat-form-field>
                            <mat-error
                                *ngIf="!getFieldProperties('zipCode').hasAllErrors && getFieldProperties('gender').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.birthday.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="7" matInput readonly formControlName="birthday"
                                       placeholder="{{ 'student-profile.sections.personal_data.labels.birthday.placeholder' | translate }}"
                                       [matDatepicker]="picker" [max]="currentDate">
                                <mat-datepicker-toggle matIconSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('birthday').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.birthday.required_error' | translate }}
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.gender.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.personal_data.labels.zip_code.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="5"
                                            placeholder="{{ 'student-profile.sections.personal_data.labels.gender.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('gender').hasAllErrors}"
                                            formControlName="gender">
                                    <mat-option *ngFor="let option of singlegender" [value]="option.value">
                                        {{ 'genders.' + option.value | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <mat-error *ngIf="getFieldProperties('gender').isRequired">
                                 {{ 'student-profile.sections.personal_data.labels.gender.required_error' | translate }}
                             </mat-error> -->
                            <mat-error
                                *ngIf="!getFieldProperties('gender').hasAllErrors && getFieldProperties('zipCode').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.personal_data.labels.ui_language.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="6"
                                            placeholder="{{ 'student-profile.sections.personal_data.labels.ui_language.placeholder' | translate }}"
                                            formControlName="language" [compareWith]="compareFn">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('language').isRequired">
                                {{ 'student-profile.sections.personal_data.labels.ui_language.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-title">{{ 'student-profile.sections.what_im_looking_for.form_title' | translate }}
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.instrument.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.instrument.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="8" formControlName="instrument" [multiple]="true"
                                            placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.instrument.placeholder' | translate }}">
                                    <mat-option *ngFor="let option of instrument; trackBy: trackById" [value]="option">
                                        <mat-icon *ngIf="option?.gnd || option?.wikidata || option?.mimo" class="link-url-icon" [tooltip]="[{ name: 'GND', url: option?.gnd}, { name: 'Wikidata', url: option?.wikidata}, {name: 'MIMO',url: option?.mimo}]">open_in_new</mat-icon>
                                        {{ this.currentLanguage === 'eng' ? option.nameEn : option.nameDe }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('instrument').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.instrument.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('genre').isRequired && !getFieldProperties('instrument').isRequired">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.genre.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.genre.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="9" formControlName="genre" [multiple]="true"
                                            placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.genre.placeholder' | translate }}">
                                    <mat-option *ngFor="let option of genres; trackBy: trackById" [value]="option">
                                        {{ this.currentLanguage === 'eng' ? option.nameEn : option.nameDe }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('genre').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.genre.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('instrument').isRequired && !getFieldProperties('genre').isRequired">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <!--   <p>{{ 'student-profile.sections.what_im_looking_for.labels.gender_preference.label' | translate }}</p>
                                    <mat-form-field subscriptSizing="dynamic">
                                        <mat-select placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.gender_preference.placeholder' | translate }}" formControlName="genderPreference" multiple>
                                                <mat-option [value]="'no_preference'" (click)="onNoGenderPreference()">{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}</mat-option>
                                                <mat-option *ngFor="let option of selectGender"
                                                            [disabled]="option.value !== 'no_preference' && getFieldProperties('genderPreference', studentProfileForm).control?.value?.includes('no_preference')"
                                                            [value]="option.value">
                                                    {{ 'genders.' + option.value | translate }}
                                                </mat-option>
                                            <mat-select-trigger>
                                    <span class="example-additional-selection">
                                     {{getSelectedGenderOptions}}
                                   </span>
                                            </mat-select-trigger>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="getFieldProperties('genderPreference').isRequired">
                                        {{ 'student-profile.sections.what_im_looking_for.labels.gender_preference.required_error' | translate }}
                                    </mat-error> -->
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="10"
                                            placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.placeholder' | translate }}"
                                            formControlName="spokenLanguages" [multiple]="true"
                                            [compareWith]="compareFn">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('spokenLanguages').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">
                        <!--  <div class="form-field">
                              <p>{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.label' | translate }}</p>
                              <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.multiple_possible' | translate }}</p>
                              <mat-form-field subscriptSizing="dynamic">
                                  <mat-select placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.placeholder' | translate }}" formControlName="spokenLanguages" [multiple]="true" [compareWith]="compareFn">
                                      <mat-option *ngFor="let option of languageOptions" [value]="option.value">{{ option.label }}</mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-error *ngIf="getFieldProperties('spokenLanguages').isRequired">
                                  {{ 'student-profile.sections.what_im_looking_for.labels.spoken_language.required_error' | translate }}
                              </mat-error>
                          </div> -->
                    </div>
                </div>
                <div class="form-field">
                    <p>{{ 'student-profile.sections.what_im_looking_for.labels.goals.label' | translate }}</p>
                    <p class="small-label"></p>
                    <mat-form-field>
                    <textarea tabindex="11"
                              placeholder="{{ 'student-profile.sections.what_im_looking_for.labels.goals.placeholder' | translate }}"
                              maxlength="300" matInput formControlName="goals"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.prior_knowledge.label' | translate }}</p>
                            <mat-radio-group class="mat-radio-container" formControlName="knowledge">
                                <mat-radio-button tabIndex="{{12 + i}}"
                                                  *ngFor="let experience of experienceLevel; let i = index"
                                                  [value]="experience.value"><span>
                            {{ 'experience_levels.' + experience.value | translate }}
                        </span></mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="getFieldProperties('knowledge').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.prior_knowledge.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.what_im_looking_for.labels.teaching_location.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.what_im_looking_for.labels.teaching_location.multiple_possible' | translate }}</p>
                            <mat-selection-list formControlName="location">
                                <ng-container *ngFor="let location of teacherLocations; let i = index">
                                    <mat-list-option *ngIf="location.value === 'no-preference'; else others"
                                                     (click)="onNoTeachingLocation()"
                                                     [value]="location.value">{{ 'teaching_locations.' + location.value | translate }}
                                    </mat-list-option>
                                    <ng-template #others>
                                        <mat-list-option
                                            [selected]="location.value !== 'no-preference' && getFieldProperties('location', studentProfileForm).control?.value?.includes('no-preference')"
                                            [class.disable-focus]="getFieldProperties('location', studentProfileForm).control?.value?.includes('no-preference')"
                                            [disabled]="location.value !== 'no-preference' && getFieldProperties('location', studentProfileForm).control?.value?.includes('no-preference')"
                                            [value]="location.value">{{ 'teaching_locations.' + location.value | translate }}
                                        </mat-list-option>
                                    </ng-template>
                                </ng-container>
                            </mat-selection-list>
                            <mat-error *ngIf="getFieldProperties('location').isRequired">
                                {{ 'student-profile.sections.what_im_looking_for.labels.teaching_location.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="form-title">{{ 'student-profile.sections.contact.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'student-profile.sections.contact.labels.email.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="20" matInput formControlName="email" required
                                       placeholder="{{ 'student-profile.sections.contact.labels.email.placeholder' | translate }}">
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('email').isRequired">
                                {{ 'student-profile.sections.contact.labels.email.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                                {{ 'student-profile.sections.contact.labels.email.invalid_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="email?.hasError('alreadyExist')">
                                <!-- Replace key after json update   -->
                                {{ 'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                            </mat-error>
                        </div>

                        <div class="form-field checkbox">
                            <mat-checkbox tabindex="21"
                                          formControlName="allowContactViaEmail">{{ 'student-profile.sections.contact.labels.allow_contact_via_email.label' | translate }}
                            </mat-checkbox>
                        </div>

                        <div class="form-field">
                            <p>{{ 'student-profile.sections.contact.labels.phone.label' | translate }}</p>
                            <p class="small-label">{{ 'student-profile.sections.contact.labels.phone.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="22" matInput
                                       placeholder="{{ 'student-profile.sections.contact.labels.phone.placeholder' | translate }}"
                                       formControlName="phone" maxlength="15" minlength="5" appNumericInput>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('phone').hasError">
                                {{ 'student-profile.sections.contact.labels.phone.minlength_error' | translate }}
                            </mat-error>
                        </div>

                        <div class="form-field checkbox"
                             [ngStyle]="{'padding-top': getFieldProperties('email').hasAllErrors ? '24px': '0'}">
                            <mat-checkbox tabindex="23"
                                          formControlName="allowContactViaPhone">{{ 'student-profile.sections.contact.labels.allow_contact_via_phone.label' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-title">{{ 'student-profile.sections.availability.form_title' | translate }}</div>
            <div class="form-field">
                <mat-grid-list [cols]="isMobilePlatform ? 1 : 7" [rowHeight]="isMobilePlatform ? '370px' : '285px'">
                    <mat-grid-tile *ngFor="let chip of chips; let i = index">
                        <div class="chips-container">
                            <div class="day-container">
                                <div class="weekday">{{ 'days.' + chip.day | translate }}</div>
                                <div class="date">{{ chip.date }}</div>
                            </div>
                            <mat-chip-listbox formArrayName="availabilityTimeStamp" class="mat-mdc-chip-set-stacked"
                                              multiple>
                                <mat-chip-option tabIndex="{{i + 24}}" class="timeStamp select-all"
                                                 [selected]="setSelectedAll(availabilityTimeStamp.value[i]?.selectedTime)"
                                                 (keydown.enter)="selectAllTimeSlots(i)"
                                                 (click)="selectAllTimeSlots(i)">{{ 'days.all-day' | translate }}
                                </mat-chip-option>
                                <mat-chip-option tabIndex="{{i + 31}}"
                                                 [selected]="setSelectedSlot(availabilityTimeStamp.value[i]?.selectedTime, timeSlot)"
                                                 class="timeStamp" (selectionChange)="onSelectTime($event, i, timeSlot)"
                                                 *ngFor="let timeSlot of chip.time"> {{ timeSlot }}
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                    </mat-grid-tile>
                    <mat-error class="availability-error"
                               *ngIf="!isValidAvailabilityTimeStamp && availabilityTimeStamp?.touched">
                        {{ 'student-profile.sections.availability.labels.availability_error' | translate }}
                    </mat-error>
                </mat-grid-list>
            </div>
        </form>


    </div>

    <div class="footer desktop">
        <mat-divider></mat-divider>
        <div class="btn-container custom-theme">
            <button mat-stroked-button color="primary"
                    (click)="discard()">{{ 'student-profile.buttons.discard' | translate }}
            </button>
            <button mat-raised-button color="primary" [disabled]="loading" type="submit"
                    (click)="onSubmit()">{{ 'student-profile.buttons.save_changes' | translate }}
            </button>
        </div>
    </div>
</div>
