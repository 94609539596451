<div class="tab-container teacher-form-wrapper" appTabNavigation [containerSelector]="'.tab-container'">
    <div class="header-wrapper-mobile">
        <mat-toolbar class="toolbar-form">
            <span>{{ 'teacher-profile.toolbar_title' | translate }}</span>
            <span class="example-spacer"></span>
            <button tabindex="1" mat-icon-button class="example-icon" (click)="closeDialog()">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </mat-toolbar>
        <mat-divider></mat-divider>
        <div class="footer mobile">
            <div class="btn-container custom-theme">
                <div class="right-side">
                    <button mat-stroked-button color="primary"
                            (click)="discard()">{{ 'teacher-profile.buttons.discard' | translate }}
                    </button>
                    <button mat-raised-button color="primary" [disabled]="loading" type="submit"
                            (click)="onSubmit()">{{ 'teacher-profile.buttons.save_changes' | translate }}
                    </button>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>

    <div mat-dialog-content class="form-content">
        <form [formGroup]="teacherProfileForm">
            <!-- DESKTOP-->
            <div *ngIf="!isMobilePlatform">
                <div
                    class="form-title personal-data">{{ 'teacher-profile.sections.personal_data.form_title' | translate }}
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.first_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="2" matInput formControlName="firstName"
                                       placeholder="{{ 'teacher-profile.sections.personal_data.labels.first_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('firstName').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.first_name.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('firstName').hasError">
                                {{ 'teacher-profile.sections.personal_data.labels.first_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('firstName').hasAllErrors && getFieldProperties('lastName').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.zip_code.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.personal_data.labels.zip_code.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="3" appNumericInput matInput formControlName="zipCode"
                                       placeholder="000000" maxlength="10">
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('gender').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.ui_language.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="5"
                                            placeholder="{{ 'teacher-profile.sections.personal_data.labels.ui_language.placeholder' | translate }}"
                                            formControlName="language" [compareWith]="compareFn">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('language').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.ui_language.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.last_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="2" matInput formControlName="lastName"
                                       placeholder="{{ 'teacher-profile.sections.personal_data.labels.last_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('lastName').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.last_name.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('lastName').hasError">
                                {{ 'teacher-profile.sections.personal_data.labels.last_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('lastName').hasAllErrors && getFieldProperties('firstName').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.gender.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.about_me.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="4"
                                            placeholder="{{ 'teacher-profile.sections.personal_data.labels.gender.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('gender').hasAllErrors}"
                                            formControlName="gender">
                                    <mat-option *ngFor="let option of singleGender" [value]="option.value">
                                        {{ 'genders.' + option.value | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!--<mat-error
                                *ngIf="getFieldProperties('gender').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.gender.required_error' | translate }}
                            </mat-error> -->
                            <mat-error
                                *ngIf="!getFieldProperties('gender').hasAllErrors && getFieldProperties('zipCode').hasAllErrors">
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.birthday.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="6" matInput formControlName="birthday"
                                       placeholder="{{ 'teacher-profile.sections.personal_data.labels.birthday.placeholder' | translate }}"
                                       [matDatepicker]="picker" [max]="currentDate">
                                <mat-datepicker-toggle matIconSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('birthday').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.birthday.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>

                </div>
                <div class="form-title">{{ 'teacher-profile.sections.musical_profile.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.musical_profile.labels.instrument.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.musical_profile.labels.instrument.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="7"
                                            placeholder="{{ 'teacher-profile.sections.musical_profile.labels.instrument.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('instrument').hasAllErrors}"
                                            formControlName="instrument" multiple>
                                    <mat-option *ngFor="let option of instrument; trackBy: trackById"
                                                [value]="option">
                                        <mat-icon *ngIf="option?.gnd || option?.wikidata || option?.mimo" class="link-url-icon" [tooltip]="[{ name: 'GND', url: option?.gnd}, { name: 'Wikidata', url: option?.wikidata}, {name: 'MIMO',url: option?.mimo}]">open_in_new</mat-icon>
                                        {{ currentLanguage === 'eng' ? option.nameEn : option.nameDe }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('instrument').isRequired">
                                {{ 'teacher-profile.sections.musical_profile.labels.instrument.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('instrument').hasAllErrors && getFieldProperties('genre').hasAllErrors">
                            </mat-error>
                        </div>
                        <div class="form-field" *ngFor="let control of educationGroup?.controls; let i = index">
                            <p *ngIf="i < 1">{{ 'teacher-profile.sections.musical_profile.labels.institution.label' | translate }}</p>
                            <p *ngIf="i < 1"
                               class="small-label">{{ 'teacher-profile.sections.musical_profile.labels.institution.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="9"
                                       placeholder="{{ 'teacher-profile.sections.musical_profile.labels.institution.placeholder' | translate }}"
                                       matInput [formControl]="control.get('institution')" maxlength="50">
                            </mat-form-field>
                            <mat-error
                                *ngIf="(control.get('institution')?.hasError('requiredInstitution') && control.get('institution')?.dirty) || (control.get('institution')?.hasError('requiredInstitution') && control.get('institution')?.touched)">
                                {{ 'teacher-profile.sections.musical_profile.labels.institution.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.musical_profile.labels.genre.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.musical_profile.labels.genre.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="8"
                                            placeholder="{{ 'teacher-profile.sections.musical_profile.labels.genre.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('genre').hasAllErrors}"
                                            formControlName="genre" multiple>
                                    <mat-option *ngFor="let option of genres; trackBy: trackById" [value]="option">
                                        {{ this.currentLanguage === 'eng' ? option.nameEn : option.nameDe }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('genre').isRequired">
                                {{ 'teacher-profile.sections.musical_profile.labels.genre.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('genre').hasAllErrors && getFieldProperties('instrument').hasAllErrors">
                            </mat-error>
                        </div>
                        <div *ngFor="let control of educationGroup?.controls; let i = index" class="form-field">
                            <p *ngIf="i < 1" style="visibility: hidden">.</p>
                            <div class="todo-list">
                                <mat-form-field subscriptSizing="dynamic">
                                    <input tabindex="10"
                                           placeholder="{{ 'teacher-profile.sections.musical_profile.labels.degree.placeholder' | translate }}"
                                           maxlength="50" matInput [formControl]="control.get('degree')">
                                </mat-form-field>
                                <button mat-icon-button class="example-icon"
                                        [disabled]="educationGroup?.controls.length < 2" (click)="removeEducation(i)">
                                    <mat-icon class="close-icon"
                                              [ngClass]="{'disabled': educationGroup?.controls.length < 2}">close
                                    </mat-icon>
                                </button>
                            </div>
                            <mat-error
                                *ngIf="(control.get('degree')?.hasError('requiredDegree') && control.get('degree')?.dirty) || (control.get('degree')?.hasError('requiredDegree') && control.get('degree')?.touched)">
                                {{ 'teacher-profile.sections.musical_profile.labels.degree.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!!((control.get('institution')?.hasError('requiredInstitution') && control.get('institution')?.dirty) || (control.get('institution')?.hasError('requiredInstitution') && control.get('institution')?.touched)) && !((control.get('degree')?.hasError('requiredDegree') && control.get('degree')?.dirty) || (control.get('degree')?.hasError('requiredDegree') && control.get('degree')?.touched))">
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="custom-theme add-more">
                    <button tabindex="11" mat-stroked-button color="primary"
                            (click)="addEducationControls()">{{ 'teacher-profile.buttons.add_another' | translate }}
                    </button>
                </div>
                <div class="form-field">
                    <p>{{ 'teacher-profile.sections.about_me.label' | translate }}</p>
                    <p class="small-label">{{ 'teacher-profile.sections.about_me.optional_text' | translate }}</p>
                    <mat-form-field>
                        <textarea tabindex="12"
                                  placeholder="{{ 'teacher-profile.sections.about_me.placeholder' | translate }}"
                                  maxlength="300" matInput formControlName="goals"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-title">{{ 'teacher-profile.sections.student_preference.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.teaching_location.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.teaching_location.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="13"
                                            placeholder="{{ 'teacher-profile.sections.student_preference.labels.teaching_location.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('studentPreferenceLocation').hasAllErrors}"
                                            formControlName="studentPreferenceLocation" multiple>
                                    <ng-container *ngFor="let option of teachingLocation">
                                        <mat-option *ngIf="option.value === 'no-preference'; else others"
                                                    (click)="updateFormControl(false, 'studentPreferenceLocation', 'no-preference')"
                                                    [value]="option.value">{{ 'teaching_locations.' + option.value | translate }}
                                        </mat-option>
                                        <ng-template #others>
                                            <mat-option
                                                (click)="updateFormControl(true, 'studentPreferenceLocation', 'no-preference')"
                                                [value]="option.value">{{ 'teaching_locations.' + option.value | translate }}
                                            </mat-option>
                                        </ng-template>
                                    </ng-container>
                                    <mat-select-trigger>
                    <span class="example-additional-selection">
                     {{ getTeachingLocationOptions }}
                   </span>
                                    </mat-select-trigger>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('studentPreferenceLocation').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.teaching_location.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('studentPreferenceLocation').hasAllErrors && getFieldProperties('studentPreferenceAgeGroup').hasAllErrors"></mat-error>
                        </div>
                        <!--<div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.gender_preference.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.teaching_location.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select placeholder="{{ 'teacher-profile.sections.student_preference.labels.gender_preference.placeholder' | translate }}" [ngClass]="{'default-select-icon-color': !getFieldProperties('studentGenderPreference').hasAllErrors}" formControlName="studentGenderPreference" multiple>
                                            <mat-option [value]="'no_preference'" (click)="onNoGenderPreference()">{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}</mat-option>
                                            <mat-option *ngFor="let option of selectGender" [disabled]="option.value !== 'no_preference' && getFieldProperties('studentGenderPreference', teacherProfileForm).control?.value?.includes('no_preference')"
                                                        [value]="option.value">{{ 'genders.' + option.value | translate }}
                                            </mat-option>
                                    <mat-select-trigger>
                            <span class="example-additional-selection">
                             {{getSelectedGenderOptions}}
                           </span>
                                    </mat-select-trigger>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('studentGenderPreference').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.gender_preference.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="!getFieldProperties('studentGenderPreference').hasAllErrors && getFieldProperties('spokenLanguages').hasAllErrors"></mat-error>
                        </div> -->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.experience_level.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.teaching_location.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select
                                    tabindex="15"
                                    placeholder="{{ 'teacher-profile.sections.student_preference.labels.experience_level.placeholder' | translate }}"
                                    [ngClass]="{'default-select-icon-color': !getFieldProperties('studentExperienceLevel').hasAllErrors}"
                                    formControlName="studentExperienceLevel" multiple>
                                    <mat-option [value]="'no_preference'"
                                                (click)="updateFormControl(false, 'studentExperienceLevel')">{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}
                                    </mat-option>
                                    <mat-option (click)="updateFormControl(true, 'studentExperienceLevel')" *ngFor="let option of experienceLevel"
                                                [value]="option.value">{{ 'experience_levels.' + option.value | translate }}
                                    </mat-option>
                                    <mat-select-trigger>
                                  <span class="example-additional-selection">
                                     {{ getStudentExperienceLevelOptions }}
                                  </span>
                                    </mat-select-trigger>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('studentExperienceLevel').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.experience_level.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.age_group.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.age_group.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="14"
                                            placeholder="{{ 'teacher-profile.sections.student_preference.labels.age_group.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('studentPreferenceAgeGroup').hasAllErrors}"
                                            formControlName="studentPreferenceAgeGroup" multiple>
                                    <mat-option [value]="'no_preference'"
                                                (click)="updateFormControl(false, 'studentPreferenceAgeGroup')"
                                               >{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}
                                    </mat-option>
                                    <mat-option
                                        (click)="updateFormControl(true, 'studentPreferenceAgeGroup')"
                                        *ngFor="let option of ageGroupOptions" [value]="option">{{ option }}
                                    </mat-option>
                                    <mat-select-trigger>
                                        <span class="example-additional-selection">{{ getSelectedOptions }}</span>
                                    </mat-select-trigger>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('studentPreferenceAgeGroup').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.age_group.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('studentPreferenceAgeGroup').hasAllErrors && getFieldProperties('studentPreferenceLocation').hasAllErrors"></mat-error>
                        </div>
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.spoken_language.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.spoken_language.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="16" multiple
                                            placeholder="{{ 'teacher-profile.sections.student_preference.labels.spoken_language.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('spokenLanguages').hasAllErrors}"
                                            formControlName="spokenLanguages">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('spokenLanguages').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.spoken_language.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('spokenLanguages').hasAllErrors && getFieldProperties('gender').hasAllErrors"></mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-title">{{ 'teacher-profile.sections.contact.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.contact.labels.email.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="17"
                                       placeholder="{{ 'teacher-profile.sections.contact.labels.email.placeholder' | translate }}"
                                       matInput formControlName="email" appTrim required>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('email').isRequired">
                                {{ 'teacher-profile.sections.contact.labels.email.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                                {{ 'teacher-profile.sections.contact.labels.email.invalid_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="email?.hasError('alreadyExist')">
                                <!-- Replace key after json update   -->
                                {{ 'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                            </mat-error>
                        </div>

                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.contact.labels.phone.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.contact.labels.phone.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="19"
                                       placeholder="{{ 'teacher-profile.sections.contact.labels.phone.placeholder' | translate }}"
                                       matInput formControlName="phone" maxlength="15" minlength="5" appNumericInput>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('phone').hasError">
                                {{ 'teacher-profile.sections.contact.labels.phone.minlength_error' | translate }}
                            </mat-error>
                        </div>

                        <!-- <div class="form-field" style="display: none;">
                             <p>{{ 'teacher-profile.sections.contact.labels.match_notification' | translate }}</p>
                             <mat-form-field subscriptSizing="dynamic">
                                 <mat-select placeholder="{{ 'teacher-profile.sections.contact.labels.match_notification' | translate }}" [ngClass]="{'default-select-icon-color': !getFieldProperties('contactMatchNotification').hasAllErrors}" formControlName="contactMatchNotification">
                                     <mat-option *ngFor="let option of matchNotifications" [value]="option">{{ option }}</mat-option>
                                 </mat-select>
                             </mat-form-field>
                             <mat-error *ngIf="getFieldProperties('contactMatchNotification').isRequired">
                                 {{ 'teacher-profile.sections.contact.labels.match_notification.required_error' | translate }}
                             </mat-error>
                         </div> -->
                        <div class="form-field" *ngFor="let control of socialMediaGroup?.controls; let i = index">
                            <p *ngIf="i < 1">{{ 'teacher-profile.sections.contact.labels.social_media.label' | translate }}</p>
                            <p *ngIf="i < 1"
                               class="small-label">{{ 'teacher-profile.sections.contact.labels.social_media.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <div *ngIf="control.get('custom').value" class="custom-type-wrapper">
                                    <input tabindex="21" id="custom-{{i}}" #customInput
                                           placeholder="{{ 'teacher-profile.sections.contact.labels.social_media.placeholder' | translate }}"
                                           matInput
                                           [formControl]="control.get('socialMedia')">
                                    <mat-icon (click)="clearInput(control)">clear</mat-icon>
                                </div>
                                <mat-select tabindex="21" *ngIf="!control.get('custom').value"
                                            (selectionChange)="onSelectSocialMedia($event, control, i)"
                                            placeholder="{{ 'teacher-profile.sections.contact.labels.social_media.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))}"
                                            [formControl]="control.get('socialMedia')">
                                    <mat-option *ngFor="let option of selectOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                *ngIf="(control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)">
                                {{ 'teacher-profile.sections.contact.labels.social_media.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="!!((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)) &&
                !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))">
                            </mat-error>
                        </div>
                        <div class="custom-theme add-more">
                            <button tabindex="23" mat-stroked-button color="primary"
                                    (click)="addSocialMediaControls()">{{ 'teacher-profile.buttons.add_another' | translate }}
                            </button>
                        </div>
                    </div>

                    <div class="form-column">
                        <div class="form-field checkbox">
                            <mat-checkbox tabindex="18"
                                          formControlName="allowContactViaEmail">{{ 'teacher-profile.sections.contact.labels.allow_contact_via_email.label' | translate }}
                            </mat-checkbox>
                        </div>
                        <mat-error *ngIf="getFieldProperties('email').hasAllErrors"></mat-error>

                        <div class="form-field checkbox">
                            <mat-checkbox tabindex="20"
                                          formControlName="allowContactViaPhone">{{ 'teacher-profile.sections.contact.labels.allow_contact_via_phone.label' | translate }}
                            </mat-checkbox>
                        </div>

                        <mat-error *ngIf="getFieldProperties('contactMatchNotification').hasAllErrors"></mat-error>
                        <mat-error *ngIf="getFieldProperties('phone').hasAllErrors"></mat-error>

                        <div *ngFor="let control of socialMediaGroup?.controls; let i = index" class="form-field">
                            <p *ngIf="i < 1" style="visibility: hidden">.</p>
                            <div class="todo-list">
                                <mat-form-field subscriptSizing="dynamic">
                                    <input tabindex="22" placeholder="{{ 'teacher-profile.sections.contact.labels.social_media.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" matInput
                                           [formControl]="control.get('link')">
                                </mat-form-field>
                                <button mat-icon-button class="example-icon"
                                        [disabled]="socialMediaGroup?.controls.length < 2" (click)="removeSocial(i)">
                                    <mat-icon class="close-icon"
                                              [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close
                                    </mat-icon>
                                </button>
                            </div>
                            <mat-error
                                *ngIf="(control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)">
                                {{ 'teacher-profile.sections.contact.labels.social_media_url.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="!!((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)) &&
                !((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched))">
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>


            <!-- MOBILE-->
            <div *ngIf="isMobilePlatform">
                <div
                    class="form-title personal-data">{{ 'teacher-profile.sections.personal_data.form_title' | translate }}
                </div>
                <div class="form-row">
                    <div class="form-column">
                        <!-- firstName-->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.first_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="2" matInput formControlName="firstName"
                                       placeholder="{{ 'teacher-profile.sections.personal_data.labels.first_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('firstName').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.first_name.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('firstName').hasError">
                                {{ 'teacher-profile.sections.personal_data.labels.first_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('firstName').hasAllErrors && getFieldProperties('lastName').hasAllErrors">
                            </mat-error>
                        </div>

                        <!-- lastName-->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.last_name.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="2" matInput formControlName="lastName"
                                       placeholder="{{ 'teacher-profile.sections.personal_data.labels.last_name.placeholder' | translate }}"
                                       required>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('lastName').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.last_name.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('lastName').hasError">
                                {{ 'teacher-profile.sections.personal_data.labels.last_name.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('lastName').hasAllErrors && getFieldProperties('firstName').hasAllErrors">
                            </mat-error>
                        </div>

                        <!-- zipCode-->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.zip_code.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.personal_data.labels.zip_code.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="3" appNumericInput matInput formControlName="zipCode"
                                       placeholder="000000" maxlength="10">
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('gender').hasAllErrors">
                            </mat-error>
                        </div>

                        <!-- gender-->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.gender.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.about_me.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="4"
                                            placeholder="{{ 'teacher-profile.sections.personal_data.labels.gender.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('gender').hasAllErrors}"
                                            formControlName="gender">
                                    <mat-option *ngFor="let option of singleGender" [value]="option.value">
                                        {{ 'genders.' + option.value | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!--<mat-error
                                *ngIf="getFieldProperties('gender').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.gender.required_error' | translate }}
                            </mat-error> -->
                            <mat-error
                                *ngIf="!getFieldProperties('gender').hasAllErrors && getFieldProperties('zipCode').hasAllErrors">
                            </mat-error>
                        </div>

                        <!-- birthday-->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.birthday.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="6" matInput formControlName="birthday"
                                       placeholder="{{ 'teacher-profile.sections.personal_data.labels.birthday.placeholder' | translate }}"
                                       [matDatepicker]="picker" [max]="currentDate">
                                <mat-datepicker-toggle matIconSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('birthday').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.birthday.required_error' | translate }}
                            </mat-error>
                        </div>

                        <!-- language-->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.personal_data.labels.ui_language.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="5"
                                            placeholder="{{ 'teacher-profile.sections.personal_data.labels.ui_language.placeholder' | translate }}"
                                            formControlName="language" [compareWith]="compareFn">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('language').isRequired">
                                {{ 'teacher-profile.sections.personal_data.labels.ui_language.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-column">

                    </div>

                </div>
                <div class="form-title">{{ 'teacher-profile.sections.musical_profile.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field mobile" *ngFor="let control of educationGroup?.controls; let i = index">
                            <p *ngIf="i < 1">{{ 'teacher-profile.sections.musical_profile.labels.institution.label' | translate }}</p>
                            <p *ngIf="i < 1"
                               class="small-label">{{ 'teacher-profile.sections.musical_profile.labels.institution.optional_text' | translate }}</p>
                            <div class="fields-container-wrapper">
                                <div class="fields-container">
                                    <mat-form-field subscriptSizing="dynamic">
                                        <input tabindex="9"
                                               placeholder="{{ 'teacher-profile.sections.musical_profile.labels.institution.placeholder' | translate }}"
                                               matInput [formControl]="control.get('institution')" maxlength="50">
                                    </mat-form-field>
                                    <mat-error
                                        *ngIf="(control.get('institution')?.hasError('requiredInstitution') && control.get('institution')?.dirty) || (control.get('institution')?.hasError('requiredInstitution') && control.get('institution')?.touched)">
                                        {{ 'teacher-profile.sections.musical_profile.labels.institution.required_error' | translate }}
                                    </mat-error>
                                    <div class="todo-list mobile">
                                        <mat-form-field subscriptSizing="dynamic">
                                            <input tabindex="10"
                                                   placeholder="{{ 'teacher-profile.sections.musical_profile.labels.degree.placeholder' | translate }}"
                                                   maxlength="50" matInput [formControl]="control.get('degree')">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <button mat-icon-button class="example-icon"
                                        [disabled]="educationGroup?.controls.length < 2" (click)="removeEducation(i)">
                                    <mat-icon class="close-icon"
                                              [ngClass]="{'disabled': educationGroup?.controls.length < 2}">close
                                    </mat-icon>
                                </button>
                            </div>
                            <div class="error-wrapper mobile">
                                <mat-error
                                    *ngIf="(control.get('degree')?.hasError('requiredDegree') && control.get('degree')?.dirty) || (control.get('degree')?.hasError('requiredDegree') && control.get('degree')?.touched)">
                                    {{ 'teacher-profile.sections.musical_profile.labels.degree.required_error' | translate }}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custom-theme add-more">
                    <button tabindex="11" mat-stroked-button color="primary"
                            (click)="addEducationControls()">{{ 'teacher-profile.buttons.add_another' | translate }}
                    </button>
                </div>
                <div class="form-field">
                    <p>{{ 'teacher-profile.sections.about_me.label' | translate }}</p>
                    <p class="small-label">{{ 'teacher-profile.sections.about_me.optional_text' | translate }}</p>
                    <mat-form-field>
                        <textarea tabindex="12"
                                  placeholder="{{ 'teacher-profile.sections.about_me.placeholder' | translate }}"
                                  maxlength="300" matInput formControlName="goals"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-title">{{ 'teacher-profile.sections.student_preference.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.teaching_location.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.teaching_location.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="13"
                                            placeholder="{{ 'teacher-profile.sections.student_preference.labels.teaching_location.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('studentPreferenceLocation').hasAllErrors}"
                                            formControlName="studentPreferenceLocation" multiple>
                                    <ng-container *ngFor="let option of teachingLocation">
                                        <mat-option *ngIf="option.value === 'no-preference'; else others"
                                                    (click)="updateFormControl(false, 'studentPreferenceLocation', 'no-preference')"
                                                    [value]="option.value">{{ 'teaching_locations.' + option.value | translate }}
                                        </mat-option>
                                        <ng-template #others>
                                            <mat-option
                                                (click)="updateFormControl(true, 'studentPreferenceLocation', 'no-preference')"
                                                [value]="option.value">{{ 'teaching_locations.' + option.value | translate }}
                                            </mat-option>
                                        </ng-template>
                                    </ng-container>
                                    <mat-select-trigger>
                    <span class="example-additional-selection">
                     {{ getTeachingLocationOptions }}
                   </span>
                                    </mat-select-trigger>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('studentPreferenceLocation').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.teaching_location.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('studentPreferenceLocation').hasAllErrors && getFieldProperties('studentPreferenceAgeGroup').hasAllErrors"></mat-error>
                        </div>
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.age_group.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.age_group.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="14"
                                            placeholder="{{ 'teacher-profile.sections.student_preference.labels.age_group.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('studentPreferenceAgeGroup').hasAllErrors}"
                                            formControlName="studentPreferenceAgeGroup" multiple>
                                    <mat-option [value]="'no_preference'"
                                                (click)="updateFormControl(false, 'studentPreferenceAgeGroup')"
                                    >{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}
                                    </mat-option>
                                    <mat-option
                                        (click)="updateFormControl(true, 'studentPreferenceAgeGroup')"
                                        *ngFor="let option of ageGroupOptions" [value]="option">{{ option }}
                                    </mat-option>
                                    <mat-select-trigger>
                                        <span class="example-additional-selection">{{ getSelectedOptions }}</span>
                                    </mat-select-trigger>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('studentPreferenceAgeGroup').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.age_group.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('studentPreferenceAgeGroup').hasAllErrors && getFieldProperties('studentPreferenceLocation').hasAllErrors"></mat-error>
                        </div>
                        <!--<div class="form-field">
                      <p>{{ 'teacher-profile.sections.student_preference.labels.gender_preference.label' | translate }}</p>
                      <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.teaching_location.multiple_possible' | translate }}</p>
                      <mat-form-field subscriptSizing="dynamic">
                          <mat-select placeholder="{{ 'teacher-profile.sections.student_preference.labels.gender_preference.placeholder' | translate }}" [ngClass]="{'default-select-icon-color': !getFieldProperties('studentGenderPreference').hasAllErrors}" formControlName="studentGenderPreference" multiple>
                                      <mat-option [value]="'no_preference'" (click)="onNoGenderPreference()">{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}</mat-option>
                                      <mat-option *ngFor="let option of selectGender" [disabled]="option.value !== 'no_preference' && getFieldProperties('studentGenderPreference', teacherProfileForm).control?.value?.includes('no_preference')"
                                                  [value]="option.value">{{ 'genders.' + option.value | translate }}
                                      </mat-option>
                              <mat-select-trigger>
                      <span class="example-additional-selection">
                       {{getSelectedGenderOptions}}
                     </span>
                              </mat-select-trigger>
                          </mat-select>
                      </mat-form-field>
                      <mat-error *ngIf="getFieldProperties('studentGenderPreference').isRequired">
                          {{ 'teacher-profile.sections.student_preference.labels.gender_preference.required_error' | translate }}
                      </mat-error>
                      <mat-error *ngIf="!getFieldProperties('studentGenderPreference').hasAllErrors && getFieldProperties('spokenLanguages').hasAllErrors"></mat-error>
                  </div> -->
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.spoken_language.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.spoken_language.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select tabindex="16" multiple
                                            placeholder="{{ 'teacher-profile.sections.student_preference.labels.spoken_language.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('spokenLanguages').hasAllErrors}"
                                            formControlName="spokenLanguages">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('spokenLanguages').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.spoken_language.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="!getFieldProperties('spokenLanguages').hasAllErrors && getFieldProperties('gender').hasAllErrors"></mat-error>
                        </div>
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.student_preference.labels.experience_level.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.student_preference.labels.teaching_location.multiple_possible' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select
                                    tabindex="15"
                                    placeholder="{{ 'teacher-profile.sections.student_preference.labels.experience_level.placeholder' | translate }}"
                                    [ngClass]="{'default-select-icon-color': !getFieldProperties('studentExperienceLevel').hasAllErrors}"
                                    formControlName="studentExperienceLevel" multiple>
                                    <mat-option [value]="'no_preference'"
                                                (click)="updateFormControl(false, 'studentExperienceLevel')">{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}
                                    </mat-option>
                                    <mat-option (click)="updateFormControl(true, 'studentExperienceLevel')" *ngFor="let option of experienceLevel"
                                                [disabled]="getFieldProperties('studentExperienceLevel', teacherProfileForm).control?.value?.includes('no_preference')"
                                                [value]="option.value">{{ 'experience_levels.' + option.value | translate }}
                                    </mat-option>
                                    <mat-select-trigger>
                                  <span class="example-additional-selection">
                                     {{ getStudentExperienceLevelOptions }}
                                  </span>
                                    </mat-select-trigger>
                                </mat-select>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('studentExperienceLevel').isRequired">
                                {{ 'teacher-profile.sections.student_preference.labels.experience_level.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-title">{{ 'teacher-profile.sections.contact.form_title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.contact.labels.email.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="17"
                                       placeholder="{{ 'teacher-profile.sections.contact.labels.email.placeholder' | translate }}"
                                       matInput formControlName="email" appTrim required>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('email').isRequired">
                                {{ 'teacher-profile.sections.contact.labels.email.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                                {{ 'teacher-profile.sections.contact.labels.email.invalid_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="email?.hasError('alreadyExist')">
                                <!-- Replace key after json update   -->
                                {{ 'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                            </mat-error>
                        </div>
                        <div class="form-field checkbox">
                            <mat-checkbox tabindex="18"
                                          formControlName="allowContactViaEmail">{{ 'teacher-profile.sections.contact.labels.allow_contact_via_email.label' | translate }}
                            </mat-checkbox>
                        </div>
                        <div class="form-field">
                            <p>{{ 'teacher-profile.sections.contact.labels.phone.label' | translate }}</p>
                            <p class="small-label">{{ 'teacher-profile.sections.contact.labels.phone.optional_text' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="19"
                                       placeholder="{{ 'teacher-profile.sections.contact.labels.phone.placeholder' | translate }}"
                                       matInput formControlName="phone" maxlength="15" minlength="5" appNumericInput>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('phone').hasError">
                                {{ 'teacher-profile.sections.contact.labels.phone.minlength_error' | translate }}
                            </mat-error>
                        </div>
                        <div class="form-field checkbox">
                            <mat-checkbox tabindex="20"
                                          formControlName="allowContactViaPhone">{{ 'teacher-profile.sections.contact.labels.allow_contact_via_phone.label' | translate }}
                            </mat-checkbox>
                        </div>

                        <!-- <div class="form-field" style="display: none;">
                             <p>{{ 'teacher-profile.sections.contact.labels.match_notification' | translate }}</p>
                             <mat-form-field subscriptSizing="dynamic">
                                 <mat-select placeholder="{{ 'teacher-profile.sections.contact.labels.match_notification' | translate }}" [ngClass]="{'default-select-icon-color': !getFieldProperties('contactMatchNotification').hasAllErrors}" formControlName="contactMatchNotification">
                                     <mat-option *ngFor="let option of matchNotifications" [value]="option">{{ option }}</mat-option>
                                 </mat-select>
                             </mat-form-field>
                             <mat-error *ngIf="getFieldProperties('contactMatchNotification').isRequired">
                                 {{ 'teacher-profile.sections.contact.labels.match_notification.required_error' | translate }}
                             </mat-error>
                         </div> -->
                        <div class="form-field mobile"
                             *ngFor="let control of socialMediaGroup?.controls; let i = index">
                            <p *ngIf="i < 1">{{ 'teacher-profile.sections.contact.labels.social_media.label' | translate }}</p>
                            <p *ngIf="i < 1"
                               class="small-label">{{ 'teacher-profile.sections.contact.labels.social_media.optional_text' | translate }}</p>
                            <div class="fields-container-wrapper">
                                <div class="fields-container">
                                    <mat-form-field subscriptSizing="dynamic">
                                        <div *ngIf="control.get('custom').value" class="custom-type-wrapper">
                                            <input tabindex="21" id="custom-{{i}}" #customInput
                                                   placeholder="{{ 'teacher-profile.sections.contact.labels.social_media.placeholder' | translate }}"
                                                   matInput
                                                   [formControl]="control.get('socialMedia')">
                                            <mat-icon (click)="clearInput(control)">clear</mat-icon>
                                        </div>
                                        <mat-select tabindex="21" *ngIf="!control.get('custom').value"
                                                    (selectionChange)="onSelectSocialMedia($event, control, i)"
                                                    placeholder="{{ 'teacher-profile.sections.contact.labels.social_media.placeholder' | translate }}"
                                                    [ngClass]="{'default-select-icon-color': !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))}"
                                                    [formControl]="control.get('socialMedia')">
                                            <mat-option *ngFor="let option of selectOptions"
                                                        [value]="option.value">{{ option.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error
                                        *ngIf="(control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)">
                                        {{ 'teacher-profile.sections.contact.labels.social_media.required_error' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="!!((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)) &&
                !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))">
                                    </mat-error>

                                    <div class="todo-list mobile">
                                        <mat-form-field subscriptSizing="dynamic">
                                            <input tabindex="22" placeholder="{{ 'teacher-profile.sections.contact.labels.social_media.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" matInput
                                                   [formControl]="control.get('link')">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <button mat-icon-button class="example-icon"
                                        [disabled]="socialMediaGroup?.controls.length < 2"
                                        (click)="removeSocial(i)">
                                    <mat-icon class="close-icon"
                                              [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close
                                    </mat-icon>
                                </button>
                            </div>
                            <div class="error-wrapper mobile">
                                <mat-error
                                    *ngIf="(control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)">
                                    {{ 'teacher-profile.sections.contact.labels.social_media_url.required_error' | translate }}
                                </mat-error>
                                <mat-error *ngIf="!!((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)) &&
                !((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched))">
                                </mat-error>
                            </div>
                        </div>
                        <div class="custom-theme add-more">
                            <button tabindex="23" mat-stroked-button color="primary"
                                    (click)="addSocialMediaControls()">{{ 'teacher-profile.buttons.add_another' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container>
                <div class="form-title">
                    {{ 'teacher-profile.sections.availability.form_title' | translate }}
                </div>
                <div class="form-field">
                    <mat-grid-list [cols]="isMobilePlatform ? 1 : 7" [rowHeight]="isMobilePlatform ? '370px' : '285px'">
                        <mat-grid-tile *ngFor="let chip of chips; let i = index">
                            <div class="chips-container">
                                <div class="day-container">
                                    <div class="weekday">{{ 'days.' + chip.day | translate }}</div>
                                    <div class="date">{{ chip.date }}</div>
                                </div>
                                <mat-chip-listbox
                                    formArrayName="availabilityTimeStamp"
                                    class="mat-mdc-chip-set-stacked" multiple>
                                    <mat-chip-option tabIndex="{{i + 23}}" class="timeStamp select-all"
                                                     [selected]="setSelectedAll(availabilityTimeStamp.value[i]?.selectedTime)"
                                                     (keydown.enter)="selectAllTimeSlots(i)"
                                                     (click)="selectAllTimeSlots(i)">{{ 'days.all-day' | translate }}
                                    </mat-chip-option>
                                    <mat-chip-option tabIndex="{{i + 30}}"
                                                     [selected]="setSelectedSlot(availabilityTimeStamp.value[i]?.selectedTime, timeSlot)"
                                                     class="timeStamp"
                                                     (selectionChange)="onSelectTime($event, i, timeSlot)"
                                                     *ngFor="let timeSlot of chip.time"> {{ timeSlot }}
                                    </mat-chip-option>
                                </mat-chip-listbox>
                            </div>
                        </mat-grid-tile>
                        <mat-error class="availability-error"
                                   *ngIf="!isValidAvailabilityTimeStamp && availabilityTimeStamp?.touched">
                            {{ 'teacher-profile.sections.availability.availability_error' | translate }}
                        </mat-error>
                    </mat-grid-list>
                </div>
            </ng-container>
        </form>

    </div>

    <div class="footer desktop">
        <mat-divider></mat-divider>
        <div class="btn-container custom-theme">
            <div>
                <button tabindex="60" *ngIf="data && !data?.hasEditProfile" mat-stroked-button color="primary"
                        (click)="deleteTeacher('teacher-profile.buttons.delete_teacher')">{{ 'teacher-profile.buttons.delete_teacher' | translate }}
                </button>
            </div>
            <div class="right-side">
                <button tabindex="61" mat-stroked-button color="primary"
                        (click)="discard()">{{ 'teacher-profile.buttons.discard' | translate }}
                </button>
                <button tabindex="62" mat-raised-button color="primary" [disabled]="loading" type="submit"
                        (click)="onSubmit()">{{ 'teacher-profile.buttons.save_changes' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

