import { MusicEntity } from '../../../shared/services/music.service';

export enum UserLanguage {
    ENGLISH = 'english',
    GERMAN = 'german',
}

export enum UserRole {
    ROOT = 'root',
    SCHOOL_ADMIN = 'school_admin',
    TEACHER = 'teacher',
    STUDENT = 'student',
}

export enum UserGender {
    MALE = 'male',
    FEMALE = 'female'
}

export type Instrument = 'e-guitar'
    | 'piano-grand-piano'
    | 'violin'
    | 'trumpet'
    | 'flute-transverse-flute'
    | 'singing-vocal'
    | 'dj'
    | 'e-piano-keyboard'
    | 'horn'
    | 'bongo'
    | 'guitar'
    | 'music-production'
    | 'viola'
    | 'cello-violoncello'
    | 'bass-guitar-e-bass'
    | 'recorder'
    | 'double-bass'
    | 'trombone'
    | 'tuba'
    | 'harp'
    | 'saxophone'
    | 'music-theory'
    | 'composition'
    | 'sound-engineering'
    | 'drums-percussion'
    | 'sound-therapy'
    | 'ukulele'
    | 'marimba'
    | 'bagpipe'
    | 'accordion'
    | 'chimes'
    | 'other-instrument';

export type Genre = 'classic'
    | 'pop'
    | 'rock'
    | 'jazz'
    | 'musical'
    | 'hip-hop'
    | 'funk'
    | 'soul'
    | 'folk-music'
    | 'schlager'
    | 'electro'
    | 'do-not-know'
    | 'other-genre';

export type ExperienceLevel = 'none'
    | 'self-acquired-skills'
    | '1-to-3-years-of-instruction'
    | '4-to-6-years-of-instruction'
    | 'more-than-6-years-of-teaching';

export type TeachingLocation = 'no-preference'
    | 'in-the-music-school'
    | 'at-the-students-home'
    | 'at-the-teachers-home'
    | 'online'
    | 'hybrid';

export type GenderPreference = 'male' | 'female' | 'non-binary' | 'transgender' | 'intersex' | 'other';

export type EducationItem = {
    institution: string;
    degree: string;
};

export type SocialsItem = {
    type: string;
    link: string;
};

export type SocialsMediaItem = {
    socialMedia: string;
    nickName: string
}

export type SchoolSocialsItem = {
    socialMedia: string;
    link: string
}

export type UnionsItem = {
    name: string;
    membership: string;
};

export type PriceItem = {
    price: string;
    length: string;
};

export type AgePreference = '0-12' | '13-17' | '18-39' | '40-64' | '65-100';

export interface User {
    id: string,
    language: UserLanguage,
    role: UserRole,
    manageSchoolId: string,
    email: string,
    firstName: string,
    lastName: string,
    timezone: string,
    postalCode: number,
    address: string,
    gender: UserGender,
    birthday: string,
    modified: string,
    created: string,
    teacher?: {
        id: string,
        userId: string,
        schoolId: string,
        certified: boolean,
        instruments: Instrument[],
        genres: Genre[],
        yearsExperience: number,
        studentsExperienceLevel: ExperienceLevel[],
        teachingLocation: TeachingLocation[],
        education: string[],
        socials: string[],
        workWithMinors: boolean,
        studentPreferences: string,
        agePreference: AgePreference[],
        description: string
    },
    student?: {
        id: string,
        userId: string,
        schoolId?: string,
        instruments: Instrument[],
        genres: Genre[],
        experienceLevel: ExperienceLevel[],
        teachingLocation: TeachingLocation[],
        socials: string[],
        lessonFrequency: number,
    },
}

export interface UserTeacher extends User {
    age: number;
}

export interface UserStudent extends User {
    age: number;
}

export interface MatchingResponse {
    id: string;
    teacherId: string;
    studentId: string;
    acceptedTeacher: boolean;
    acceptedStudent: boolean;
    timeSlots: string[];
    lesson: any;
}

export interface UpdateMatchingBody {
    teacherId: string;
    studentId: string;
    acceptedTeacher?: boolean;
    acceptedStudent?: boolean;
    timeSlots?: string[];
    lesson?: AddLessonBody;
    genres?: (string | MusicEntity | null)[];
    instruments?: (string | MusicEntity | null)[];
}

export enum LessonPlace {
    ONLINE = 'online',
    OFFLINE = 'offline'
}

export enum LessonType {
    TRIAL = 'trial',
    SINGLE = 'single',
    REPEAT = 'repeat'
}

export enum LessonDuration {
    '30_M' = '30m',
    '45_M' = '45m',
    '60_M' = '60m',
}

export enum LessonStatus {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    COMPLETED = 'completed',
    CANCELED = 'canceled',
}

export enum MatchNotification {
    TEACHER = 'send_to_teacher',
    SCHOOL = 'send_to_school',
    ANOTHER = 'send_to_another_email',
}

export class AddLessonBody {
    place: LessonPlace;
    teacherId: string;
    studentId: string;
    location?: string;
    roomId?: number;
    title: string;
    description?: string;
    targetTeacher?: string;
    targetStudent?: string;
    startTime: string;
    endTime: string;
    type: LessonType;
    duration: LessonDuration;
    status?: LessonStatus;
}

export interface SchedulesBody {
    day: number;
    startTime: string;
    endTime: string | null;
    notice?: number;
}


export interface UsersFormBody {
    language: string;
    role?: string;
    email: string;
    firstName: string;
    lastName: string;
    gender?: string | null;
    manageSchoolId?: string;
    postalCode?: number | null | string;
    phoneNumber?: string | null;
    allowPhoneContact?: boolean;
    allowEmailContact?: boolean;
    spokenLanguages?: string[];
    birthday?: string | null;
    timezone?: string;
}

export interface StudentFormBody {
    userId: string;
    instruments: Instrument[];
    genres: Genre[];
    experienceLevel: string;
    teachingLocation: TeachingLocation[];
    // genderPreference: GenderPreference[];
    description: string;
}


export interface TeacherFormBody {
    userId: string;
    schoolId: string;
    instruments: Instrument[];
    genres: Genre[];
    yearsExperience?: number;
    studentsExperienceLevel: ExperienceLevel[];
    teachingLocation: TeachingLocation[];
    education?: EducationItem[] | null;
    socials?: SocialsItem[] | null;
    workWithMinors?: boolean;
    studentPreferences?: string;
    agePreference: AgePreference[];
    description?: string;
    genderPreference?: GenderPreference[];
}

export interface UserResponse {
    id: string;
    language: string;
    spokenLanguages: null | any;
    role: string;
    manageSchoolId: null | any;
    email: string;
    files: Avatar[];
    firstName: string;
    lastName: string;
    timezone: null | any;
    postalCode: null | any;
    phoneNumber: null | any;
    gender: string;
    birthday: null | any;
    allowEmailContact: null | any;
    allowPhoneContact: null | any;
    modified: string;
    created: string;
}


export interface Teacher {
    id: string;
    language: string;
    spokenLanguages: string[];
    role: string;
    manageSchoolId: string;
    email: string;
    files: Avatar[];
    firstName: string;
    lastName: string;
    timezone: string | null;
    postalCode: number | null;
    distance?: number;
    phoneNumber: string | null;
    gender: string;
    birthday: string;
    city: string;
    fromMatch?: boolean;
    allowEmailContact: boolean;
    allowPhoneContact: boolean;
    modified: string;
    created: string;
    hasEditProfile?: boolean;
    acceptedStudent?: boolean;
    lesson?: Lesson;
    teacher: {
        id: string;
        userId: string;
        schoolId: string;
        validated: boolean;
        instruments: (string | MusicEntity | null)[];
        genres: (string | MusicEntity | null)[];
        yearsExperience: number | null;
        studentsExperienceLevel: ExperienceLevel[];
        teachingLocation: TeachingLocation[];
        education: EducationItem[];
        socials: SocialsItem[];
        workWithMinors: boolean | null;
        studentPreferences: any | null;
        agePreference:  AgePreference[];
        genderPreference: GenderPreference;
        description: string;
        school: School | null;
        priceRange: PriceRange | null;
    };
}


export interface IntegrationListResponse {
    list: Integration[];
    count: number;
    page: number;
    pages: number;
}

export interface IntegrationTeacherListResponse {
    list: IntegratedTeacher[];
    count: number;
    page: number;
    pages: number;
}

export interface IntegratedTeacher {
    id: string;
    integration: Integration;
    personalLink: string;
}

export interface Integration {
    id: string;
    name: string;
    description: string;
    website: string;
    schools: School[];
    teacherIntegrations: TeacherIntegration[];
    files: Avatar[];
}

interface TeacherIntegration {
    id: string;
    personalLink: string;
}

interface PriceRange {
    price: number;
    length: string;
}

export interface School {
    name: string;
    teachersAmount: number;
    studentsAmount: number;
    language: string;
    socials: SocialsItem[] | null;
    unions: UnionsItem[] | null;
    description: string | null;
    sponsor: string | null;
    postalCode: string | null;
    phoneNumber: string | null;
    priceRange: PriceRange | null;
    instruments: string[] | null;
    genres: string[] | null;
    files: Avatar[];
    matchNotification: MatchNotification;
}

export interface Student {
    id: string;
    language: string;
    spokenLanguages: string[],
    role: string;
    manageSchoolId: string;
    email: string;
    files: Avatar[];
    firstName: string;
    lastName: string;
    distance?: number;
    timezone: string | null;
    postalCode: string | null;
    phoneNumber: string | null;
    gender: string;
    birthday: string;
    city: string;
    fromMatch?: boolean;
    allowEmailContact: boolean;
    allowPhoneContact: boolean;
    modified: string;
    created: string;
    hasEditProfile?: boolean;
    validated: boolean;
    lesson?: Lesson;
    student: {
        id: string;
        userId: string;
        instruments: (string | MusicEntity | null)[];
        genres: (string | MusicEntity | null)[];
        experienceLevel: ExperienceLevel;
        teachingLocation: TeachingLocation[];
        education?: EducationItem[];
        socials?: SocialsItem[];
        genderPreference: GenderPreference[];
        description: string;
        teacherId: string;
    }
}

export interface TeachersResponse {
    list: Teacher[];
    count: number;
    page: number;
    pages: number;
}

export interface StudentsResponse {
    list: Student[];
    count: number;
    page: number;
    pages: number;
}

export interface ScheduleItem {
        created: string;
        day: number;
        endTime: string;
        id: string;
        overrideDate: null | string;
        startTime: string;
}



export interface Lesson {
    id: string;
    teacherId: string;
    studentId: string;
    place: LessonPlace;
    location?: string;
    roomId?: number;
    title: string;
    description?: string;
    targetTeacher?: string;
    targetStudent?: string;
    startTime: Date;
    endTime: string;
    type: LessonType;
    duration: LessonDuration;
    status?: LessonStatus;
    modified: string;
    created: string;
}

export interface LessonsList {
    list: Lesson[];
    count: number;
    page: number;
    pages: number;
}

export interface StudentLesson {
    studentId: string;
    duration: string;
    startTime: string;
    lessons: LessonsList;
}

export interface OrganisationBody {
    name: string;
    language?: string;
    socials: SocialsItem[] | null;
    unions: UnionsItem[] | null;
    description?: string;
    sponsor?: string | null;
    postalCode: string | null;
    phoneNumber: string | null;
    instruments?: Instrument[];
    genres?: Genre[];
}

export interface SchoolListResponse {
    list: SchoolItem[];
    count: number;
    page: number;
    pages: number;
}

export interface SchoolItem {
    id: string;
    confirmed: boolean;
    validated: boolean;
    name: string;
    teachersAmount: number;
    studentsAmount: number;
    language: string;
    socials: SocialsItem[];
    unions: UnionsItem[];
    description: null | string;
    sponsor: null | string;
    city: string;
    postalCode: null | string;
    phoneNumber: null | string;
    instruments: null | Instrument[];
    genres: null | Genre[];
    inviteUrl: string;
    modified: string;
    created: string;
    files: Avatar[];
    priceRange: PriceItem[];
    hasEditMyProfile?: boolean;
    hasEditProfile?: boolean;
    gender?: string;
    firstName?:string;
    lastName?:string;
    email?:string;
    adminId:string;
    matchNotification: string;
    matchNotificationEmails: string[];
    website: string;
}

export interface Avatar {
    id: string;
    type: string;
    url: string;
    key: string;
    userId: string | null;
    schoolId: string | null;
    created: string;
}

export interface TeacherUser {
    id: string;
    language: string;
    spokenLanguages: string[];
    role: string;
    manageSchoolId: null;
    email: string;
    firstName: string;
    lastName: string;
    timezone: string;
    postalCode: string;
    city: string;
    phoneNumber: null;
    gender: string;
    birthday: string;
    allowEmailContact: boolean;
    allowPhoneContact: boolean;
    files: any[];
    modified: string;
    created: string;
    teacher: {
        id: string;
        userId: string;
        schoolId: string;
        validated: boolean;
        studentsAmount: number;
        instruments: string[];
        genres: string[];
        yearsExperience: null;
        studentsExperienceLevel: string[];
        teachingLocation: string[];
        education: null;
        socials: null;
        workWithMinors: null;
        studentPreferences: null;
        agePreference: string[];
        genderPreference: string[];
        description: null;
        priceRange: null;
        school: null;
    };
}
export interface StudentUser {
    id: string;
    language: string;
    spokenLanguages: string[];
    role: string;
    manageSchoolId: null;
    email: string;
    firstName: string;
    lastName: string;
    timezone: string;
    postalCode: string;
    city: string;
    phoneNumber: null;
    gender: string;
    birthday: string;
    allowEmailContact: boolean;
    allowPhoneContact: boolean;
    files: any[];
    modified: string;
    created: string;
    student: {
        id: string;
        userId: string;
        schoolId: null;
        teacherId: null;
        instruments: string[];
        genres: string[];
        experienceLevel: string;
        teachingLocation: string[];
        genderPreference: string[];
        description: string;
    };
}

export interface Matches {
    id: string;
    instruments: (string | MusicEntity | null)[];
    lesson: Lesson;
    terminated: boolean;
    genres: (string | MusicEntity | null)[];
    acceptedTeacher: null | boolean;
    acceptedStudent: boolean;
    teacherUser: Teacher;
    studentUser: Student;
}

export interface MatchesListResponse {
    list: Matches[];
    count: number;
    page: number;
    pages: number;
    matchType: string;
    title: string;
}

export interface TimeSlotData {
    time: (string | TimeSlot)[];
    day: string;
    date: string;
    selectedDate?: Date;
}

export interface TimeSlot {
    name: string;
    selected: boolean;
}





