import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MusicEntity, MusicService } from '../../../../shared/services/music.service';
import { Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { UserInfo, UserRoles } from '../../../../core/models/user-info.interface';
import { UserService } from '../../../services/user/user.service';
import { PlatformService } from '../../../services/platform.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-genres-table',
  templateUrl: './genres-table.component.html',
  styleUrls: ['./genres-table.component.scss']
})
export class GenresTableComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = ['name', 'nameVariant'];
    dataSource: MatTableDataSource<MusicEntity>;
    currentIndex: number;
    currentPage = 0;
    pageSize = 10;
    sort: Sort;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('title') title: ElementRef;

    @Input() genres: MusicEntity[];
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

    protected readonly UserRoles = UserRoles;
    destroy$ = new Subject();
    currentLanguage: string = this.translateService.currentLang;

    get user(): UserInfo {
        return this.userService.userInfo;
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get paginatedData() {
        const startIndex = this.currentPage * this.pageSize;
        const endIndex = startIndex + 10;
        return this.dataSource.data.slice(startIndex, endIndex);
    }

    constructor(private userService: UserService,
                private platformService: PlatformService,
                private translateService: TranslateService,
                private musicService: MusicService) {
    }

    ngOnInit() {
        this.handleLanguageChange();
        this.dataSource = new MatTableDataSource<MusicEntity>(this.genres);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    handleLanguageChange() {
        this.translateService.onLangChange
            .pipe(
                takeUntil(this.destroy$),
                switchMap((event):Observable<any> => {
                    this.currentLanguage = event.lang;
                    const sortBy = this.currentLanguage === 'eng' ? 'nameEn' : 'nameDe';

                    return this.musicService.fetchSortedGenres( '1', '1000', sortBy, 'ASC');
                })
            )
            .subscribe((genres: { allItems: MusicEntity[] }) => {
                    this.genres = [];
                    genres.allItems.forEach((item) => this.genres = [...this.genres, item]);
                    this.dataSource = new MatTableDataSource<MusicEntity>(this.genres);
                    this.dataSource._updateChangeSubscription();
                    this.dataSource.paginator = this.paginator;
                },
                (error) => {
                    console.error('Error fetching sorted instruments:', error);
                }
            );
    }

    onPaginatorPageChange(event: PageEvent) {
        const titleElement = this.title.nativeElement;
        if (titleElement) {
            setTimeout(() => titleElement.focus());
        }
        this.currentIndex = event.pageIndex * event.pageSize;
    }

    getSortedGenres(sortBy: string, direction: string) {
        this.musicService.fetchSortedGenres( '1', '1000', sortBy, direction.toUpperCase()).subscribe(
            (instruments: { allItems: MusicEntity[] }) => {
                this.genres = [];
                instruments.allItems.forEach((item) => this.genres = [...this.genres, item]);
                this.dataSource = new MatTableDataSource<MusicEntity>(this.genres);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
            });
    }

    sortData(sort: Sort) {
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            const sortBy = this.currentLanguage === 'eng' ? 'nameEn' : 'nameDe';
            this.getSortedGenres(sortBy, 'ASC');
            return;
        }

        this.getSortedGenres(sort.active, sort.direction);
    }

    onPageChange(event: PageEvent) {
        this.currentIndex = event.pageIndex * event.pageSize;
        this.currentPage = event.pageIndex;
    }

    closeDialog() {
        this.closeModal.emit();
    }
}
