import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { StudentProfileInfoComponent } from '../../student-dashboard/student-profile-info/student-profile-info.component';
import { TeacherProfileInfoComponent } from '../../teacher-dashboard/teacher-profile-info/teacher-profile-info.component';
import { Subject, takeUntil } from 'rxjs';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { Matches, MatchesListResponse, Student, Teacher } from '../../../../services/api/api.type';
import { ApiService } from '../../../../services/api/api.service';
import { UserInfo, UserRoles } from '../../../../../core/models/user-info.interface';
import {
    MatchingTimeSelectComponent, TimeSelect
} from '../../../match-teachers/matching-time-select/matching-time-select.component';
import { UserService } from '../../../../services/user/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ComponentType } from '@angular/cdk/overlay';
import { Sort } from '@angular/material/sort';
@Component({
  selector: 'app-pending-matches',
  templateUrl: './pending-matches.component.html',
  styleUrls: ['./pending-matches.component.scss']
})
export class PendingMatchesComponent implements OnInit, AfterViewInit, OnDestroy {
    protected readonly UserRoles = UserRoles;
    displayedColumns: string[] = [ 'instrument', 'teacherFirstName', 'teacherLastName', 'studentFirstName', 'studentLastName', 'approve', 'teacherProfile', 'studentProfile'];
    dataSource: MatTableDataSource<Matches>;
    currentIndex: number;
    dialogRef: MatDialogRef<TeacherProfileInfoComponent | StudentProfileInfoComponent | MatchingTimeSelectComponent>;
    selectedTeacherId: string;
    destroy$ = new Subject();
    sort: Sort;
    currentLanguage: string = this.translateService.currentLang;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('title') title: ElementRef;

    @Input() matches: MatchesListResponse;
    @Output() updateActiveMatches: EventEmitter<void> = new EventEmitter<void>();

    get user(): UserInfo {
        return this.userService.userInfo;
    }

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private dashboardTableService: DashboardTableService,
        private translateService: TranslateService,
        private cd: ChangeDetectorRef,
        private apiService: ApiService) {
    }

    ngOnInit() {
        this.handleLanguageChange();
        this.dataSource = new MatTableDataSource<Matches>(this.matches.list);
        this.dashboardTableService.needUpdateTeacherTable.pipe(takeUntil(this.destroy$)).subscribe((update) => {
            this.getMatches(!!update);
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    handleLanguageChange() {
        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: LangChangeEvent) => {
                this.currentLanguage = event.lang;
                this.cd.detectChanges();
            });
    }
    openDialog(component: ComponentType<(StudentProfileInfoComponent | TeacherProfileInfoComponent | MatchingTimeSelectComponent)>, panelClass: string, dialogData?: (Teacher | Student | TimeSelect ), width = '864px', position = '32px'): void {
        document.body.classList.add('modal-open');
        const config = {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: true,
            position: { top: position },
            data: dialogData
        }
        if (position) {
            config.position = { top: position }
        }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.getMatches();
            }
            console.log('Dialog closed result', result);
        });
        if (panelClass === 'teacher-profile-info') {
            this.dialogRef = dialogRef;
            const modalComponentInstance = this.dialogRef.componentInstance as TeacherProfileInfoComponent;
            modalComponentInstance.teacherUpdated.subscribe((id) => this.selectedTeacherId = id);
        }

        if (panelClass === 'time-select') {
            this.dialogRef = dialogRef;
            const modalComponentInstance = this.dialogRef.componentInstance as MatchingTimeSelectComponent;
            modalComponentInstance.updateMatches.subscribe(() => this.updateActiveMatches.emit());
        }
    }

    onPaginatorPageChange(event: PageEvent) {
        const titleElement = this.title.nativeElement;
        if (titleElement) {
            setTimeout(() => titleElement.focus());
        }
        this.currentIndex = event.pageIndex * event.pageSize;
    }

    showStudentProfile(student: Matches) {
        this.openDialog(StudentProfileInfoComponent, 'student-profile-info', student.studentUser, '640px');
    }

    showTeacherProfile(teacher: Matches) {
        this.openDialog(TeacherProfileInfoComponent, 'teacher-profile-info', teacher.teacherUser, '640px');
    }


    getMatches(updateProfile = false) {
        const manageSchoolId = this.user.manageSchoolId;
        if (manageSchoolId) {
            this.apiService.getMatchesList(manageSchoolId, '1', '100', 'pending').subscribe(
                (matches: MatchesListResponse) => {
                    this.matches.list.length = 0;
                    matches.list.forEach((item) => {
                        this.matches?.list.push(item);
                    })
                    this.dataSource = new MatTableDataSource<Matches>(this.matches?.list);
                    this.dataSource._updateChangeSubscription();
                    this.dataSource.paginator = this.paginator;
                    if (updateProfile && this.selectedTeacherId) {
                        const selectedTeacher = this.matches.list.find((matches) => matches.teacherUser.id === this.selectedTeacherId);
                        if (selectedTeacher) {
                            const modalComponentInstance = this.dialogRef.componentInstance as TeacherProfileInfoComponent;
                            modalComponentInstance.setTeacher(selectedTeacher.teacherUser);
                            modalComponentInstance.setImage();
                        }
                    }
                });
        }
    }

    getSortedMatches(sortBy: string, direction: string) {
        const manageSchoolId = this.user.manageSchoolId;
        if (manageSchoolId) {
            this.apiService.getSortedMatches(manageSchoolId, '1', '100', 'pending', sortBy, direction.toUpperCase()).subscribe(
                (matches: MatchesListResponse) => {
                    this.matches.list.length = 0;
                    matches.list.forEach((item) => {
                        this.matches?.list.push(item);
                    })
                    this.dataSource = new MatTableDataSource<Matches>(this.matches?.list);
                    this.dataSource._updateChangeSubscription();
                    this.dataSource.paginator = this.paginator;
                });
        }
    }


    approve(student: Student, teacher: Teacher) {
        const teacherId = teacher?.teacher?.id || '';
        this.apiService.getMatching(teacherId, student.student.id).subscribe(
            (data) => {
                const modalDescription = {
                    title: this.translateService.instant('matching.time_select.titleCard'),
                    question: this.translateService.instant('matching.time_select.description')
                }
                this.openDialog(MatchingTimeSelectComponent, 'time-select', { slots: data.timeSlots, teacher: teacher, student, modalDescription, multiple: false, hasTeacher: true }, '864px', '');
            }
        )
    }

     /* disapprove(student: Student, teacher: Teacher) {
        this.apiService.updateMatching({
            teacherId: teacher?.teacher.id || '',
            studentId: student.student.id,
            acceptedTeacher: false,
        }).pipe(switchMap(() => {
            const manageSchoolId = this.user.manageSchoolId || '';
            return this.apiService.getMatchesList(manageSchoolId, '1', '100', 'pending')
        })).subscribe((matches: MatchesListResponse) => {
            this.matches.list.length = 0;
            matches.list.forEach((item) => {
                this.matches?.list.push(item);
            })
            this.dataSource = new MatTableDataSource<any>(this.matches?.list);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;
            console.log('Successfully declined.')
        });
    }
      */

    sortData(sort: Sort) {
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            this.getMatches();
            return;
        }
        this.getSortedMatches(sort.active, sort.direction);
    }

}
