import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { getFieldProperties } from '../../../../../../../../shared/validators/has-error';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CALENDAR_FORMATS, Step } from '../../../../../../../../shared/constants/form-option';
import { UserRoles } from '../../../../../../../../core/models/user-info.interface';

@Component({
    selector: 'app-step-additional-info',
    templateUrl: './step-additional-info.component.html',
    styleUrls: ['./step-additional-info.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: CALENDAR_FORMATS },
    ],
})
export class StepAdditionalInfoComponent implements OnInit {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    @Input() signUpRole: UserRoles;
    currentDate: Date = new Date();
    protected readonly getFieldProperties = getFieldProperties;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.addControls();
    }

    onSubmit() {
        this.submitForm.emit();
        this.stepper.next();
    }
    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('gender', this.fb.control(''));

            const birthdayControl = this.signUpRole === UserRoles.Student
                ? this.fb.control('')
                : this.fb.control('', Validators.required);

            this.formGroup.addControl('birthday', birthdayControl);
        }
    }

    protected readonly UserRoles = UserRoles;
}
