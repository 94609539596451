<div class="instrument-form-container">
    <mat-toolbar class="toolbar-form">
        <span>{{'instruments_modal.title' | translate}}</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon" (click)="closeDialog()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div class="title-container">
        <div class="title">{{signUpRole === 'teacher' ? ('instruments_modal.select_instruments' | translate) : ('instruments_modal.select_instruments_student' | translate) }}</div>
    </div>
    <form [formGroup]="formGroup">
    <div mat-dialog-content class="form-content">
        <mat-chip-listbox formControlName="selectedInstruments" #chipList [multiple]="true" class="mat-chip-list-stacked"
                          [selectable]="true" (change)="handleSelectedInstrumentsChange()">
            <mat-chip-option *ngFor="let chip of displayedChips" [value]="chip.chip.id" [selected]="chip.selected"
                             (click)="chip.selected=!chip.selected"
                             [selectable]="true">
                <mat-icon matChipTrailingIcon [tooltip]="[{ name: 'GND', url:  chip.chip?.gnd}, { name: 'Wikidata', url:  chip.chip?.wikidata}, {name: 'MIMO',url:  chip.chip?.mimo}]">open_in_new</mat-icon>
                {{ data.lang === 'eng' ? chip.chip.nameEn : chip.chip.nameDe }}
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
    </form>

    <div class="footer">
        <div class="btn-container custom-theme">
            <div class="right-side">
                <button mat-stroked-button color="primary" (click)="closeDialog()">
                    {{'instruments_modal.cancel' | translate}}
                </button>
                <button mat-raised-button color="primary" type="submit"  (click)="onSubmit()">
                    <span>{{'instruments_modal.confirm_selection' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
