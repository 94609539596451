<div #title class="title-container" [ngClass]="{'hide-empty-dashboard-line': matches?.list?.length === 0}">
    <div class="title"><span>{{ 'pending_matches_table.title' | translate }}</span></div>
</div>

<ng-container>
    <div *ngIf="matches?.list && matches.list.length > 0; else emptyContainer" class="table-container">
        <table mat-table [dataSource]="dataSource">
            <!-- Instrument Column-->
            <ng-container matColumnDef="instrument">
                <th mat-header-cell *matHeaderCellDef>{{ 'pending_matches_table.instrument_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element?.instruments">
                        <span *ngFor="let instrument of element?.instruments; let last = last">
                           {{ currentLanguage === 'eng' ? instrument.nameEn : instrument.nameDe }}
                            <span *ngIf="!last">, </span>
                        </span>
                    </ng-container>
                    <span *ngIf="!element?.instruments || element?.instruments?.length === 0">—</span>
                </td>
            </ng-container>

            <!-- Teacher First Name Column -->
            <ng-container matColumnDef="teacherFirstName">
                <th mat-header-cell *matHeaderCellDef>{{ 'pending_matches_table.teacher_first_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.teacherUser.firstName }} </td>
            </ng-container>

            <!-- Teacher Last Name Column -->
            <ng-container matColumnDef="teacherLastName">
                <th mat-header-cell *matHeaderCellDef>{{ 'pending_matches_table.teacher_last_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.teacherUser.lastName ? element.teacherUser.lastName : '—' }} </td>
            </ng-container>

            <!-- Student First Name Column -->
            <ng-container matColumnDef="studentFirstName">
                <th mat-header-cell *matHeaderCellDef>{{ 'pending_matches_table.student_first_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.studentUser.firstName }} </td>
            </ng-container>

            <!-- Student Last Name Column -->
            <ng-container matColumnDef="studentLastName">
                <th mat-header-cell *matHeaderCellDef>{{ 'pending_matches_table.student_last_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.studentUser.lastName ? element.studentUser.lastName : '—' }} </td>
            </ng-container>


            <ng-container matColumnDef="approve" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef>{{ 'pending_matches_table.actions_column_header' | translate }}</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <div class="actions-container">
<!--                            <button *ngIf="matches?.list[!this.currentIndex ? i : this.currentIndex + i].studentUser?.student?.teacherId" mat-button (click)="disapprove(matches?.list[!this.currentIndex ? i : this.currentIndex + i].studentUser, matches?.list[!this.currentIndex ? i : this.currentIndex + i].teacherUser)">{{ 'student-dashboard-table.button.disapprove' | translate }}</button>-->
                            <button [disabled]="matches.list[!this.currentIndex ? i : this.currentIndex + i].studentUser?.student?.teacherId" mat-button class="raised-button" color="primary" mat-raised-button (click)="approve(matches.list[!this.currentIndex ? i : this.currentIndex + i].studentUser,  matches.list[!this.currentIndex ? i : this.currentIndex + i].teacherUser)">{{ 'student-dashboard-table.button.approve' | translate }}</button>
                        </div>
                    </td>
            </ng-container>


            <ng-container matColumnDef="teacherProfile" stickyEnd>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index" #td>
                    <div class="actions-container">
                        <button mat-button (click)="showTeacherProfile(matches.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'pending_matches_table.teacher_profile_button' | translate }}</button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="studentProfile" stickyEnd>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index" #td>
                    <div class="actions-container">
                        <button mat-button (click)="showStudentProfile(matches.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'pending_matches_table.student_profile_button' | translate }}</button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [ngClass]="{'empty-paginator': dataSource && dataSource.data && dataSource.data.length < 10, 'hide-empty-dashboard-line': matches?.list?.length === 0}" [pageSizeOptions]="[10, 20, 30]"
                   showFirstLastButtons
                   (page)="onPaginatorPageChange($event)">
    </mat-paginator>
</ng-container>

<ng-template #emptyContainer>
    <div class="empty-container">
        {{ 'pending_matches_table.no_data_available' | translate }}
    </div>
</ng-template>

