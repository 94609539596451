<div *ngIf="!isMobilePlatform"  #title class="title-container" [ngClass]="{'hide-empty-dashboard-line': matches?.list?.length === 0}">
    <div class="title"><span>{{ 'active_matches_table.title' | translate }}</span></div>
</div>

<ng-container *ngIf="!isMobilePlatform">
    <div *ngIf="matches.list.length > 0; else emptyContainer" class="table-container">
        <table mat-table [dataSource]="dataSource">
            <!-- Trial Lesson Column -->
            <ng-container matColumnDef="trialLesson">
                <th mat-header-cell *matHeaderCellDef>{{ 'active_matches_table.trial_lesson_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element?.lesson ? (element?.lesson?.startTime | formatLessonStartTime) : '—'}} </td>
            </ng-container>
            <!-- Instrument Column-->
            <ng-container matColumnDef="instrument">
                <th mat-header-cell *matHeaderCellDef>{{ 'active_matches_table.instrument_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element?.instruments">
                        <span *ngFor="let instrument of element?.instruments; let last = last">
                            {{ currentLanguage === 'eng' ? instrument.nameEn : instrument.nameDe }}
                            <span *ngIf="!last">, </span>
                        </span>
                    </ng-container>
                    <span *ngIf="!element?.instruments || element?.instruments?.length === 0">—</span>
                </td>
            </ng-container>

            <!-- Teacher First Name Column -->
            <ng-container matColumnDef="teacherFirstName">
                <th mat-header-cell *matHeaderCellDef>{{ 'active_matches_table.teacher_first_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.teacherUser.firstName }} </td>
            </ng-container>

            <!-- Teacher Last Name Column -->
            <ng-container matColumnDef="teacherLastName">
                <th mat-header-cell *matHeaderCellDef>{{ 'active_matches_table.teacher_last_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.teacherUser.lastName ? element.teacherUser.lastName : '—' }} </td>
            </ng-container>

            <!-- Student First Name Column -->
            <ng-container matColumnDef="studentFirstName">
                <th mat-header-cell *matHeaderCellDef>{{ 'active_matches_table.student_first_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.studentUser.firstName }} </td>
            </ng-container>

            <!-- Student Last Name Column -->
            <ng-container matColumnDef="studentLastName">
                <th mat-header-cell *matHeaderCellDef>{{ 'active_matches_table.student_last_name_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{ element.studentUser.lastName ? element.studentUser.lastName : '—' }} </td>
            </ng-container>


            <ng-container matColumnDef="teacherProfile" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>{{ 'active_matches_table.actions_column_header' | translate }}</th>
                <td mat-cell *matCellDef="let element; let i = index" #td>
                    <div class="actions-container">
                        <button mat-button (click)="showTeacherProfile(matches.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'active_matches_table.teacher_profile_button' | translate }}</button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="studentProfile" stickyEnd>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index" #td>
                    <div class="actions-container">
                        <button mat-button (click)="showStudentProfile(matches.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'active_matches_table.student_profile_button' | translate }}</button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</ng-container>

<ng-container *ngIf="isMobilePlatform">
    <div *ngIf="matches?.list && matches.list.length > 0; else emptyMobileContainer" class="table-container">
            <div class="toolbar-wrapper">
                <mat-toolbar class="toolbar-form">
                    <div class="left-side">
                        <button mat-icon-button class="example-icon" (click)="closeDialog()">
                            <mat-icon class="close-icon">chevron_left</mat-icon>
                        </button>
                        <span>{{ matches.title }}</span>
                    </div>
                </mat-toolbar>
                <mat-divider></mat-divider>
            </div>
        <mat-list role="list">
            <ng-container *ngFor="let item of paginatedData; let i = index">
                <a mat-list-item matRipple class="list-item-btn" (click)="openMatchDetails(item)">
                    <div class="list-wrapper">
                        <div class="left-side">
                            <div class="text-container">
                                <span class="name">{{ item?.teacherUser?.firstName }} {{ 'active_matches_table.teacher' | translate }}
                                    & {{ item?.studentUser?.lastName }} {{ 'active_matches_table.student' | translate }}</span>
                                <div class="instrument-wrapper" *ngIf="item?.instruments">
                                   <span class="instrument" *ngFor="let instrument of item?.instruments; let last = last">
                                         <ng-container *ngIf="isMusicEntity(instrument)">{{ currentLanguage === 'eng' ? instrument?.nameEn : instrument?.nameDe }}</ng-container>
                                       <span *ngIf="!last">| </span>
                                   </span>
                                </div>
                                <span *ngIf="!item?.instruments || item?.instruments?.length === 0">—</span>
                                <span *ngIf=" item?.lesson" class="instrument"> {{ item.lesson.startTime | formatLessonStartTime }} </span>
                            </div>
                        </div>
                    </div>
                </a>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </div>
</ng-container>


<ng-template #emptyContainer>
    <div class="empty-container">
        {{ 'active_matches_table.no_data_available' | translate }}
    </div>
</ng-template>

<ng-template #emptyMobileContainer>
    <ng-container *ngIf="isMobilePlatform">
        <mat-toolbar class="toolbar-form">
            <div class="left-side">
                <button mat-icon-button class="example-icon" (click)="closeDialog()">
                    <mat-icon class="close-icon">chevron_left</mat-icon>
                </button>
                <span>{{'active_matches_table.title' | translate }}</span>
            </div>
        </mat-toolbar>
        <mat-divider></mat-divider>
        <div class="empty-container">
            {{ 'active_matches_table.no_data_available' | translate }}
        </div>
    </ng-container>
</ng-template>

<mat-paginator *ngIf="!isMobilePlatform && matches?.list && matches.list.length > 0" [ngClass]="{'empty-paginator': dataSource && dataSource.data && dataSource.data.length < 10, 'hide-empty-dashboard-line': matches?.list?.length === 0 }" [pageSizeOptions]="[10, 20, 30]"
               showFirstLastButtons
               (page)="onPaginatorPageChange($event)">
</mat-paginator>

<div *ngIf="isMobilePlatform && matches?.list && matches.list.length > 0" class="paginator-wrapper">
    <mat-paginator
        [pageSize]="10"
        (page)="onPaginatorPageChange($event)">
    </mat-paginator>
</div>

