import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TeacherDetailsComponent } from './components/profile/teacher-details/teacher-details.component';
import { TeacherScheduleComponent } from './components/profile/teacher-schedule/teacher-schedule.component';
import { TeacherProfileComponent } from './components/profile/teacher-profile/teacher-profile.component';
import { MusiqRoutingModule } from './musiq-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatchingConfirmationComponent } from './components/profile/matching-confirmation/matching-confirmation.component';
import { StudentProfileComponent } from './components/profile/student-profile/student-profile.component';
import { StudentDetailsComponent } from './components/profile/student-details/student-details.component';
import { StudentScheduleComponent } from './components/profile/student-schedule/student-schedule.component';
import { MatRadioModule } from '@angular/material/radio';
import { StudentFormComponent } from './components/profile/student-form/student-form.component';
import { TeacherFormComponent } from './components/profile/teacher-form/teacher-form.component';
import { OrganizationFormComponent } from './components/profile/organization-form/organization-form.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { TeacherDashboardComponent } from './components/dashboard/teacher-dashboard/teacher-dashboard.component';
import { RootDashboardComponent } from './components/dashboard/root-dashboard/root-dashboard.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardHeaderComponent } from './components/dashboard/dashboard-header/dashboard-header.component';
import { NotifyBannerComponent } from './components/dashboard/notify-banner/notify-banner.component';
import { DashboardIntroComponent } from './components/dashboard/dashboard-intro/dashboard-intro.component';
import { NotifyLessonComponent } from './components/dashboard/notify-lesson/notify-lesson.component';
import { DashboardTeacherTableComponent } from './components/dashboard/teacher-dashboard/dashboard-teacher-table/dashboard-teacher-table.component';
import { DashboardStudentTableComponent } from './components/dashboard/student-dashboard/dashboard-student-table/dashboard-student-table.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './sign-up/auth/login/login.component';
import { ResetPasswordComponent } from './sign-up/auth/reset-password/reset-password.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { OrganisationDashboardTableComponent } from './components/dashboard/organisation-dashboard/organisation-dashboard-table/organisation-dashboard-table.component';
import { StudentTeachersListComponentComponent } from './components/dashboard/teacher-dashboard/student-teachers-list-component/student-teachers-list-component.component';
import { DeleteConfirmComponent } from './components/dashboard/delete-confirm/delete-confirm.component';
import { MatchTeachersComponent } from './components/match-teachers/match-teachers.component';
import { MatCardModule } from '@angular/material/card';
import { NotifyTeacherMatchComponent } from './components/match-teachers/notify-teacher-match/notify-teacher-match.component';
import { TeacherCardComponent } from './components/match-teachers/teacher-card/teacher-card.component';
import { SearchingMatchComponent } from './components/match-teachers/searching-match/searching-match.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MatchingTimeSelectComponent } from './components/match-teachers/matching-time-select/matching-time-select.component';
import { CapitalizePipe } from '../shared/pipes/capitalize-pipe';
import { MatchTeacherConfirmComponent } from './components/match-teachers/match-teacher-confirm/match-teacher-confirm.component';
import { StudentProfileInfoComponent } from './components/dashboard/student-dashboard/student-profile-info/student-profile-info.component';
import { TeacherProfileInfoComponent } from './components/dashboard/teacher-dashboard/teacher-profile-info/teacher-profile-info.component';
import { OrganisationProfileInfoComponent } from './components/dashboard/organisation-dashboard/organisation-profile-info/organisation-profile-info.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarUploadComponent } from './components/avatar-upload/avatar-upload.component';
import { SelectImageComponent } from './components/select-image/select-image.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { LocalizedDateTimePipe } from '../shared/pipes/localized-date-time-pipe';
import { MatchesListComponent } from './components/dashboard/organisation-dashboard/matches-list/matches-list.component';
import { PendingMatchesComponent } from './components/dashboard/organisation-dashboard/pending-matches/pending-matches.component';
import { PastMatchesComponent } from './components/dashboard/organisation-dashboard/past-matches/past-matches.component';
import { FormatLessonStartTimePipe } from '../shared/pipes/lesson-start-time-pipe';
import { AgeOrDashPipe } from '../shared/pipes/age-or-dash.pipe';
import { OrganisationListMobileComponent } from './components/dashboard/organisation-dashboard/organisation-list-mobile/organisation-list-mobile.component';
import { StudentListMobileComponent } from './components/dashboard/student-dashboard/student-list-mobile/student-list-mobile.component';
import { StudentTeachersListMobileComponent } from './components/dashboard/teacher-dashboard/student-teachers-list-mobile/student-teachers-list-mobile.component';
import { MatchDetailsComponent } from './components/dashboard/match-details/match-details.component';
import { ConfirmMatchStudentComponent } from './components/confirm-match-student/confirm-match-student.component';
import { TimeSelectMatchStudentComponent } from './components/confirm-match-student/time-select-match-student/time-select-match-student.component';
import { LessonBookedConfirmComponent } from './components/confirm-match-student/lesson-booked-confirm/lesson-booked-confirm.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { ActivateCardComponent } from './components/integrations/activate-card/activate-card.component';
import { SessionItemComponent } from './components/integrations/session-item/session-item.component';
import { ActivateFormComponent } from './components/integrations/activate-form/activate-form.component';
import { EditSettingsComponent } from './components/integrations/edit-settings/edit-settings.component';
import { InstrumentsComponent } from './components/instruments/instruments.component';
import { GenresComponent } from './components/genres/genres.component';
import { InstrumentsTableComponent } from './components/dashboard/instruments-table/instruments-table.component';
import { GenresTableComponent } from './components/dashboard/genres-table/genres-table.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';

@NgModule({
    declarations: [
        TeacherDetailsComponent,
        TeacherScheduleComponent,
        TeacherProfileComponent,
        MatchingConfirmationComponent,
        StudentProfileComponent,
        StudentDetailsComponent,
        StudentScheduleComponent,
        StudentFormComponent,
        TeacherFormComponent,
        OrganizationFormComponent,
        LoginComponent,
        ResetPasswordComponent,
        DashboardComponent,
        DashboardHeaderComponent,
        NotifyBannerComponent,
        DashboardIntroComponent,
        StatisticsComponent,
        EditProfileComponent,
        TeacherDashboardComponent,
        NotifyLessonComponent,
        DashboardTeacherTableComponent,
        DashboardStudentTableComponent,
        RootDashboardComponent,
        OrganisationDashboardTableComponent,
        StudentTeachersListComponentComponent,
        DeleteConfirmComponent,
        MatchTeachersComponent,
        NotifyTeacherMatchComponent,
        TeacherCardComponent,
        SearchingMatchComponent,
        LoadingComponent,
        MatchingTimeSelectComponent,
        CapitalizePipe,
        AgeOrDashPipe,
        FormatLessonStartTimePipe,
        LocalizedDateTimePipe,
        MatchTeacherConfirmComponent,
        StudentProfileInfoComponent,
        TeacherProfileInfoComponent,
        OrganisationProfileInfoComponent,
        AvatarUploadComponent,
        SelectImageComponent,
        LoginComponent,
        MatchesListComponent,
        PendingMatchesComponent,
        PastMatchesComponent,
        OrganisationListMobileComponent,
        StudentListMobileComponent,
        StudentTeachersListMobileComponent,
        MatchDetailsComponent,
        ConfirmMatchStudentComponent,
        TimeSelectMatchStudentComponent,
        LessonBookedConfirmComponent,
        IntegrationsComponent,
        ActivateCardComponent,
        SessionItemComponent,
        ActivateFormComponent,
        InstrumentsComponent,
        EditSettingsComponent,
        InstrumentsComponent,
        GenresComponent,
        InstrumentsTableComponent,
        GenresTableComponent,
        CustomTooltipComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        MusiqRoutingModule,
        MatButtonModule,
        MatSnackBarModule,
        MatListModule,
        MatCheckboxModule,
        MatExpansionModule,
        TranslateModule,
        MatRadioModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MatDialogModule,
        MatToolbarModule,
        MatGridListModule,
        MatChipsModule,
        MatTableModule,
        MatPaginatorModule,
        MatStepperModule,
        SharedModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMenuModule,
        MatCardModule,
        MatTooltipModule,
        MatRippleModule,
        ImageCropperComponent,
        MatSortModule
    ]
})
export class MusiqModule {
}
